/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useState, useEffect } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { objectHasPropertyString, isObject } from 'cms/utils/empty-exists'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Logger } from 'cms/utils/logger'

const LinkButtonComponent = (props) => {
    const { token } = useAuth()
    const { component } = props || {}

    const [link, setLink] = useState()
    const [target, setTarget] = useState(undefined)
    const [classes, setClasses] = useState('btn icon')

    useEffect(() => {
        const load = async () => {
            if (objectHasPropertyString(component, 'link')) {
                Logger.debug(`loading link from DB: `, component?.link)
                const _link = await SimpliCitySDK.link.findOne(token, component?.link)
                setLink(_link)
                if (_link?.is_external) {
                    setTarget("_blank")
                }
                let class_list = ['btn icon']
                const _style = component?.style
                if (_style === 'external') {
                    class_list.push('is-external')
                }
                if (_style === 'arrow') {
                    class_list.push('is-arrow')
                }
                setClasses(class_list.join(" "))
                return _link
            }
        }
        // load once
        if (!isObject(link) || !objectHasPropertyString(link, "guid")) {
            load()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [component?.link])

    return (
        <div className='simplicity-linkbutton'>
            {link && link?.url && link?.text && (
                <a className={classes} href={link?.url} target={target}>
                    <div>{link?.text}</div>
                    {classes.indexOf('is-external') > -1 &&
                        <FontAwesomeIcon icon={`fa-regular fa-arrow-up-right-from-square`} />
                    }
                    {classes.indexOf("is-arrow") > -1 &&
                        <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                    }
                </a>
            )}
        </div>
    )
}

LinkButtonComponent.displayName = 'LinkButtonComponent'
export { LinkButtonComponent }
