/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { SimpliCitySDK } from 'cms/sdk'
import { readChildren, readNode, filterHidden } from 'cms/shared/read_node'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'

/**
 * Load Navigation Data
 * @param {string} token
 * @param {string} home
 * @param {string} side
 */
const fetchNavigation = async (token, home, side) => {

    /** @type {NavItem[]} */
    const menuItems = []

    // TODO: this processing should likely be prepared at the API
    const node = await SimpliCitySDK.webpage.findTree(token, home)
    if (node) {
        const item = await readNode(node)
        if (item) {
            // level 1 - not added to menu
            const filteredChildren = filterHidden(item?.children)
            if (isArrayNotEmpty(filteredChildren)) {
                for (const child of filteredChildren) {
                    // level 2
                    const child_data = await readNode(child)
                    child_data.children = await readChildren(child, 2, side)
                    child_data.children = filterHidden(child_data.children)
                    menuItems.push({
                        title: child_data.title,
                        side: side,
                        level: 2,
                        url: child_data.url,
                        children: child_data.children,
                    })
                }
            }
        }
    }
    return {
        data: node,
        menuItems
    }
}
export { fetchNavigation }

/**
 * @typedef {object} NavItem
 * @property {string} title
 * @property {string} side
 * @property {number} level
 * @property {string} url
 * @property {any[]} children
 */
