/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { ErrorMessages } from 'cms/forms/error-messages'
import { isStringEmpty } from 'cms/utils/empty-exists'
import { useMemo } from 'react'

/**
 * @param {number} min
 * @param {number} max
 * @param {number} decimals
 * @param {boolean} required
 * @param {string | number=} value
 */
export function useNumericalValidator (min, max, decimals, required, value) {

    const error = useMemo(
        () => {
            const isEmptyString = isStringEmpty(value)
            const isUndefined = typeof value === 'undefined'

            // if the field is required, but there is no value, error
            if (isEmptyString || isUndefined) {
                return required ?
                    ErrorMessages.required() :
                    null
            }

            const numValue = Number(value)
            const strValue = String(value)

            // if the value is less than the min value, error
            if (min && numValue < min) {
                return ErrorMessages.numeric.minValue(min)
            }

            // if the value is greater than the max value, error
            if (max && numValue > max) {
                return ErrorMessages.numeric.maxValue(max)
            }

            // if the number of fractional digits is greater than allowed, error
            const fractionalDigits = strValue.split('.')[1]
            const numFractionalDigits = typeof fractionalDigits === 'string' ? fractionalDigits.length : 0

            if (numFractionalDigits > decimals) {
                return ErrorMessages.numeric.decimals(decimals)
            }

            // by default, no error
            return null
        },
        [decimals, max, min, required, value]
    )

    return error
}
