/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useForm } from 'cms/forms/context'
import ErrorMsg from '../error-msg'
import { useRequiredValidator } from '../hooks/use-required-validator'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestionYesNo>}
 */
const SimplicityFormYesNo = (props) => {

    const { field } = props || {}
    const {
        guid,
        hasDescription,
        description,
        prompt,
        value,
        hasHelperText,
        helperText,
        options,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    const handleChange = (event) => {
        const value = event.target.value
        onInputChange(value, field)
    }

    const currentError = useRequiredValidator(
        isRequired,
        value
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    return (
        <div className='simplicity-forms-field-group'>
            <label htmlFor={guid} className='simplicity-forms-field-label'>
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className='simplicity-forms-description'>{description}</span>}
            {hasError && <ErrorMsg type='error' error={error} />}
            <RadioGroup value={value}>
                {options &&
                    options.map((option) => (
                        <FormControlLabel
                            className={`simplicity-forms-field-radios inline-block ${hasError && 'error'}`}
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            onChange={handleChange}
                        />
                    ))}
            </RadioGroup>
            {hasHelperText && helperText && <span className='simplicity-forms-helper-text'>{helperText}</span>}
        </div>
    )
}

export { SimplicityFormYesNo }
