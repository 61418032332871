/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const DividerComponent = () => {
    return (
        <div className='simplicity-divider'></div>
    )
}

DividerComponent.displayName = 'DividerComponent';
export { DividerComponent }
