/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";
import { MenuItem } from "./menu-item";

const MenuItems = (props) => {
    const { items, level = 1, setOpenMenus, openMenus } = props;
    const nextLevel = level + 1;

    const isParentGuid = items ? openMenus?.includes(items[0]?.parent) : false;

    return (
        <ul className={`menu-items level-${level} ${isParentGuid ? "open" : ""}`}>
            {items.map((item) => {
                const guid = item?.guid;
                if (item.hide_in_nav) {
                    return null;
                }
                return (
                    <MenuItem
                        siblings={items}
                        key={guid}
                        item={item}
                        guid={guid}
                        openMenus={openMenus}
                        setOpenMenus={setOpenMenus}
                    >
                        {/* {openMenus.includes(guid) && item?.children && ( */}
                        {item?.children && (
                            <MenuItems
                                items={item.children}
                                level={nextLevel}
                                openMenus={openMenus}
                                setOpenMenus={setOpenMenus}
                            />
                        )}
                    </MenuItem>
                );
            })}
        </ul>
    );
};

MenuItems.displayName = "MenuItems";
export { MenuItems };
