/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { getShortType } from 'cms/utils/get-short-type'

/**
 * Static question types without the `question.` prefix
 */
const staticQuestionTypes = /** @type {const} */ ([
    'heading',
    'paragraph',
    'infoBox',
    'group',
])

/**
 *
 * @param {FormQuestion} question
 */
const isStaticQuestion = (question) => {
    const fieldType = getShortType(question.type)
    const isStaticQuestion = staticQuestionTypes.includes(fieldType)

    return isStaticQuestion
}

export { isStaticQuestion, staticQuestionTypes }
