/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useStats } from "react-instantsearch"

/**
 * Calculate the start and end values for a page
 * @param {number} page
 * @param {number} hitsPerPage
 * @param {number} nbHits
 */
function getPageRange (page, hitsPerPage, nbHits) {
    const start = page * hitsPerPage + 1
    const maxEnd = (page + 1) * hitsPerPage

    // don't exceed the max number of hits
    const end = Math.min(nbHits, maxEnd)

    return {
        start,
        end
    }
}

export function SearchStats () {
    const { nbHits, query, page, hitsPerPage } = useStats()

    let forQuery = null

    if (query) {
        forQuery = <>for keyword: <b>{query}</b></>
    }

    const { start, end } = getPageRange(page, hitsPerPage, nbHits)

    if (end === 0) {
        return (
            <p>
                No results {forQuery}
            </p>
        )
    }

    return (
        <p>
            {start} to {end} of {nbHits.toLocaleString()} results {forQuery}
        </p>
    )
}
