/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { CalendarFeedFilterComponent } from 'cms/shared/feeds/calendar/calendar-feed-as-component'

// import { LargeFeedFilter } from 'cms/shared/feeds/large'

const FeedComponentCalendar = (props) => {

    const { component } = props
    const {
        selected_type,
        selected_category,
        enable_bar,
    } = component || {}

    return(
        <>
            <CalendarFeedFilterComponent
                selected_type={selected_type}
                selected_category={selected_category}
                enable_bar={enable_bar}
            />
        </>
    )
}

FeedComponentCalendar.displayName = 'FeedComponentCalendar'
export { FeedComponentCalendar }
