/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { getAttributes, Mark, mergeAttributes } from '@tiptap/core'
import { registerCustomProtocol, reset } from 'linkifyjs'
import { Plugin, PluginKey } from '@tiptap/pm/state'

// function autolink (options) {
//     return new Plugin({
//         key: new PluginKey('autolink'),
//         appendTransaction: (transactions, oldState, newState) => {
//             const docChanges = transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);
//             const preventAutolink = transactions.some((transaction) => transaction.getMeta('preventAutolink'));
//             if (!docChanges || preventAutolink) {
//                 return;
//             }
//             const { tr } = newState;
//             const transform = combineTransactionSteps(oldState.doc, [...transactions]);
//             const changes = getChangedRanges(transform);
//             changes.forEach(({ newRange }) => {
//                 // Now let’s see if we can add new links.
//                 const nodesInChangedRanges = findChildrenInRange(newState.doc, newRange, (node) => node.isTextblock);
//                 let textBlock;
//                 let textBeforeWhitespace;
//                 if (nodesInChangedRanges.length > 1) {
//                     // Grab the first node within the changed ranges (ex. the first of two paragraphs when hitting enter).
//                     textBlock = nodesInChangedRanges[0]
//                     textBeforeWhitespace = newState.doc.textBetween(
//                         textBlock.pos,
//                         textBlock.pos + textBlock.node.nodeSize,
//                         undefined,
//                         ' '
//                     )
//                 } else if (nodesInChangedRanges.length
//                     // We want to make sure to include the block seperator argument to treat hard breaks like spaces.
//                     && newState.doc.textBetween(newRange.from, newRange.to, ' ', ' ').endsWith(' ')) {
//                     textBlock = nodesInChangedRanges[0];
//                     textBeforeWhitespace = newState.doc.textBetween(textBlock.pos, newRange.to, undefined, ' ');
//                 }
//                 if (textBlock && textBeforeWhitespace) {
//                     const wordsBeforeWhitespace = textBeforeWhitespace.split(' ').filter((s) => s !== '');
//                     if (wordsBeforeWhitespace.length <= 0) {
//                         return false;
//                     }
//                     const lastWordBeforeSpace = wordsBeforeWhitespace[wordsBeforeWhitespace.length - 1];
//                     const lastWordAndBlockOffset = textBlock.pos + textBeforeWhitespace.lastIndexOf(lastWordBeforeSpace);
//                     if (!lastWordBeforeSpace) {
//                         return false;
//                     }
//                     find(lastWordBeforeSpace)
//                     .filter((link) => link.isLink)
//                     // Calculate link position.
//                     .map((link) => ({
//                         ...link,
//                         from: lastWordAndBlockOffset + link.start + 1,
//                         to: lastWordAndBlockOffset + link.end + 1,
//                     }))
//                     // ignore link inside code mark
//                     .filter ((link) => {
//                         if (!newState.schema.marks.code) {
//                             return true;
//                         }
//                         return !newState.doc.rangeHasMark(link.from, link.to, newState.schema.marks.code);
//                     })
//                     // validate link
//                     .filter ((link) => {
//                         if (options.validate) {
//                             return options.validate(link.value);
//                         }
//                         return true;
//                     })
//                     // Add link mark.
//                     .forEach ((link) => {
//                         if (getMarksBetween(link.from, link.to, newState.doc).some((item) => item.mark.type === options.type)) {
//                             return;
//                         }
//                         tr.addMark(link.from, link.to, options.type.create({
//                             href: link.href,
//                         }));
//                     });
//                 }
//             });
//             if (!tr.steps.length) {
//                 return;
//             }
//             return tr;
//         },
//     });
// }

function clickHandler (options) {
    return new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
            handleClick: (view, pos, event) => {
                var _a, _b
                if (event.button !== 0) {
                    return false
                }
                const eventTarget = event.target
                if (eventTarget.nodeName !== 'A') {
                    return false
                }
                const attrs = getAttributes(view.state, options.type.name)
                const link = event.target
                const href = (_a = link === null || link === undefined ? undefined : link.href) !== null && _a !== undefined ? _a : attrs.href
                const target = (_b = link === null || link === undefined ? undefined : link.target) !== null && _b !== undefined ? _b : attrs.target
                if (link && href) {
                    if (view.editable) {
                        window.open(href, target)
                    }
                    return true
                }
                return false
            },
        },
    })
}

// function pasteHandler (options) {
//     return new Plugin({
//         key: new PluginKey('handlePasteLink'),
//         props: {
//             handlePaste: (view, event, slice) => {
//                 var _a;
//                 const { state } = view;
//                 const { selection } = state;
//                 const { empty } = selection;
//                 if (empty) {
//                     return false;
//                 }
//                 let textContent = '';
//                 slice.content.forEach((node) => {
//                     textContent += node.textContent;
//                 });
//                 const link = find(textContent).find((item) => item.isLink && item.value === textContent);
//                 if (!textContent || !link) {
//                     return false;
//                 }
//                 // TODO: determine if: is external
//                 // TODO: flag links as NOT SIMPLICITY LINKS
//                 const html = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text/html');
//                 const hrefRegex = /href="([^"]*)"/;
//                 const existingLink = html === null || html === void 0 ? void 0 : html.match(hrefRegex);
//                 const url = existingLink ? existingLink[1] : link.href;
//                 options.editor.commands.setMark(options.type, {
//                     href: url,
//                     url: url,
//                     type: 'undetermined',
//                 });
//                 return true;
//             },
//         },
//     });
// }

const SimpliCityLink = Mark.create({

    name: 'link',

    priority: 1000,

    keepOnSplit: false,

    selectable: false,

    atom: true,

    onCreate () {
        this.options.protocols.forEach((protocol) => {
            if (typeof protocol === 'string') {
                registerCustomProtocol(protocol);
                return;
            }
            registerCustomProtocol(protocol.scheme, protocol.optionalSlashes);
        });
    },
    onDestroy () {
        reset();
    },
    inclusive () {
        return this.options.autolink;
    },
    addOptions () {
        return {
            openOnClick: true,
            linkOnPaste: true,
            autolink: true,
            protocols: [],
            HTMLAttributes: {
                guid: null,
                type: 'link',
                link_type: 'internal',
                external: false,
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
                class: null,
            },
            validate: undefined,
        };
    },
    addAttributes () {
        return {
            href: {
                default: null,
            },
            url: {
                default: null,
            },
            type: {
                default: 'link',
            },
            link_type: {
                default: 'internal',
            },
            external: {
                default: false,
            },
            guid: {
                default: null,
            },
            text: {
                default: null,
            },
            webpage: {
                default: null,
            },
            target: {
                default: this.options.HTMLAttributes.target,
            },
            rel: {
                default: this.options.HTMLAttributes.rel,
            },
            class: {
                default: this.options.HTMLAttributes.class,
            },
        };
    },

    // parseHTML () {
    //     return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
    // },

    renderHTML ({ HTMLAttributes }) {
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands () {
        return {
            setLink: (attributes) => ({ chain }) => {
                return chain().setMark(SimpliCityLink.name, attributes).setMeta('preventAutolink', true).run();
            },
            toggleLink: (attributes) => ({ chain }) => {
                return chain()
                .toggleMark(SimpliCityLink.name, attributes, { extendEmptyMarkRange: true })
                .setMeta('preventAutolink', true)
                .run();
            },
            unsetLink: () => ({ chain }) => {
                return chain()
                .unsetMark(SimpliCityLink.name, { extendEmptyMarkRange: true })
                .setMeta('preventAutolink', true)
                .run();
            },
        };
    },

    // addPasteRules () {
    //     return [
    //         markPasteRule({
    //             find: (text) => find(text)
    //             .filter((link) => {
    //                 if (this.options.validate) {
    //                     return this.options.validate(link.value);
    //                 }
    //                 return true;
    //             })
    //             .filter((link) => link.isLink)
    //             .map((link) => ({
    //                 text: link.value,
    //                 index: link.start,
    //                 data: link,
    //             })),
    //             type: this.type,
    //             getAttributes: (match, pasteEvent) => {
    //                 var _a, _b;
    //                 const html = (_a = pasteEvent === null || pasteEvent === void 0 ? void 0 : pasteEvent.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text/html');
    //                 const hrefRegex = /href="([^"]*)"/;
    //                 const existingLink = html === null || html === void 0 ? void 0 : html.match(hrefRegex);
    //                 if (existingLink) {
    //                     return {
    //                         href: existingLink[1],
    //                         type: 'undetermined'
    //                     };
    //                 }
    //                 return {
    //                     href: (_b = match.data) === null || _b === void 0 ? void 0 : _b.href,
    //                     type: 'undetermined'
    //                 };
    //             },
    //         }),
    //     ];
    // },

    addProseMirrorPlugins () {
        const plugins = [];
        // if (this.options.autolink) {
        //     plugins.push(autolink({
        //         type: this.type,
        //         validate: this.options.validate,
        //     }));
        // }
        if (this.options.openOnClick) {
            plugins.push(clickHandler({
                type: this.type,
            }));
        }
        // if (this.options.linkOnPaste) {
        //     plugins.push(pasteHandler({
        //         editor: this.editor,
        //         type: this.type,
        //     }));
        // }
        return plugins;
    },
});

export { SimpliCityLink, SimpliCityLink as default }
