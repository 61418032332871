/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { FormReducer } from 'cms/forms/context/reducer/form-reducer'
import { useImmerReducer } from 'use-immer'
import { FormContext } from './form.context'

const FormProvider = (props) => {

    const { initState, children } = props
    const [state, dispatch] = useImmerReducer(FormReducer, initState)
    const formContext = { state, dispatch }

    return (
        <FormContext.Provider value={formContext}>
            {children}
        </FormContext.Provider>
    )
}

export { FormProvider }
