/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isString, isStringNotEmpty } from 'cms/utils/empty-exists'
import { useMemo } from "react"


/**
 * @param {string | QuestionNameSeparateValue} name
 */
export function nameTransformer (name) {

    let value = ''

    if (isString(name)) {
        value = name
    } else {
        const {
            hasFirstName,
            hasMiddleName,
            hasLastName,
            hasNickName
         } = name

         let fullName = [hasFirstName, hasMiddleName, hasLastName]
            .filter(isStringNotEmpty)
            .join(' ')

        if (hasNickName) {
            fullName += ` (${hasNickName})`
        }

         value = fullName
    }

    return value
}

/**
 * @type {SnapshotComponent<string | QuestionNameSeparateValue>}
 */
const NameSnapshot = (props) => {
    const { value } = props

    const formattedValue = useMemo(
        () => nameTransformer(value),
        [value]
    )

    return (
        <>{formattedValue}</>
    )
}

export { NameSnapshot }

/**
 * @typedef {object} QuestionNameSeparateValue
 * @property {string} hasFirstName
 * @property {string} hasMiddleName
 * @property {string} hasLastName
 * @property {string} hasNickName
 */
