/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useEffect, useState } from 'react'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { generateImageSlides } from './utils'
import { isArrayNotEmpty, isValidGuid } from 'cms/utils/empty-exists'

const ImageGalleryCollage = (props) => {

    const { token } = useAuth()
    const { image_gallery } = props || {}
    const [slides, setSlides] = useState([])
    const [index, setIndex] = useState(-1)
    const showToggle = true
    const descriptionTextAlign = 'start'
    const descriptionMaxLines = 3

    useEffect(() => {
        if (image_gallery && image_gallery.length > 0) {
            const images = image_gallery.filter((element) => isValidGuid(element?.image))
            generateImageSlides(images, token)
                .then((result) => setSlides(result)
                )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image_gallery])

    const onClose = () => {
        setIndex(-1)
    }

    const onClickImage = (clickParams) => {
        // https://react-photo-album.com/documentation#ClickHandler
        const { index: current } = clickParams
        setIndex(current)
    }

    return (
        <div className="simplicity-image-list-item">
            {}
            {isArrayNotEmpty(slides) && (
                <>
                    <PhotoAlbum
                        layout="masonry"
                        photos={slides}
                        targetRowHeight={150}
                        onClick={onClickImage}
                    />
                    <Lightbox
                        index={index}
                        slides={slides}
                        plugins={[Captions,Fullscreen]}
                        captions={{ showToggle, descriptionTextAlign, descriptionMaxLines }}
                        open={index >= 0}
                        close={onClose}
                    />
                </>
            )}
        </div>
    );
}

export { ImageGalleryCollage }
