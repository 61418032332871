/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { DynamicResponse } from './dynamic-response'

/**
 * @param {object} props
 * @param {QuestionResponseGroup} props.responseGroup
 */
const DynamicResponseGroup = (props) => {
    const { responseGroup } = props
    const { title, responses } = responseGroup

    return (
        <div className='dynamic-response-group'>
            <h3 className='group-title'>{title}</h3>

            <div className='responses'>
                {responses.map(
                    (response) => (
                        <DynamicResponse
                            key={`response-${response.guid}`}
                            response={response}
                        />
                    )
                )}
            </div>
        </div>
    )
}

export { DynamicResponseGroup }
