/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

/**
 * @type {import('react').FC}
 */
const SearchField = () => {
    const provider = new OpenStreetMapProvider()

    const searchControl = new GeoSearchControl({
        provider: provider,
        style: "bar",
        autoComplete: true, // optional: true|false  - default true
        autoCompleteDelay: 250, // optional: number      - default 250
        autoClose: true,
        showMarker: true,
        retainZoomLevel: true,
        notFoundMessage: "Sorry, that address could not be found.",
    })

    const map = useMap()
    useEffect(() => {
        map.addControl(searchControl)
        return () => {
            map.removeControl(searchControl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export { SearchField }
