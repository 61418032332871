/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

const TabsBar = (props) => {

    const { tabs, selectTab, selected } = props

    const onClickTab = (event, id) => {
        selectTab(event, id)
    }

    return (
        <div className="tab-nav-wrapper website-tab-bar-wrapper">
            <ul className="tab-nav" role="tablist">
                {tabs.map((tab, index) => {
                    const {id, text} = tab || {}
                    const visible = selected === id
                    return (
                        <li
                            key={id}
                            data-tab-index={index}
                            role="tab"
                            tabIndex={index}
                            aria-selected={selected}
                            aria-controls={id}
                            id={id}
                            onClick={(event) => onClickTab(event, id)}
                            className={visible ? "active" : ""}
                        >
                            <span>{text}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

TabsBar.displayName = 'TabsBar'
export { TabsBar }

