/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Chip } from '@mui/material'
import { FaWrapper } from '../../fa-wrapper'
// TODO: move styles
import styles from '../dropdown-option.module.css'
// import { solid } from 'icons'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// const { faSquare, faSquareCheck } = solid

/**
 * @param {RefinementOptionProps} props
 */
export function RefinementOption (props) {
    const { children, data, innerProps, isSelected, innerRef } = props

    return (
        <div ref={innerRef} {...innerProps} className={styles.container}>
            {/* checkbox simulation */}
            <FaWrapper
                icon="fa-solid fa-square-check fa-lg"
                color="var(--color-dnv-focus-green)"
                show={isSelected}
            />
            {/*<FontAwesomeIcon*/}
            {/*    icon={faSquareCheck}*/}
            {/*    size={'lg'}*/}
            {/*/>*/}
            <FaWrapper
                icon="fa-regular fa-square fa-lg"
                show={!isSelected}
            />
            {/*<FontAwesomeIcon*/}
            {/*    icon={faSquare}*/}
            {/*    size={'lg'}*/}
            {/*/>*/}

            <div className={`${styles.label} ${styles.center}`}>
                <p>{children}</p>
                <Chip label={data.count} color="navy" size="small" />
            </div>
        </div>
    )
}
