/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const ScaledFullWidthImage = (props) => {

    const { image, settings } = props || {}
    const {
        dimension = '100%',
        forceSixteenNine = false,
        // contain = false,
    } = settings || {}
    const { width, height } = image || {}
    const hasWidth = !!width
    const hasHeight = !!height
    let aspect_ratio
    if (forceSixteenNine) {
        // use 16/9 default
        aspect_ratio = '16/9'
    } else if (hasWidth && hasHeight) {
        const longerSide = height >= width ? 'height' : 'width'
        aspect_ratio = (longerSide === 'height') ? `${height}/${width}` : `${width}/${height}`
    } else {
        // leave aspect_ratio undefined
    }

    return (
        <>
            {image &&
                <>
                    <img
                        src={image.url}
                        alt={image.alt}
                        width={dimension}
                        style={{
                            aspectRatio: aspect_ratio
                        }}
                    />
                </>
            }
        </>
    )
}

export { ScaledFullWidthImage }
