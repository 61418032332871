/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from "react"

/**
 * This render the following form:
 *
 * 123 Nowhere Drive, Unit #001, Someplace, OverHere, 55012-123, Somewhere
 * @param {QuestionAddressValue} address
 */
export function addressTransformer (address) {
    const { city, country, postcode, province, street_address, unit } = address

    let value = street_address

    if (unit) {
        value += `, Unit #${unit}`
    }

    value += `, ${city}, ${province}, ${postcode}`

    if (country) {
        value += `, ${country}`
    }

    return value
}

/**
 * @type {SnapshotComponent<QuestionAddressValue>}
 */
const AddressSnapshot = (props) => {
    const { value } = props

    const formattedValue = useMemo(
        () => addressTransformer(value),
        [value]
    )

    return (
        <>{formattedValue}</>
    )
}

export { AddressSnapshot }

/**
 * @typedef {object} QuestionAddressValue
 * @property {string} street_address
 * @property {string=} unit
 * @property {string} city
 * @property {string} province
 * @property {string=} country
 * @property {string} postcode
 */
