/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from 'react'

/**
 * Takes validation configurations and returns numeric contraints.
 * @param {boolean} hasDecimalValue - Enable decimal validation
 * @param {string} decimalValue - Number of decimal places
 * @param {boolean} hasMinValue - Enable minimum validation
 * @param {string} minValue - Minimum value
 * @param {boolean} hasMaxValue - Enable maximum valdiation
 * @param {string} maxValue - Maximum value
 */
export function useNumericalConstraints (
    hasDecimalValue,
    decimalValue,

    hasMinValue,
    minValue,

    hasMaxValue,
    maxValue,
) {

    const constraints = useMemo(
        () => {
            const min = hasMinValue && minValue ? Number(minValue) : undefined
            const max = hasMaxValue && maxValue ? Number(maxValue) : undefined
            const hasDecimalValueNumber = !!decimalValue  // is likely a string
            const decimals = hasDecimalValue && hasDecimalValueNumber ? Number(decimalValue) : 0

            return {
                min,
                max,
                decimals
            }
        },
        [decimalValue, hasDecimalValue, hasMaxValue, hasMinValue, maxValue, minValue]
    )

    return constraints
}
