/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import ErrorMsg from 'cms/forms/error-msg'
import { useAddressError } from './use-address-error'
import { useAddressValue } from './use-address-value'


/**
 * @type {QuestionComponent<FormQuestionAddress>}
 */
const SimplicityFormAddress = (props) => {

    const { field } = props
    const {
        prompt,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        isRequired,
    } = field || {}

    const { address, onChangeAnswer } = useAddressValue(field)
    const { error, errorClass, hasError } = useAddressError(field, address)

    return (
        <div className="simplicity-forms-field-group address-question">
            <h3 className="simplicity-forms-field-label address-label">
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </h3>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}

            {hasError && <ErrorMsg type="error" error={error} />}

            <div className='address-question-grid'>
                <div className='street-address'>
                    <p>Street Address</p>
                    <input
                        className={errorClass('street_address')}
                        value={address.street_address}
                        onChange={(event) => onChangeAnswer(event, 'street_address')}
                    />
                </div>
                <div className='unit'>
                    <p>Apt, Suite, etc.</p>
                    <input
                        value={address.unit}
                        onChange={(event) => onChangeAnswer(event, 'unit')}
                    />
                </div>
                <div className='city'>
                    <p>City</p>
                    <input
                        className={errorClass('city')}
                        value={address.city}
                        onChange={(event) => onChangeAnswer(event, 'city')}
                    />
                </div>
                <div className='province'>
                    <p>Province</p>
                    <input
                        className={errorClass('province')}
                        value={address.province}
                        onChange={(event) => onChangeAnswer(event, 'province')}
                    />
                </div>
                <div className='country'>
                    <p>Country</p>
                    <input
                        value={address.country}
                        onChange={(event) => onChangeAnswer(event, 'country')}
                    />
                </div>
                <div className='postcode'>
                    <p>Postal / Zip Code</p>
                    <input
                        className={errorClass('postcode')}
                        value={address.postcode}
                        onChange={(event) => onChangeAnswer(event, 'postcode')}
                    />
                </div>
            </div>


            {hasHelperText && helperText && <span className="simplicity-forms-helper-text">{helperText}</span>}
        </div>
    )
}

export { SimplicityFormAddress }
