/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { useSimpliCityImage } from 'cms/images/use-simplicity-image'
import { SimpliCityFormattedImage } from 'cms/images/formatted-image'
import { getProperty } from 'cms/utils'

/**
 * Image
 * For use with Model.banner_image as GUID
 * accepts a model object, and expects this to have a 'banner_image' property.
 * THe banner_image is expected to contain {image, settings} where image is a guid.
 * This component will look up the image data and show the image.
 * @param props
 * @returns {Element}
 * @constructor
 */
const Image = (props) => {

    const { model, showCaption } = props || {}
    const bannerImage = getProperty(model, 'banner_image')
    const { image, settings } = useSimpliCityImage(bannerImage)

    return (
        <>
            {image && (
                <Fragment>
                    <SimpliCityFormattedImage
                        image={image}
                        settings={settings}
                        showCaption={showCaption}
                    />
                </Fragment>
            )}
        </>
    )
}

export { Image }
