/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {FormQuestionMultipleChoice} question
 * @param {Record<string, string>=} labels
 */
const multipleChoiceValueTransformer = (question, labels = {}) => {
    const { value: selectedOptionId, options } = question

    const selectedOption = options.find(
        (option) => option.id === selectedOptionId
    )

    // when the question is optional, just return an empty string
    if (!selectedOption) {
        return ''
    }

    const { isOther, label: selectedLabel } = selectedOption

    const otherLabel = labels[selectedOptionId]
    const label = isOther && otherLabel ?
        otherLabel :
        selectedLabel

    return label
}

export { multipleChoiceValueTransformer }
