/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { filterHiddenRecursively } from 'cms/shared/read_node'
import { useAsync } from 'react-use'

const useFetchNavigationList = () => {
    const { token } = useAuth()
    const { webpage } = useSimpliCity()
    const { guid: webpage_guid } = webpage

    const { loading, error, value } = useAsync(async () => {
        // TODO: this tree may already be loaded in the store
        /** @type {{ children: NavigationItem[] }} */
        const response = await SimpliCitySDK.webpage.findTree(token, webpage_guid) || {}
        filterHiddenRecursively(response)

        return response.children
    }, [token, webpage_guid])

    return {
        loading,
        error,
        children: value
    }
}

export { useFetchNavigationList }


/**
 * @typedef {object} BaseNavigationItem
 * @property {string} guid - The unique identifier for the navigation item.
 * @property {string} parent - The parent identifier for the navigation item.
 * @property {string} nav_title - The title of the navigation item.
 * @property {string|null} nav_icon - The icon associated with the navigation item (if any).
 * @property {string} path - The path to the navigation item.
 * @property {string|null} description - A description of the navigation item (if available).
 * @property {number} hide_in_nav - Indicates whether the item should be hidden in the navigation (0 or 1).
 */

/**
 * @typedef {BaseNavigationItem & {children: BaseNavigationItem[]}} NavigationItem
 */
