/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {FormQuestion | FormQuestionGroup} question
 * @returns {question is FormQuestionGroup}
 */
const isGroupQuestion = (question) => {
    return question.type === 'question.group'
}

export { isGroupQuestion }
