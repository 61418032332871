/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { Link } from './link'
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const LinkListComponent = (props) => {

    const { component } = props || {}
    const { links = [], title, settings } = component || {}
    const { style, format } = settings || {}
    const hasTitle = !!title

    return (
        <div className='link-list'>

            {hasTitle &&
                <h2>{title}</h2>
            }

            {isArrayNotEmpty(links) &&
                <>
                    {links.map((link, idx) => (
                        <Link
                            key={idx}
                            link={link}
                            format={format}
                            style={style}
                        />
                    ))}
                </>
            }
        </div>
    )
}

LinkListComponent.displayName = 'LinkListComponent'
export { LinkListComponent }
