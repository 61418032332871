/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect } from "react"
import { LayoutWrapper } from "cms/layout/layout-wrapper"
import { useFetchWebpage } from "cms/hooks/use-fetch-webpage"
import { useSimpliCityPath } from "cms/webpage/use-simplicity-path"
import { EnvWrapper } from 'cms/auth/auth/components/env-wrapper'
// import { RemoveTrailingSlash } from "../remove-trailing-slash";

const Webpage = () => {

    const { fetchWebpageDataComplete } = useFetchWebpage()
    const { path } = useSimpliCityPath()

    useEffect(() => {
        if (path) {
            // console.log(`[Webpage] -> fetchWebpageData(${path})`)
            fetchWebpageDataComplete(path)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    return (
        <>
            <EnvWrapper>
                <LayoutWrapper />
            </EnvWrapper>
        </>
    )
}

export { Webpage }
