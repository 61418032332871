/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { FaWrapper } from 'components/search/fa-wrapper'
import React from 'react'
import { NavLink } from 'react-router-dom'

/**
 * @param {object} props
 * @param {string} props.alt
 * @param {string} props.text
 * @param {string=} props.href
 * @param {boolean} props.isLast
 */
export function Crumb (props) {

    const { alt, text, href, isLast } = props
    const hasHref = !!href

    return (
        <li className="simplicity-breadcrumb-item">
            {hasHref
                ? (<NavLink to={href} title={alt}>{text}</NavLink>)
                : text}
            <FaWrapper
                icon="fa-regular fa-chevron-right"
                show={!isLast}
            />
        </li>
    );
}
