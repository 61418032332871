/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { DOCUMENT_FORMATS, formatBytes } from 'cms/components/document-list/helpers'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

const IconItem = (props) => {

    const {
        doc,
        document,
        format,
        // listStyle
    } = props || {}
    const style = 'body'  // DOCUMENT_STYLES.BODY // only option
    const { title } = doc
    const { file_size, url } = document
    const isTitleSize = format === DOCUMENT_FORMATS.TITLE_SIZE


    return (
        <div className={`list-item ${style}`}>
            <a
                href={url}
                className={`display-as ${style} `}
                target={format}
                rel='noreferrer'
            >
                <div className='display-as-link'>
                    <div className='icon'><DescriptionOutlinedIcon /></div>
                    <span className='document-text'>{title}</span>
                </div>
            </a>
            {isTitleSize &&
                <span className='document-file-size'>({formatBytes(file_size)})</span>
            }
        </div>
    )
}

export default IconItem
