/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { CmsConfig } from 'cms/config'

function ErrorDisplay (props) {

    const { error } = props

    return (
        error && CmsConfig.showErrors &&
            <div className="Error">
                <ul>
                    {error.message}
                </ul>
            </div>
    )
}

export { ErrorDisplay }
