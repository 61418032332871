/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useState, useEffect } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { isObject, isStringNotEmpty, objectHasPropertyString } from 'cms/utils/empty-exists'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import AccordionItem from '../accordion/accordion-item'
import BulletPointItem from '../bullet/bullet-point-item'
import SimpleListItem from '../simple/simple-list-item'
import IconItem from '../icon/icon-item'
import { DOCUMENT_LIST_TYPES } from 'cms/components/document-list/helpers'

const Document = (props) => {
    const { doc, style, format, listStyle } = props || {}
    const [document, setDocument] = useState()
    const { token } = useAuth()
    const isAccordion = listStyle === DOCUMENT_LIST_TYPES.ACCORDION
    const isSimpleList = listStyle === DOCUMENT_LIST_TYPES.SIMPLE
    const isIconList = listStyle === DOCUMENT_LIST_TYPES.ICON
    const isBulletList = listStyle === DOCUMENT_LIST_TYPES.BULLET

    const loadDoc = async (guid) => {
        if (guid && guid.length > 30) {
            const _doc = await SimpliCitySDK.documents.findOne(token, guid)
            setDocument(_doc)
            return _doc
        }
    }
    useEffect(() => {
        const load = async (guid) => {
            if (isStringNotEmpty(guid)) {
                const _doc = await loadDoc(guid)
                setDocument(_doc)
            }
        }
        // load once
        if (!isObject(document) || !objectHasPropertyString(document, 'guid')) {
            const guid = doc?.document
            load(guid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc?.document])

    return (
        <>
            {doc && document && isSimpleList &&
                <SimpleListItem
                    doc={doc}
                    document={document}
                    style={style}
                    format={format}
                    listStyle={listStyle}
                />
            }
            {doc && document && isIconList &&
                <IconItem
                    doc={doc}
                    document={document}
                    style={style}
                    format={format}
                    listStyle={listStyle}
                />
            }
            {doc && document && isAccordion &&
                <AccordionItem
                    doc={doc}
                    document={document}
                    style={style}
                    format={format}
                    listStyle={listStyle}
                />
            }
            {doc && document && isBulletList &&
                <BulletPointItem
                    doc={doc}
                    document={document}
                    style={style}
                    format={format}
                    listStyle={listStyle}
                />
            }
        </>
    )
}

export default Document
