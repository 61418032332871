/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const ScaledHeightImage = (props) => {

    const {
        image,
        settings,
        orientation,
        style_class
    } = props || {}
    const { width, height, url, alt } = image || {}
    const { options } = settings || {}
    const {
        dimension = '100%',
        forceSixteenNine = false,
        contain = false
    } = options || {}
    const hasWidth = !!width
    const hasHeight = !!height

    let aspect_ratio, fit, maxWidth
    if (forceSixteenNine) {
        aspect_ratio = '16/9'
        // fit = 'cover'
        fit = 'contain'
        maxWidth = '100%'
    } else if (contain) {
        fit = 'contain'
        maxWidth = '100%'
    } else if (hasWidth && hasHeight) {
        fit = orientation === 'vertical' ? 'contain' : undefined
        maxWidth = '100%'
        const longerSide = height >= width ? 'height' : 'width'
        aspect_ratio = (longerSide === 'height') ? `${height}/${width}` : `${width}/${height}`
    }

    return (
        <>
            {image &&
                <>
                    <img
                        src={url}
                        alt={alt}
                        height={dimension}
                        className={style_class}
                        style={{
                            aspectRatio: aspect_ratio,
                            objectFit: fit,
                            maxWidth: maxWidth,
                        }}
                    />
                </>
            }
        </>
    )
}

export { ScaledHeightImage }
