/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useForm } from 'cms/forms/context'
import { ErrorMessages } from "cms/forms/error-messages"
import { useShowError } from "cms/forms/hooks/use-show-error"
import { isStringEmpty } from "cms/utils/empty-exists"
import { phoneMask } from 'cms/utils/inputMask'
import { useMemo } from "react"
import { IMaskInput } from 'react-imask'
import ErrorMsg from '../error-msg'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'

// If this is defined within the component, then it will constantly cause a re-render.
// It's static, so it can (likely should) exist outside of the component body so that it is not computed
// on every single render.
const validRegex = /^[+]?(1-|1\s|1|\d{3}-|\d{3}\s|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/

/**
 * @type {QuestionComponent<FormQuestion<string>>}
 */
const SimplicityFormPhoneNumber = (props) => {
    const { field } = props
    const {
        guid,
        description,
        prompt,
        value,
        hasPlaceholderText,
        helperText,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    const currentError = useMemo(
        () => {

            if (isRequired && isStringEmpty(value)) {
                return ErrorMessages.required()
            }

            const isValidPhone = validRegex.test(value)
            if (!isValidPhone) {
                return 'Please enter a valid phone number'
            }

            return null
        },
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const onChangePhoneNumber = (value) => {
        onInputChange(value, field)
    }

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {description &&
                <span className="simplicity-forms-description">{description}</span>
            }
            <IMaskInput
                id={guid}
                name={guid}
                mask={phoneMask}
                placeholder={hasPlaceholderText}
                value={value}
                onAccept={onChangePhoneNumber}
                className={hasError ? 'form-field-error-input' : ''}
            />
            {helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
            {hasError &&
                <ErrorMsg type="error" error={error} />
            }
        </div>
    )
}

export { SimplicityFormPhoneNumber };

