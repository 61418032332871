/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob'
import { Logger } from 'cms/utils/logger'

// const containerName = Config.AzureStorage.Images.STORAGE_CONTAINER_NAME

// return list of blobs in container to display
const getFilesInContainer = async (storageAccount, containerName, userDelegateSasToken) => {
    const blobServiceClient = new BlobServiceClient(
        // `${process.env.REACT_APP_AZURE_STORAGE_CONTAINER}?${userDelegateSasToken}`,  // includes storage account + container name
        // `${process.env.REACT_APP_STORAGE_ACCOUNT_URL}?${userDelegateSasToken}`,     // only storage account
        `${storageAccount}?${userDelegateSasToken}`, // only storage account
        new AnonymousCredential(),
    )
    const containerClient = blobServiceClient.getContainerClient(containerName)
    const containerUrl = `${storageAccount}/${containerName}/`
    const returnedBlobUrls = []
    for await (const blob of containerClient.listBlobsFlat()) {
        returnedBlobUrls.push({
            storageAccount: storageAccount,
            containerName: containerName,
            containerUrl: containerUrl,
            name: blob.name,
            // FIXME: using sas token to provide access to file - review this implementation
            url: `${containerUrl}${blob.name}?${userDelegateSasToken}`, //  includes storage account + container name
        })
    }
    Logger.debug('blob list', returnedBlobUrls)
    return returnedBlobUrls
}

// FIXME: This function is not called by anything
/**
 * Gets list of blobs in container to display
 * @param {*} storageAccount
 * @param {*} containerName
 * @param {*} file_name
 * @param {*} sasToken
 */
const getImageFromStorage = async (storageAccount, containerName, file_name, sasToken) => {
    const blobServiceClient = new BlobServiceClient(
        `${storageAccount}?${sasToken}`, // only storage account
        new AnonymousCredential(),
    )
    const containerClient = blobServiceClient.getContainerClient(containerName)
    const containerUrl = `${storageAccount}/${containerName}/`
    const returnedBlobUrls = []
    for await (const blob of containerClient.listBlobsFlat()) {
        returnedBlobUrls.push({
            storageAccount: storageAccount,
            containerName: containerName,
            containerUrl: containerUrl,
            name: blob.name,
            // FIXME: using sas token to provide access to file - review this for best practices
            // url: `${containerUrl}${blob.name}?${userDelegateSasToken}`   //  includes storage account + container name
        })
    }
    Logger.debug(`blob list ${returnedBlobUrls}`)
    return returnedBlobUrls
}

const createFileInContainer = async (containerClient, file) => {
    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(file.name)

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } }

    // upload file
    await blobClient.uploadData(file, options)

    // await blobClient.setMetadata(metadata);
}

const getContainerClient = async (storageAccount, containerName, userDelegateSasToken) => {

    try {
        Logger.log(`Getting Container Client for ${storageAccount}/${containerName}/?${userDelegateSasToken}`)
        const blobServiceClient = new BlobServiceClient(
            `${storageAccount}?${userDelegateSasToken}`, // client for whole of storage account
            new AnonymousCredential(),
        )

        const containerClient = blobServiceClient.getContainerClient(containerName)
        // await containerClient.createIfNotExists({
        //     access: 'container',
        // })
        return containerClient
    } catch (err) {
        Logger.error(err)
        Logger.flush()
        throw err
    }
}

export { getContainerClient, getFilesInContainer, createFileInContainer, getImageFromStorage }

// DOWNLOAD
// https://docs.microsoft.com/en-us/javascript/api/@azure/storage-blob/blobclient?view=azure-node-latest#@azure-storage-blob-blobclient-download

/*
https://docs.microsoft.com/en-us/javascript/api/@azure/storage-blob/blobhttpheaders?view=azure-node-latest

properties= {
      blobContentType: 'text/plain',
      blobContentLanguage: 'en-us',
      blobContentEncoding: 'utf-8',
      // all other http properties are cleared
    }
*/
// async function setHTTPHeaders (blobClient, headers) {
//     await blobClient.setHTTPHeaders(headers)
//     console.log(`headers set successfully`)
// }

/*  metadata= { reviewedBy: 'Bob', releasedBy: 'Jill' }  */
// async function setBlobMetadata (blobClient, metadata) {
//     await blobClient.setMetadata(metadata)
//     console.log(`metadata set successfully`)
// }

// async function getProperties (blobClient) {
//     const properties = await blobClient.getProperties()
//     console.log(blobClient.name + ' properties: ')

//     for (const property in properties) {
//         switch (property) {
//             // nested properties are stringified and returned as strings
//             case 'metadata':
//             case 'objectReplicationRules':
//                 console.log(`    ${property}: ${JSON.stringify(properties[property])}`)
//                 break
//             default:
//                 console.log(`    ${property}: ${properties[property]}`)
//                 break
//         }
//     }
// }

// const blob_properties_fields = [
//     'acceptRanges',
//     'accessTier',
//     'accessTierChangedOn',
//     'accessTierInferred',
//     'archiveStatus',
//     'blobCommittedBlockCount',
//     'createdOn',
//     'blobSequenceNumber',
//     'blobTypeBlockBlob',
//     'body',
//     'cacheControl',
//     'clientRequestId',
//     'contentDisposition',
//     'contentEncoding',
//     'contentLength',
//     'contentMD5',
//     'contentType',
//     'contentLanguage',
//     'copyCompletedOn',
//     'copyId',
//     'copyProgress',
//     'copySource',
//     'copyStatus',
//     'copyStatusDescription',
//     'date',
//     'destinationSnapshot',
//     'encryptionKeySha256',
//     'encryptionScope',
//     'errorCode',
//     'expiresOn',
//     'etag',
//     'immutabilityPolicyExpiresOn',
//     'immutabilityPolicyMode',
//     'isCurrentVersion',
//     'isIncrementalCopy',
//     'isSealed',
//     'isServerEncrypted',
//     'lastAccessed',
//     'lastModified',
//     'leaseDuration',
//     'leaseState',
//     'leaseStatus',
//     'legalHold',
//     'metadata', // <-- metadata JSON here
//     'objectReplicationPolicyId',
//     'objectReplicationRules',
//     'objectReplicationDestinationPolicyId',
//     'objectReplicationSourceProperties',
//     'requestId',
//     'rehydratePriority',
//     'tagCount',
//     'version',
//     'versionId',
//     '_response',
// ]

// const { DefaultAzureCredential,  InteractiveBrowserCredential } = require('@azure/identity');
// const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
// const signInOptions = {
//     clientId: Config.Azure.AZURE_CLIENT_ID,
//     tenantId: Config.Azure.AZURE_TENANT_ID,
//     redirectUri: Config.Azure.AZURE_REDIRECT_URI
// }

// const blobServiceClient = new BlobServiceClient(
//     process.env.REACT_APP_STORAGE_ACCOUNT_URL,
//     new InteractiveBrowserCredential(signInOptions)
// )

// Configure Token for example
// https://docs.microsoft.com/en-us/azure/developer/javascript/tutorial/browser-file-upload-azure-storage-blob
// const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
// export const isStorageConfigured = () => {
//     return (!storageAccountName || !sasToken) ? false : true;
// }
// const uploadFileToBlob = async (file) => {
//     if (!file) return [];
//
//     // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
//     const blobService = new BlobServiceClient(
//         `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
//     );
//
//     // get Container - full public read access
//     const containerClient = blobService.getContainerClient(containerName);
//     await containerClient.createIfNotExists({
//         access: 'container',
//     });
//
//     // upload file
//     await createBlobInContainer(containerClient, file);
//
//     // get list of blobs in container
//     return getBlobsInContainer(containerClient);
// };

// // Create a User Delegation Key
// const userDelegationKey = await blobServiceClient.getUserDelegationKey(
//     TEN_MINUTES_BEFORE_NOW,
//     TEN_MINUTES_AFTER_NOW
// );
// const containerSAS = generateBlobSASQueryParameters({
//         containerName, // Required
//         permissions: ContainerSASPermissions.parse("racwdl"), // Required
//         startsOn, // Optional. Date type
//         expiresOn, // Required. Date type
//         ipRange: { start: "0.0.0.0", end: "255.255.255.255" }, // Optional
//         protocol: SASProtocol.HttpsAndHttp, // Optional
//         version: "2018-11-09" // Must greater than or equal to 2018-11-09 to generate user delegation SAS
//     },
//     userDelegationKey, // UserDelegationKey
//     account
// ).toString();

// get Container - full public read access
// await containerClient.createIfNotExists({
//     access: 'container',
// });
