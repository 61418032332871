/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { ScaledWidthImage } from './scaled-width-image'
import { ScaledHeightImage } from './scaled-height-image'
import { ScaledFullWidthImage } from './scaled-fullwidth-image'
import { CroppedFocusImage } from './cropped-focus-image'
import { isNull, isString } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'
// import validator from 'cms/shared/images/validator'

const SimpliCityFormattedImage = (props) => {

    const {
        image,
        settings,
        showCaption = true
    } = props || {}
    const hasContent = !!image
    const FORMATS = {
        CROPPED_FOCUS: 'image.format.cropped-focus',
        SCALED_IMAGE: 'image.format.scaled-image',
    }
    const { format, options } = settings || {}
    const { method, useCaption } = options || {}
    const { description: caption } = image || {}
    const orientation = (image?.width > image?.height) ? 'horizontal' : 'vertical'
    const isScaled = format === FORMATS.SCALED_IMAGE

    let Component
    if (isScaled) {
        switch (method) {
            case 'WidthPriority':
                Component = <ScaledWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                    style_class={'WidthPriority'}
                />
                break
            case 'HeightPriority':
                Component = <ScaledHeightImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                    style_class={'HeightPriority'}
                />
                break
            case '100Aspect':
                Component = <ScaledFullWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                    style_class={'Percent100Aspect'}
                />
                break
            default:
                Component = <ScaledFullWidthImage
                    image={image}
                    settings={settings}
                    forceSixteenNine={false}
                    contain={false}
                    orientation={orientation}
                />
                break
        }
    } else {
        // i.e. where format === FORMATS.CROPPED_FOCUS
        Component = <CroppedFocusImage image={image} settings={settings} />
    }

    // NOTE: this implementation now gets a prepopulated banner image

    // useEffect(() => {
    //     // component has an image property containing the guid of the image
    //     let guid = _image
    //     // First check if the image is improperly saved
    //     // - we want a guid at this point not an image
    //     const isImage = validator.isImage(_image)
    //     if (isImage) {
    //         guid = _image.guid
    //     }
    //     const isGuid = validator.isGuid(guid)
    //     if (isGuid) {
    //         const fetchData = async () => {
    //             const img = await SimpliCitySDK.images.findOne(token, guid)
    //             setImage(img)
    //             setHasContent(!!img)
    //         }
    //         fetchData()
    //     } else {
    //         Logger.info(`Image property was not valid. [IMAGE: ${JSON.stringify(image)}]`)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [_image])

    if (isString(image) || isNull(image)) {
        Logger.warn('invalid image data in SimpliCityFormattedImage (should be guid)')
        // throw Error('invalid image data in SimpliCityFormattedImage (should be guid)')
        return null
    }

    return (
        <>
            {image && hasContent &&
                <>
                    {Component}
                    {showCaption && useCaption && caption &&
                        <div className='simplicity-image-caption'>{caption}</div>
                    }
                </>
            }
        </>
    )
}

export { SimpliCityFormattedImage }
