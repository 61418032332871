/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useState } from 'react'
import { ResultItem } from 'cms/shared/feeds/calendar/result-item'
import { SearchFilterBar } from 'cms/shared/feeds/search-filter-bar'
import { EventCalendar } from 'cms/shared/event-calendar/calendar'
import { Tabs } from 'components/tabs'
import { TabContent } from 'components/tabs/tab-content'
import { useCategoryInfo } from 'cms/hooks/use-category-info'
import { useFetchEventFeed } from 'cms/shared/feeds/hooks/use-fetch-event-feed'
import { FeedPagination } from 'cms/shared/feeds/pagination/feed-pagination'

const CalendarFeedFilterComponent = (props) => {

    const {
        selected_type,
        selected_category,
        enable_bar,
    } = props

    const tabs = [
        {id:0, text: 'Calendar'},
        {id:1, text: 'List'},
    ]
    const [selectedTab, setSelectedTab] = useState(0)
    // const [selectedType, setSelectedType] = useState(selected_type)
    const selectedType = selected_type
    // const [selectedCategory, setSelectedCategory] = useState(selected_category)
    const selectedCategory = selected_category
    // const [enableBar, setEnableBar] = useState(enable_bar)
    const enableBar = enable_bar
    // const [isCalender, setIsCalender] = useState(true)
    const isCalender = selectedTab === 0
    // const isCalender = true  // selected === 0
    const chooseSelectedTab = (id) => {
        setSelectedTab(id)
    }
    // get categories based on 'model.event', meetings share the same ones
    // const type = 'model.event'
    const {
        categories,
        categoryName,
        filterName
    } = useCategoryInfo(selectedType)
    // } = useCategoryInfo('model.event')

    const {
        loading,
        value,
        pagination,
        search
    } = useFetchEventFeed(selectedType, categoryName, selectedCategory, isCalender)
    // } = useFetchEventFeed('category', selectedCategory, isCalender)

    const {
        first,
        last,
        hasResults,
        total,
    } = pagination.state

    const { models } = value || {}

    return (
        <>

            {enableBar &&
                <SearchFilterBar
                    type={selectedType}
                    selectedCategory={selectedCategory}
                    categories={categories}
                    filterName={filterName}
                    runSearch={search}
                />
            }

            {/*<p>{selected_type}</p>*/}
            {/*<p>{selected_category}</p>*/}

            <Tabs
                tabs={tabs}
                selected={selectedTab}
                setSelected={chooseSelectedTab}
            >

                {/*--------------   Calendar List    -------------------*/}
                <TabContent
                    id={0}
                    tab={tabs[0]}
                    selected={selectedTab}
                >
                    <div style={{
                        display: "flex", flexDirection: "column",
                        justifyContent: "center",
                        alignItems: 'center',
                        width: '100%',
                    }}>

                        <EventCalendar
                            events={models}
                        />

                    </div>
                </TabContent>


                {/*--------------   Results List    -------------------*/}
                <TabContent
                    id={1}
                    tab={tabs[1]}
                    selected={selectedTab}
                >
                    <div className='loading-indicator'>
                        {loading &&
                            <p>Loading...</p>
                        }
                        {!loading && hasResults &&
                            <p>{first} to {last} of {total} results.</p>
                        }
                        {!loading && !hasResults &&
                            <p>{total} results.</p>
                        }
                    </div>

                    {/*<CalendarFeedFilter type={'model.meeting'} />*/}

                    <div className="template-wrap">
                        <div style={{
                            display: "flex", flexDirection: "column",
                            justifyContent: "center",
                        }}>
                            {models && models.map((model, index) => {
                                const { Id } = model
                                return (
                                    <ResultItem key={`${Id}${index}`} model={model} />
                                )
                            })}

                            <FeedPagination
                                pagination={pagination}
                            />

                        </div>
                    </div>

                </TabContent>

            </Tabs>

        </>
    )
}

export { CalendarFeedFilterComponent }
