/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/* --------------------------------------------------------------------------
     This file contains default configuration for extensions
* --------------------------------------------------------------------------- */
export const extensionConfig = {
    Blockquote: {
        enabled: true,
        options: {}
    },
    Bold: { enabled: true,
        options: {}
    },
    BulletList: {
        enabled: true,
        options: {}
    },
    Code: {
        enabled: true,
        options: {}
    },
    CodeBlock: {
        enabled: true,
        options: {}
    },
    ColumnExtension: {
        enabled: true,
        options: {}
    },
    Document: {
        enabled: true,
        options: {}
    },
    Dropcursor: {
        enabled: true,
        options: {}
    },
    Gapcursor: {
        enabled: true,
        options: {}
    },
    HardBreak: {
        enabled: true,
        options: {}
    },
    History: {
        enabled: true,
        options: {}
    },
    HorizontalRule: {
        enabled: true,
        options: {}
    },
    Italic: {
        enabled: true,
        options: {}
    },
    ListItem: {
        enabled: true,
        options: {}
    },
    OrderedList: {
        enabled: true,
        options: {}
    },
    Strike: {
        enabled: true,
        options: {}
    },
    Text: {
        enabled: true,
        options: {}
    },
    // Link: {
    //     enabled: true,
    //     options: { openOnClick: false }
    // },
    Underline: {
        enabled: true,
        options: {}
    },
    TextAlign: {
        enabled: true,
        options: {
            types: ['heading', 'paragraph'],
            alignments: ['left', 'center', 'right']
        }
    },
    CustomTable: {
        enabled: true,
        options: {
            resizable: true,
            lastColumnResizable: false
        }
    },
    TableRow: {
        enabled: true,
        options: {}
    },
    TableHeader: {
        enabled: true,
        options: {}
    },
    TableCell: {
        enabled: true,
        options: {}
    },
    CustomParagraph: {
        enabled: true,
        options: {}
    },
    CustomHeading: {
        enabled: true,
        options: { enableTOC: false }
    },
    SimpliCityDocLink: {
        enabled: true,
        options: { openOnClick: false, autolink: false }
    },
    SimpliCityLink: {
        enabled: true,
        options: { openOnClick: false }
    },
    SimpliCityImage: {
        enabled: true,
        options: {}
    },
}
