/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, {Fragment} from 'react'

const ListImage = (props) => {

    // let image_style = {display: 'none'}
    const image = props.image

    const width = 290
    const height = 194
    const image_style = {}
    const alt = "alt is not defined"

    return (
        <Fragment>
            {image &&
                <div style={image_style}>
                    <img alt={alt}
                         width={width}
                         height={height}
                         src={image.url}
                    />
                </div>
            }
        </Fragment>
    )
}
ListImage.displayName = "ListImage"
export { ListImage }
