/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'
import { SimpliCitySDK } from '../sdk'
import { Logger } from 'cms/utils/logger'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'

const  useSimpliCityImage = (data) => {

    const { image: guid, settings } = data || {}
    const { token } = useAuth()
    const [image,setImage] = useState()
    const [imageGuid,setImageGuid] = useState()

    useEffect(() => {
        const fetchImage = async (_guid) => {
            if (_guid && imageGuid !== _guid) {
                let _image = await SimpliCitySDK.images.findOne(token, _guid)
                if (_image && _image?.guid) {
                    setImage(_image)
                    setImageGuid(_image?.guid)
                    Logger.debug('setImage', _image)
                } else {
                    Logger.debug('Could not load Image...', _guid)
                }
            } else {
                setImage(undefined)
            }
        }
        fetchImage(guid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guid])

    return { image, settings }
}

export { useSimpliCityImage }
