/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { LinearProgress } from '@mui/material'
import { formatBytes } from 'cms/utils/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icons } from 'icons'
// import { getAcceptableFilename } from "cms/forms/questions/file-upload/upload-files-utils"

/**
 * @type {import('react').FC<UploadingFileProps>}
 */
const UploadingFile = (props) => {

    const {
        file,
        onCancelFile,
    } = props

    // const fileName = getAcceptableFilename(file)

    const onCancelUpload = (event, file) => {
        event.stopPropagation()

        // remove file from upload list
        if (typeof onCancelFile === 'function') {
            onCancelFile(file)
        }

        // FIXME: we are leaving a file in azure storage.
        //   - TODO: actually cancel network request
        //   - TODO: make delete request to api
        //   - TODO:    make delete request to storage from the api
        //      See AbortController
        //        may also solve 'progress'
        //      https://stackoverflow.com/questions/65678404/how-to-cancel-azure-blob-upload-multiple-files
    }

    return (
        <div className="form-file-upload-file-listing">
            <div className="progress-bar-container">
                <LinearProgress variant="indeterminate" color='success' />
            </div>
            <div className="file-upload-control-bar">
                <div className="file-title">
                    <div>
                        {file?.name} ({formatBytes(file.size)})
                    </div>
                </div>
                <div
                    className="deleteIcon"
                    onClick={(event) => onCancelUpload(event, file)}
                >
                    <span className="simplicity-icon-inline">
                        <FontAwesomeIcon
                            icon={icons.regular.faXmark}
                            size="2x"
                        />
                    </span>
                    <span>Cancel</span>
                </div>
            </div>
        </div>
    )
}

UploadingFile.displayName = "UploadingFile";
export { UploadingFile };


/**
 * @typedef {object} UploadingFileProps
 * @property {File} file
 * @property {(file: File) => void} onCancelFile
 */
