/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useEffect, useState } from 'react'
import { AccordionExpandCollapse } from "cms/components/accordion/expand_collapse";
import { AccordionSection } from 'cms/components/accordion/accordion_section'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { cloneArray } from 'cms/hooks/helpers/hooks-helpers'

const AccordionComponent = (props) => {

    const { component } = props
    const { guid, showSteps = false } = component

    const [sections, setSections] = useState(component.sections || [])
    const [allOpen, setAllOpen] = useState(false)
    const [allClosed, setAllClosed] = useState(true)

    useEffect(() => {
        if (isArrayNotEmpty(sections)) {
            setAllOpen(sections.every((section) => section.isVisible))
            setAllClosed(sections.every((section) => !section.isVisible))
        }
    }, [sections])

    const selectSection = function (tab_id, e) {
        e.preventDefault()
        const s = cloneArray(sections)
        s.forEach((section) => {
            if (section.tab_id === tab_id) {
                section.isVisible = !section.isVisible
            }
        })
        setSections(s)
    }
    const expandAllSections = function (e) {
        e.preventDefault()
        const s = cloneArray(sections)
        s.forEach((section) => {
            section.isVisible = true
        })
        setSections(s)
    }
    const collapseAllSections = function (e) {
        e.preventDefault()
        const s = cloneArray(sections)
        s.forEach((section) => {
            section.isVisible = false
        })
        setSections(s)
    }

    const expandProps = {
        allClosed,
        allOpen,
        expandAllSections,
        collapseAllSections,
    }

    return (
        <div className={"simplicity-accordion-container"}>


            <h2>{component && component.title}</h2>

            <p>{component && component.description}</p>

            <AccordionExpandCollapse {...expandProps} />
            <div
                className={"simplicity-accordion-root"}
                data-group-type="accordion"
                role="tablist"
                aria-multiselectable="true"
            >

                {showSteps && (
                    <div className='line' >
                        <span />
                    </div>
                )}

                {sections &&
                    sections.map((section, index) => (
                        <AccordionSection
                            section={section}
                            group_id={guid}
                            selectSection={selectSection}
                            key={section.guid}
                            tabIndex={index}
                            stepNumber={index + 1}
                            showSteps={showSteps}
                        />
                    ))}
            </div>
        </div>
    )
}
AccordionComponent.displayName = 'AccordionComponent'
export { AccordionComponent }
