/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from 'react'

/**
 * Recursively finds all opened menus on menu initialization
 * @param {string} currentPageGuid
 * @param {import('cms/context/simplicity-app.context').MenuItem[]} items
 */
const useInitialOpenMenus = (currentPageGuid, items) => {
    const initialOpenMenus = useMemo(() => {
        const stack = [...items]

        /**
         * Tracks the ancestry to our given guid.
         * @type {Ancestry[]}
         */
        let ancestry = []

        while (stack.length) {
            const item = stack.shift()

            // stop processing if there's nothing left in the stack, or we have a match
            if (!item || item.guid === currentPageGuid) {
                break
            }

            // if there are children, recurse
            if (item.children.length) {
                /**
                 * Record the current node as an ancestor and mark the length
                 * of the stack.  The stack length is unique here because we're
                 * adding more than 1 to its length below.
                 */
                ancestry.push({
                    guid: item.guid,
                    stackedAt: stack.length
                })

                // place the children at the beginning of the stack
                stack.unshift(...item.children)
            }

            // check if the current stack length is where we stacked before
            const index = ancestry.findIndex((ancestor) => ancestor.stackedAt === stack.length);

            // if it matches the stacked position, remove all ancestry from
            // that index and downwards
            if (index >= 0) {
                ancestry.splice(index);
            }
        }

        const opened = ancestry.map((ancestor) => ancestor.guid)

        // append the current page guid as it might be a section instead of a leaf node
        opened.push(currentPageGuid)

        return opened
    }, [currentPageGuid, items])

    return initialOpenMenus
}

export { useInitialOpenMenus }


/**
 * @typedef {object} Ancestry
 * @property {string} guid Unique identifier of the item we're recording as an ancestor
 * @property {number} stackedAt At what point (determined by stack length) we stacked/recursed
 */
