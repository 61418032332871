/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { FullSearch } from "components/search";

const Search = () => {
    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Search</h1>

            <FullSearch />
        </main>
    )
}

export { Search }
