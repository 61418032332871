/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";

/**
 * useScrollToLocation
 * A utility hook that helps scroll the browser to an anchor found in the page
 * when the hash for that anchor is a URL hash param
 */
const useScrollToLocation = () => {
    const scrolledRef = useRef(false);
    // get location object, and access hash property
    const { hash } = useLocation();
    useEffect(() => {
        // wait for the page to load and the anchor to exists, before scrolling to that anchor
        setTimeout(() => {
            if (hash && !scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView( { behavior: 'smooth' });
                    scrolledRef.current = true;
                }
            }
        }, 350)
    });
};

export { useScrollToLocation }
