/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
// import { Logger } from 'cms/utils/logger'

const LiveGuard = (props) => {

    const { children } = props
    const isStage = CmsConfig.isStage
    const isPreview = CmsConfig.isPreview
    const isLive = !isPreview && !isStage

    if (!isLive) {
        return null
    }
    return (
        <>{children}</>
    )
}

export { LiveGuard }
