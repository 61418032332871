/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

const useFetchTableOfContents = () => {

    const { token } = useAuth()
    const { model } = useSimpliCity()

    const [loading,setLoading] = useState(false)
    const [chapters,setChapters] = useState([])

    const fetchChapters = async (token) => {
        setLoading(true)
        const { guid } = model || {}
        const toc = await SimpliCitySDK.model.toc(token, guid)
        const { chapters } = toc
        setChapters(chapters)
        setLoading(false)
    }
    useEffect(() => {
        fetchChapters(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return {
        loading,
        chapters
    }

}

export { useFetchTableOfContents }
