/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from "react";
// import { CTAHorizontal } from './horizontal'
// import { CTAVertical } from './vertical'

// ------------- DNV override components ------------------
// import { HighlightHorizontal } from 'components/highlight/horizontal'
import { HighlightHorizontal } from "components/highlight/horizontal-inside";
import { HighlightVertical } from "components/highlight/vertical";

const CallToActionComponent = (props) => {
    const { component } = props || {};
    const { image = "", title = "", description = "", imageCard, links = [], settings } = component || {};
    const { style, format } = settings || {};
    // const hasContent = isValidGuid(image)
    const isVertical = imageCard === "vertical";
    const isHorizontal = imageCard === "horizontal";

    return (
        <>
            {isVertical && (
                <HighlightVertical
                    image={image}
                    title={title}
                    description={description}
                    links={links}
                    style={style}
                    format={format}
                    settings={settings}
                />
            )}

            {isHorizontal && (
                <HighlightHorizontal
                    image={image}
                    title={title}
                    description={description}
                    links={links}
                    style={style}
                    format={format}
                    settings={settings}
                />
            )}
        </>
    );
};

CallToActionComponent.displayname = "CallToActionComponent";
export { CallToActionComponent };
