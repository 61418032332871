/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { ErrorMessages } from 'cms/forms/error-messages'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { useMemo } from 'react'
import { ErrorCode } from 'react-dropzone'
import { useRequiredValidator } from './use-required-validator'

/**
 * @param {FormQuestionFileUpload} question
 * @param {import('react-dropzone').FileRejection[]} fileRejections
 * @returns {FileUploadErrorInfo}
 */
const useFileUploadErrors = (question, fileRejections) => {

    const {
        guid,
        allowOnlyFileTypes,
        fileUnit,
        isRequired,
        maxFileSize,
        maxFiles,
        value,
    } = question

    const ErrorMap = useMemo(
        () => {
            const newFileUnit = fileUnit === 'mb' ? 'MB' : 'GB'

            const errorMap = {
                [ErrorCode.FileInvalidType]: ErrorMessages.files.invalidType(allowOnlyFileTypes.join(', ')),
                [ErrorCode.TooManyFiles]: ErrorMessages.files.tooMany(maxFiles),
                [ErrorCode.FileTooLarge]: ErrorMessages.files.tooLarge(maxFileSize, newFileUnit),
            }

            return errorMap
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const currentError = useRequiredValidator(
        isRequired,
        value
    )

    const immediateErrors = useMemo(() => {
        /**
         * @type {Set<string>}
         */
        const errorSet = new Set()

        for (const rejection of fileRejections) {
            for (const formError of rejection.errors) {
                const { code, message } = formError

                const errorMessage = ErrorMap[code] || message
                errorSet.add(errorMessage)
            }
        }

        const errors = Array.from(errorSet.values())
        return errors
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileRejections])

    useSetQuestionErrors(guid, currentError)
    const errorInfo = useShowError(currentError, question)

    if (isArrayNotEmpty(immediateErrors)) {
        return {
            error: immediateErrors,
            hasError: true,
        }
    }

    return errorInfo
}

export { useFileUploadErrors }


/**
 * @typedef {object} FileUploadErrorInfo
 * @property {string | string[] | null} error
 * @property {boolean} hasError
 */
