/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useEffect, useState } from 'react'
import { fetchNavigation } from './helpers/fetch-navigation'
import { Logger } from 'cms/utils/logger'

/**
 * @param {string} home
 * @param {import('react').Dispatch<import('react').SetStateAction<any>>} setError
 */
export function useFetchNavigation (home, setError) {

    const { token } = useAuth()

    const [menu, setMenu] = useState([])
    const [levelOneData, setLevelOneData] = useState([])
    const [nav, setNav] = useState(null)

    const side = 'right'

    useEffect(() => {
        fetchNavigation(token, home, side)
            .then((result) => {
                const { data, menuItems } = result

                setNav(data)
                setMenu(menuItems)

                if (data) {
                    setLevelOneData(data?.children)
                }
            })
            .catch((error) => {
                Logger.error(error)
                setError({ error })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, home, side])

    return {
        menu,
        levelOneData,
        nav,
        side,
    }
}
