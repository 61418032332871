/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import SimpleList from './simple/simple-list'
import BulletPointList from './bullet/bullet-point'
import AccordionList from './accordion/accordion'
import IconList from './icon/icon'
import {
    // DOCUMENT_FORMATS,
    DOCUMENT_LIST_TYPES,
    // DOCUMENT_STYLES,
    // DOCUMENT_TARGETS
} from "cms/components/document-list/helpers"

const DocumentListComponent = (props) => {

    const { component } = props || {}
    const { documents = [], listStyle } = component || {}
    // const {
    //     style = DOCUMENT_STYLES.BODY,
    //     format = DOCUMENT_FORMATS.TITLE_SIZE,
    //     target = DOCUMENT_TARGETS.NEW,
    // } = settings || {}

    const hasContent = documents?.length > 0
    const isAccordion = listStyle === DOCUMENT_LIST_TYPES.ACCORDION
    const isSimpleList = listStyle === DOCUMENT_LIST_TYPES.SIMPLE
    const isBulletList = listStyle === DOCUMENT_LIST_TYPES.BULLET
    const isIconList = listStyle === DOCUMENT_LIST_TYPES.ICON

    return (
        <div className="document-list-container">
            {hasContent && (
                <Fragment>

                    {/*<div style={{ backgroundColor: "#dedede", marginTop: "20px", marginBottom: "20px" }}>*/}
                    {/*    List: [{listStyle}] <br />*/}
                    {/*    Style: [{style}] <br />*/}
                    {/*    Format: [{format}] <br />*/}
                    {/*     Target: [{target}] <br />*/}
                    {/*    {JSON.stringify(settings)}*/}
                    {/*</div>*/}

                    {isSimpleList && (
                        <>
                            <SimpleList component={component} />
                        </>
                    )}
                    {isIconList && (
                        <>
                            <IconList component={component} />
                        </>
                    )}
                    {isBulletList && (
                        <>
                            <BulletPointList component={component} />
                        </>
                    )}
                    {isAccordion && (
                        <>
                            <AccordionList component={component} />
                        </>
                    )}
                </Fragment>
            )}
        </div>
    );
}

DocumentListComponent.displayName = 'DocumentListComponent'
export { DocumentListComponent }
