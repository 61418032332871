/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * Resolves the rules for a given set of selected options
 * @param {string | string[]} selectedOptions
 * @param {QuestionOption[]} options
 */
const getRules = (selectedOptions, options) => {
    const optionIds = Array.isArray(selectedOptions) ?
        selectedOptions :
        [selectedOptions]

    const rules = options
        .filter((option) => {
            // handle regular options (id) and yes/no (value)
            const id = option.id ?? option.value
            return optionIds.includes(id)
        })
        .flatMap((option) => option.rules ?? [])

    return rules
}

export { getRules }
