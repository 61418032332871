/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// https://www.iana.org/assignments/media-types/media-types.xhtml
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file

export const FILE_TYPES_BY_EXTENSION = {
    '.jpg': { 'images/jpeg': ['.jpg'] },
    '.jpeg': { 'images/jpeg': ['.jpeg'] },
    '.png': { 'image/png': ['.png'] },
    '.doc': { 'application/msword': [".doc"] },
    '.docx': { "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"] },
    '.html': { 'text/html': ['htm','html'] },
    '.htm': { 'text/html': ['htm','html'] },
    '.pdf': { 'application/pdf': [".pdf"] },
    '.ppt': { 'application/vnd.ms-powerpoint': [".ppt"] },
    '.pptx': { "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"] },
    '.rar': { 'application/vnd.rar': ['.rar'] },
    '.txt': { 'text/plain': ['.txt'] },
    '.xls': { 'application/vnd.ms-excel': [".xls"] },
    '.xlsx': { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [".xlsx"] },
    '.zip': { 'application/zip': ['.zip'] },
    '.7z': { 'application/x-7z-compressed': ['.7z'] },
}

// export const DEFAULT_FILE_TYPES = {
//     "image/*": [],
//     "application/pdf": [],
//     "text/html": [],
//     "application/zip": [],
//     "application/x-tar": [],
//     "message/rfc822": [],
//     "application/xml": [],
//     "text/plain": [],
//     "text/xml": [],
//     "text/rtf": [".rtf"],
//     "application/rtf": [".rtf"],
//     "application/msword": [".rtf", ".dot"],
//     "application/x-7z-compressed": [],
//     "application/vnd.ms-word": [],
//     "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
//     "application/vnd.openxmlformats-officedocument.wordprocessingml.template": [".dotx"],
//     "application/vnd.ms-word.document.macroEnabled.12": [".docm"],
//     "application/vnd.ms-word.template.macroEnabled.12": [".dotm"],
//     "application/vnd.ms-excel": [".xlt", ".xla"],
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsm"],
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [".xltx"],
//     "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
//     "application/vnd.ms-excel.addin.macroEnabled.12": [".xlam"],
//     "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
//     "application/vnd.ms-powerpoint": [".pot", ".pps", ".ppa"],
//     "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
//     "application/vnd.openxmlformats-officedocument.presentationml.template": [".potx"],
//     "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [".ppsx"],
//     "application/vnd.ms-powerpoint.addin.macraEnabled.12": [".ppam"],
//     "application/vnd.ms-powerpoint.presentation.macroEnabled.12": [".pptm", ".potm"],
//     "application/vnd.ms-powerpoint.slideshow.macroEnabled.12": [".ppsm"],
//     "application/vnd.ms-outlook": [".msg"],
// };
