/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {object} props
 * @param {string | string[]} props.error
 */
export function ErrorList (props) {
    const { error } = props

    if (!Array.isArray(error)) {
        return error
    }

    if (error.length === 1) {
        return error[0]
    }

    return (
        <ul>
            {error.map(
                (text, index) => (
                    <li key={index}>
                        {text}
                    </li>
                )
            )}
        </ul>
    )

}
