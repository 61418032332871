/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

// named "selected" solid icons in the kit
import {
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowLeftFromLine,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faBars,
    faBasketball,
    faBasketballHoop,
    faBookmark,
    faBriefcase,
    faBuildingColumns,
    faBuildingFlag,
    faBus,
    faBusSimple,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleDot,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClock,
    faCloudArrowUp,
    faComment,
    faCommentDots,
    faComments,
    faCopy,
    faDiamondExclamation,
    faDownload,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faGear,
    faHand,
    faHeart,
    faHouseChimneyWindow,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faMoneyBills,
    faPeopleLine,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRightFromBracket,
    faRoadBarrier,
    faScaleBalanced,
    faShareNodes,
    faShield,
    faShieldCheck,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faStar,
    faSuitcase,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
} from "@awesome.me/kit-5f107d6f8b/icons/classic/solid";

const solid = {
    faArrowDown,
    faArrowDownToLine,
    faArrowLeft,
    faArrowLeftFromLine,
    faArrowRight,
    faArrowUp,
    faArrowUpRightFromSquare,
    faBars,
    faBasketball,
    faBasketballHoop,
    faBookmark,
    faBriefcase,
    faBuildingColumns,
    faBuildingFlag,
    faBus,
    faBusSimple,
    faCaretUp,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronsDown,
    faChevronsLeft,
    faChevronsRight,
    faChevronsUp,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleChevronDown,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleChevronUp,
    faCircleDot,
    faCircleExclamation,
    faCircleInfo,
    faCircleMinus,
    faCirclePause,
    faCirclePlay,
    faCirclePlus,
    faCircleXmark,
    faClipboardCheck,
    faClock,
    faCloudArrowUp,
    faComment,
    faCommentDots,
    faComments,
    faCopy,
    faDiamondExclamation,
    faDownload,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopeOpenDollar,
    faEye,
    faFilePdf,
    faGavel,
    faGear,
    faHand,
    faHeart,
    faHouseChimneyWindow,
    faHouseUser,
    faInfo,
    faLeaf,
    faLink,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassArrowRight,
    faMessage,
    faMessageCheck,
    faMessageExclamation,
    faMessageMiddle,
    faMessageMiddleTop,
    faMessages,
    faMinus,
    faMoneyBills,
    faPeopleLine,
    faPlus,
    faQuoteLeft,
    faQuoteRight,
    faRecycle,
    faRightFromBracket,
    faRoadBarrier,
    faScaleBalanced,
    faShareNodes,
    faShield,
    faShieldCheck,
    faSortDown,
    faSortUp,
    faSpinner,
    faSquare,
    faSquareCheck,
    faSquareChevronDown,
    faSquareChevronLeft,
    faSquareChevronRight,
    faSquareChevronUp,
    faSquareExclamation,
    faSquareInfo,
    faStar,
    faSuitcase,
    faTrash,
    faTrashCan,
    faTrashCanSlash,
    faTrashSlash,
    faTree,
    faTriangleExclamation,
    faXmark,
};

export { solid };
