/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useForm } from '../context'
import { getRules } from '../utils/get-rules'

/**
 * @param {GridQuestionOption[]} rows
 * @param {FormQuestionGrid} question
 * @param {(rowId: string) => void} onRemoveRow
 */
export function useGridVisibleRows (question, onRemoveRow) {
    const { guid, options, value } = question
    const { updateRules } = useForm()

    const [visibleRows, setVisibleRows] = useState(() => {
        /** @type {Record<string, boolean>} */
        const defaultVisibleRows = {}

        const initialState = options.reduce((visibilityState, row) => {
            visibilityState[row.id] = !!value[row.id]
            return visibilityState
        }, defaultVisibleRows)

        return initialState
    })

    useUpdateEffect(() => {
        const rowIds = Object.entries(visibleRows)
            .filter((entry) => {
                const [, isVisible] = entry
                return isVisible
            })
            .map((entry) => {
                const [rowId] = entry
                return rowId
            })

        const rules = getRules(rowIds, options)
        updateRules(guid, rules)
    }, [visibleRows])

    const toggleRowVisibility = (rowId) => {
        setVisibleRows((prevVisibleRows) => ({
            ...prevVisibleRows,
            [rowId]: !prevVisibleRows[rowId]
        }))

        // note: The above set state call does not impact the following reference
        // as the update has been queued, but not yet run. We are still within the context
        // (closure) of an older render phase.
        const prevRowVisibility = visibleRows[rowId]

        // unset the row if we're going from `true`=> `false`
        if (prevRowVisibility) {
            // unset row values
            onRemoveRow(rowId)
        }
    }

    return {
        visibleRows,
        toggleRowVisibility
    }
}
