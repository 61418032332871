/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

const MobileBackDrop = (props) => {

    const { toggleMobile } = props;

    return (
        <div className={"mobile-backdrop"} onClick={toggleMobile} />
    )
}

export { MobileBackDrop }
