/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import cn from 'clsx'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { useState } from 'react'
import { MenuItems } from './menu-items'
import { useInitialOpenMenus } from './use-initial-open-menus'

/**
 * @param {object} props
 * @param {boolean} props.show
 */
const MobileMenu = (props) => {

    const { show } = props
    const { levelOneData, webpage } = useSimpliCity()
    const { guid } = webpage || {}

    const initialOpenMenus = useInitialOpenMenus(guid, levelOneData)
    const [openMenus, setOpenMenus] = useState(initialOpenMenus)

    return (
        <div className={cn('mobile-menu', { open: show })}>
            <div className='mobile-menu-content'>
                <MenuItems
                    items={levelOneData}
                    openMenus={openMenus}
                    setOpenMenus={setOpenMenus}
                />
            </div>
            {/*<div>*/}
            {/*  <pre>*/}
            {/*    <code>{JSON.stringify(openMenus, null, 2)}</code>*/}
            {/*  </pre>*/}
            {/*</div>*/}
        </div>
    )
}

MobileMenu.displayName = 'MobileMenu'
export { MobileMenu }
