/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
import { useLocation } from 'react-router-dom'
import { useSimpliCity } from './use-simplicity'
// import { Logger } from 'cms/utils/logger'

/**
 * useSimpliCityComposer
 * Requires env vars
 *     PREVIEW_URL: process.env.REACT_APP_SIMPLICITY_SDK_PREVIEW_URL,
 *     CONSOLE_URL: process.env.REACT_APP_SIMPLICITY_SDK_CONSOLE_URL,
 *     WEBSITE_URL: process.env.REACT_APP_SIMPLICITY_SDK_WEBSITE_URL,
 */
const  useSimpliCityComposer = () => {

    const { pathname: path } = useLocation()
    const { webpage } = useSimpliCity()
    const defaultEditor = `${CmsConfig.CONSOLE_URL}/composer`
    const defaultPreview = `${CmsConfig.PREVIEW_URL}${path}`
    const defaultLive = `${CmsConfig.WEBSITE_URL}${path}`

    const excludes = [
        // '/',
        '/search',
        '/events',
        '/news',
        '/advisories',
        '/alerts',
        '/licences',
        '/events/search',
        '/dnv-stories-old',
    ]
    const isExcludedPath = excludes.indexOf(path) > -1
    const isWebpage = (!isExcludedPath && webpage)
    const editor = isWebpage ? `${CmsConfig.CONSOLE_URL}/composer/${webpage.guid}`: defaultEditor
    const preview = isWebpage ? `${CmsConfig.PREVIEW_URL}${path}` : defaultPreview
    const live = isWebpage ? `${CmsConfig.WEBSITE_URL}${path}` : defaultLive

    return { editor, preview, live }
}

export { useSimpliCityComposer }
