/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { getComponent } from 'cms/components/get-component'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
// import { CmsConfig } from 'cms/config'

const ContentZoneDisplay = (props) => {

    const { webpage, value, field } = props || {}

    // NOTE: value in a field of component type, is an array of components
    // which also contains `settings`, but we are not using 'field settings' at this time

    return (
        <Fragment>
            {isArrayNotEmpty(value) &&
                value.map((component, index) => {

                    const Component = getComponent(component, field, webpage)
                    const { guid, type } = component
                    const shortType = type.replace('component.','')
                    const componentId = `field-component-${guid}`
                    return (
                        <div
                            className={`component ${shortType}`}
                            id={componentId}
                            key={`${componentId}${index}`}
                        >
                            {/*{CmsConfig.useDebug &&*/}
                            {/*    <>*/}
                            {/*        <h4>{component.type}</h4>*/}
                            {/*        <details>*/}
                            {/*            <summary><b>Component JSON</b></summary>*/}
                            {/*            <pre style={{overflow: 'scroll', fontSize: '10px', overflowY: 'auto', border: '1px solid #343434', height: '250px', maxWidth: '680px'}}>*/}
                            {/*                {JSON.stringify(component,null,4)}*/}
                            {/*            </pre>*/}
                            {/*        </details>*/}
                            {/*    </>*/}
                            {/*}*/}
                            {Component}
                        </div>
                    )
                })
            }
        </Fragment>
    )
}

export { ContentZoneDisplay }
