/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Fragment, useEffect, useState } from 'react'
import { isStringNotEmpty, isTrue } from 'cms/utils/empty-exists'

const TabContent = (props) => {

    const { tab, children, selected = 0 } = props;
    const { id, text } = tab || {}
    const [visible, setVisible] = useState(selected === id)

    useEffect(() => {
        setVisible(selected === id)
    }, [selected,id])

    return (
        <Fragment>
            {isTrue(visible) && isStringNotEmpty(text) && (
                <div
                    className="ds-1col entity entity-field-collection-item field-collection-item-field-tabs view-mode-full item clearfix"
                    data-tab-index={id}
                    role="tabpanel"
                    id={id}
                    aria-hidden="false"
                >

                    {children}

                </div>
            )}
        </Fragment>
    );
}

TabContent.displayName = 'TabContent'
export { TabContent }
