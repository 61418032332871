/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'

export const useExternalScript = (url, attributes) => {
    let [state, setState] = useState(url ? 'loading' : 'idle')

    useEffect(() => {
        if (!url) {
            setState("idle");
            return;
        }

        let script = document.querySelector(`script[src="${url}"]`)

        const handleScript = (event) => {
            setState(event.type === 'load' ? 'ready' : 'error')
        }

        if (!script) {
            script = document.createElement('script')
            script.type = 'application/javascript'
            if (attributes) {
                for (const [key, value] of Object.entries(attributes)) {
                    script.setAttribute(key, value)
                }
            }
            script.src = url
            script.async = true
            document.body.appendChild(script)
            script.addEventListener('load', handleScript)
            script.addEventListener('error', handleScript)

        }

        script.addEventListener('load', handleScript)
        script.addEventListener('error', handleScript)

        return () => {
            script.removeEventListener('load', handleScript)
            script.removeEventListener('error', handleScript)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return state
}
