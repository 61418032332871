/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useFormSnapshot } from './use-form-snapshot'
import { useFormState } from './use-form-state'
import { Logger } from 'cms/utils/logger'

const useSubmitForm = () => {
    const FormState = useFormState()
    const { token } = useAuth()
    const getSnapshots = useFormSnapshot()

    /**
     * Submit form
     * @param {string=} recaptchaToken
     */
    const submit = async (recaptchaToken) => {
        const { schemaForm } = FormState

        const { snapshots, groups, labels } = getSnapshots()

        const payload = {
            form_guid: schemaForm.form_id,
            fields: snapshots,
            refs: {
                groups,
                labels
            }
        }

        const response = await SimpliCitySDK.formResponse.create(token, payload, {
            headers: {
                'x-recaptcha-token': recaptchaToken
            }
        })

        Logger.info(`[save-form-response] Recorded form response: ${response.guid}`)
    }

    return submit
}

export { useSubmitForm }
