/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

export const defaultTOCRichTextObjectPM = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
            "attrs": { "textAlign": "left", "class": null },
            "content": [
                {
                    "type": "text",
                    "marks": [ { "type": "bold" } ],
                    "text": "On this page:"
                }
            ]
        }
    ]
}
