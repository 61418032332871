/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isGroupQuestion } from 'cms/forms/utils/is-group-question'
import { isArray } from 'cms/utils/empty-exists'
import { applySchemaTransformer } from './apply-schema-transformer'
import { shouldExcludeQuestion } from './should-exclude-question'

/**
 * Initializes form question properties and flattens the given question array.
 * @param {FormQuestion[]} questions
 * @param {GroupInfo=} groupInfo - Group information that apply to the given questions
 */
const prepareFormQuestions = (
    questions,
    groupInfo,
) => {

    /**
     * @type {FormQuestion[]}
     */
    const preparedQuestions = []

    for (const baseQuestion of questions) {
        const isExcluded = shouldExcludeQuestion(
            baseQuestion.type,
            baseQuestion.prompt
        )

        if (isExcluded) {
            continue
        }

        /**
         * @type {FormQuestion}
         */
        const question = {
            ...baseQuestion
        }

        question.label = baseQuestion.prompt
        question.isRequired = Boolean(baseQuestion.required)
        question.isValid = !baseQuestion.required
        question.isTouched = false
        question.errors = []
        question.labels = {}

        // set the group id and step if group info was supplied
        // this allows grouped questions to be accurately displayed
        // in steps
        if (groupInfo?.guid) {
            const { guid, step } = groupInfo
            question.group = guid
            question.step = step
        }

        if (question.value === undefined) {
            question.value = ""
        }

        applySchemaTransformer(question)

        if (isGroupQuestion(question)) {

            const { questions, guid, step } = question

            // only process question groups if they have questions under them
            if (isArray(questions)) {
                const groupInfo = { guid, step }
                const groupedQuestions = prepareFormQuestions(
                    questions,
                    groupInfo
                )

                // order matters, group must come before the grouped questions
                preparedQuestions.push(question)
                preparedQuestions.push(...groupedQuestions)
            }
        } else {
            preparedQuestions.push(question)
        }
    }

    return preparedQuestions
}

export { prepareFormQuestions }


/**
 * @typedef {object} GroupInfo
 * @property {string} guid
 * @property {number} step
 */
