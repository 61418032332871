/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import dayjs from 'dayjs'

/**
 * @param {OptionalQuestion<FormQuestionDateTime>} question
 */
const dateTimeValueTransformer = (question) => {
    const { dateTimeType, value: selectedDate } = question

    const dateTimeString = dayjs(selectedDate).isValid() ?
        selectedDate.toISOString() :
        selectedDate

    return {
        dateTimeType,
        dateTimeString
    }
}

export { dateTimeValueTransformer }
