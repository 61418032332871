/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SimpliCitySDK } from 'cms/sdk'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { prepareFormQuestions } from '../context/reducer/prepare-form-questions'
import { Logger } from 'cms/utils/logger'

/**
 *
 * @param {string} token
 * @param {string} guid
 */
const getForm = async (token, guid) => {
    try {
        /**
         * @type {GetFormResponse | undefined}
         */
        const response = await SimpliCitySDK.form.findOne(token, guid)

        // transform response
        if (response?.guid) {
            // remove invalid/unusable data
            if (isArrayNotEmpty(response.questions)) {
                response.questions = response.questions.filter((item) => item.type !== undefined)
            }

            // flatten and prepare questions
            response.questions = prepareFormQuestions(response.questions)
            return response
        }
    } catch (error) {
        Logger.error(error)
    }

}


export { getForm }


/**
 * @typedef {object} GetFormResponse
 * @property {string} guid
 * @property {string} last_response
 * @property {string} name
 * @property {FormQuestion[]} questions
 * @property {number} response_count
 * @property {number} reuse_count
 * @property {string} status
 * @property {Step[]} steps
 * @property {string} type
 * @property {string} version
 *
 */
