/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Logger } from 'cms/utils/logger'

const useQuickSearch = () => {

    const backDropId = 'search-overlay'
    const bodyScrollLockClass = 'no-scroll'

    const isAtLeastTablet = useMediaQuery("(min-width:480px)")

    const [showResults, setShowResults] = useState(isAtLeastTablet)
    const [showSearchInput, setShowSearchInput] = useState(false)

    const loc = useLocation()

    /** @type {import('react').MutableRefObject<HTMLInputElement>} */
    const inputRef = useRef()

    const closeOnAnchorClick = (event) => {
        /** @type {string} */
        const tagName = event.target.tagName

        if (tagName.toLowerCase() === "a") {
            setShowSearchInput(false)

            // we need to explicitly close the results in desktop
            setShowResults(false)
        }
    }

    const closeOnOverlayFocus = (event) => {
        const targetId = event.target.id
        if (targetId === backDropId) {
            setShowResults(false)
            setShowSearchInput(false)
        }
    }

    const onInputFocus = () => {
        const isInputFocused = document.activeElement === inputRef.current
        const isDesktopFocused = isAtLeastTablet && isInputFocused

        if (isDesktopFocused || showSearchInput) {
            setShowResults(true)
        }
    }

    const toggleSearchInput = () => {
        setShowSearchInput((show) => !show)
    }

    useEffect (() => {
        if (showResults) {
            document.body.classList.add(bodyScrollLockClass)
        } else {
            document.body.classList.remove(bodyScrollLockClass)
        }
    }, [showResults])

    useEffect (() => {
        // when we explicitly close the input in mobile
        if (!showSearchInput) {
            setShowResults(false)
        } else {
            Logger.debug(`Focusing quick search`)
            inputRef.current.focus()
        }
    }, [showSearchInput])

    useEffect (() => {
        Logger.debug(`Changed location to ${loc.pathname}, closing search input`)
        Logger.flush()
        setShowSearchInput(false)
    }, [loc.pathname])

    return {
        backDropId,
        closeOnAnchorClick,
        closeOnOverlayFocus,
        inputRef,
        onInputFocus,
        showResults,
        showSearchInput,
        toggleSearchInput,
    }
}

export { useQuickSearch }
