/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom"

/**
 * Retrieves the SimpliCity path
 */
export const useSimpliCityPath = () => {

    // it's also possible to use useLocation here, but using params will
    // allow us to add a prefix in the future easily
    const params = useParams()
    const location = useLocation()
    let splat
    if (params.path) {
        splat = params['*']
    } else {
        splat = location.pathname
        if (splat.startsWith('/')) {
            splat = splat.slice(1)
        }
    }
    const pathInfo = useMemo(() => {
        /** @type {PathInfo} */
        const pathInfo = {
            path: null,
            slug: null
        }
        if (!splat) {
            return pathInfo
        }
        pathInfo.path = '/' + splat
        const pathParts = splat.split('/')
        pathInfo.slug = pathParts[pathParts.length - 1]
        return pathInfo
    }, [splat])

    return pathInfo
}

/** @typedef {string | null} NullableString */

/**
 * @typedef {object} PathInfo
 * @property {NullableString} path
 * @property {NullableString} slug
 */
