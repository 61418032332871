/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

export const getFeedbackQuestion = (name, value) => {
    const question = question_fields.find((item) => {
        return item.name === name
    })
    question.guid = crypto.randomUUID()
    question.value = value
    return question
}
export const question_fields = [
    {
        "name": "feedback_approve",
        "type": "question.yesNo",
        "prompt": "Is this Helpful?",
        "valueLabel": "Yes",
    },
    {
        "name": "feedback_other_feedback",
        "type": "question.longAnswer",
        "prompt": "Other Feedback",
    },
    {
        "name": "feedback_title",
        "type": "question.shortAnswer",
        "prompt": "Model Title",
    },
    {
        "name": "feedback_url",
        "type": "question.websiteUrl",
        "prompt": "Webpage Canonical URL",
    },
    {
        "name": "feedback_path",
        "type": "question.shortAnswer",
        "prompt": "Webpage Path",
    },
    {
        "name": "feedback_webpage",
        "type": "question.shortAnswer",
        "prompt": "Webpage GUID",
    },
    {
        "name": "feedback_model",
        "type": "question.shortAnswer",
        "prompt": "Model GUID",
    }
]
