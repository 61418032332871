/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * Creates a question config
 * @param {QuestionComponent} component
 * @param {QuestionValueTransformer=} valueTransformer
 * @param {QuestionSchemaTransformer=} schemaTransformer
 * @returns {QuestionConfig}
 */
const questionConfig = (component, valueTransformer, schemaTransformer) => {
    return {
        component,
        valueTransformer,
        schemaTransformer
    }
}

export { questionConfig }
