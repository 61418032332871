/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { DOCUMENT_FORMATS, formatBytes } from 'cms/components/document-list/helpers'

const SimpleListItem = (props) => {

    const {
        doc,
        document,
        format,
        target,
    } = props || {}
    const style = 'body'  // DOCUMENT_STYLES.BODY // only option
    const { title } = doc
    const { file_size, url } = document
    // const isTitle = format === DOCUMENT_FORMATS.TITLE
    const isTitleSize = format === DOCUMENT_FORMATS.TITLE_SIZE

    return (
        <>
            <div className={`list-item ${style}`}>
                <a
                    href={url}
                    className={`display-as ${style} `}
                    target={target}
                    rel='noreferrer'
                >
                    <div className='display-as-link'>
                        <span className='document-text'>{title}</span>
                    </div>
                </a>
                {isTitleSize &&
                    <span className='document-file-size'>({formatBytes(file_size)})</span>
                }
            </div>
        </>
    )
}

export default SimpleListItem
