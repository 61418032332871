
/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import {NavLink} from 'react-router-dom'

const ResultItemGeneric = (props) => {

    const { model } = props
    const {
        title,
        description,
        path
    } = model

    return (
        <div className="feed-result-row">
            <h3 className="title">
                <NavLink
                    aria-label={title}
                    to={path}>
                    {title}
                </NavLink>
                {/*<a href={path}>*/}
                {/*    {title}*/}
                {/*</a>*/}
            </h3>
            <div className="feed-result-description">
                <p>{description}</p>
            </div>
        </div>
    )
}

export { ResultItemGeneric }
