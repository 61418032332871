/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'

const FieldDisplayWrapper = (props) => {

    const { children, field } = props || {}
    const { settings } = field || {}
    const {
        hide = false,
        // style,
        // format,
        // options,
        // applyUnifiedSettings,
    } = settings || {}

    return (
        <Fragment>
            {!hide &&
                <Fragment>{children}</Fragment>
            }
        </Fragment>
    )
}
export { FieldDisplayWrapper }
