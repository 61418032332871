/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { optionTransformer } from './option.transformer'

/**
 * @param {FormQuestionMultipleChoice} question
 */
const multipleChoiceSchemaTransformer = (question) => {
    const {
        guid,
        hasOtherOptions,
        options: initialOptions,
    } = question

    const options = optionTransformer(
        guid,
        hasOtherOptions,
        initialOptions,
        1,
        (guid) => {
            return {
                value: guid,
                name: guid,
            }
        }
    )

    /**
     * @type {FormQuestionMultipleChoice}
     */
    const newQuestion = {
        ...question,
        options,
        value: '',
    }

    return newQuestion
}

export { multipleChoiceSchemaTransformer }
