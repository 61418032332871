/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React, { Fragment } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'
dayjs.extend(timezone)
import { DisplayTextOnly } from 'cms/fields/_helpers/display-text-only'
import { FieldDisplayWrapper } from 'cms/fields/_helpers/field-display-wrapper'
import { DATE_STORAGE_FORMAT, getDateFormat } from 'cms/fields/dates/date-helpers'
import { Logger } from "cms/utils/logger";

const Date = (props) => {

    const { field, format: _format } = props
    const overrideFormat = !!_format
    const { values } = field || {}

    return (
        <FieldDisplayWrapper field={field}>
            {values &&
                Array.isArray(values) &&
                values.map((item, index) => {
                    const { value, settings } = item || {}
                    const { date, tz } = value || {}
                    const { style, format, options } = settings || {}
                    const dateFormat = overrideFormat
                        ? _format
                        : getDateFormat(format)

                    let dateInZone
                    try {
                        dateInZone = dayjs.tz(date, DATE_STORAGE_FORMAT, tz)
                    } catch (err) {
                        Logger.error(err)
                        return null
                    }

                    const dateVal = date ? dateInZone.format(dateFormat) : ''

                    return (
                        <Fragment key={index}>
                            <DisplayTextOnly
                                value={dateVal}
                                style={style}
                                format={format}
                                options={options}
                            />
                        </Fragment>
                    )
                })}
        </FieldDisplayWrapper>
    )
}
export { Date }
