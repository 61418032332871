/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";

const CroppedFocusImage = (props) => {
    const { image, settings } = props || {};
    let displayWidth = "100%";
    let displayHeight = "340px";
    // const { alt } = image || {};
    // This placement forces the use of a cropped focus image. It uses position when set
    const { options } = settings || {};
    const { position = "center center" } = options || {};

    const image_style = image
        ? {
              width: displayWidth,
              height: displayHeight,
              backgroundPosition: `${position}`,
              backgroundImage: `url('${image.url}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              // aspectRatio: '16/9'
          }
        : {};

    let class_list = ["simplicity-cover-image-thumb", "cover"];

    return (
        <>
            {image && <>{image && image.url && <div className={class_list.join(" ")} style={image_style} />}</>}

            {!image && <>{/*<EmptyContentMessage hasContent={hasContent} component={component} />*/}</>}
        </>
    );
};

export { CroppedFocusImage };
