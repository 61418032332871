/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useEffect, useState } from "react";
import { isArrayNotEmpty, isRichTextNotEmpty, isStringNotEmpty } from "cms/utils/empty-exists";
import { RichText } from "cms/rich-text";
import { HighlightImage } from "./highlight-image";
import { NavLink } from "react-router-dom";
import { SimpliCitySDK } from 'cms/sdk';
import { useAuth } from "cms/auth/auth/hooks/useAuth";

/*
 *   This is one highlight card used in a component field. The component field may have multiple components.
 */
const HighlightHorizontal = (props) => {
    const { image, title, description, links, settings } = props;
    const {token} = useAuth()

    const [link, setLink] = useState();
    
    useEffect(() => {
            const loadLink = async (guid) => {
                if (isStringNotEmpty(guid)) {
                    const _link = await SimpliCitySDK.link.findOne(token, guid)
                    setLink(_link?.url)
                }
            }
            loadLink(links?.[0]?.link);
        }, [links, token])

    return (
        <>
            <div className={`simplicity-cta-container horizontal`}>
                <HighlightImage image={image} imageCard={"horizontal"} settings={settings} />

                <div className={`simplicity-cta-content horizontal`}>
                    {/*This needs to be added to outside of the component*/}
                    {/* {isStringNotEmpty(title) && <div className="cta-title">{title}</div>} */}

                    <div className="cta-title">
                        {isArrayNotEmpty(links) && <NavLink to={link}>{title}</NavLink>}
                    </div>

                    {isRichTextNotEmpty(description) && <RichText richtext={description} />}

                    {/* {isArrayNotEmpty(links) && (
                        <div className="cta-link-container">
                            {links.map((link, index) => {
                                return <HighlightLink key={index} link={link} format={format} style={style} />;
                            })}
                        </div>
                    )} */}
                </div>
            </div>
        </>
    );
};

HighlightHorizontal.displayname = "HighlightHorizontal";
export { HighlightHorizontal };
