/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isArrayCountEqual } from 'cms/utils/empty-exists'

/**
 * @param {LatLng=} coords
 * @returns {coords is LatLng}
 */
const isLatLng = (coords) => {
    return isArrayCountEqual(coords, 2)
}

export { isLatLng }
