/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Logger } from 'cms/utils/logger'

/**
 * Accepts a map of dependency pairs and creates a materialized path for a
 * given ancestor.
 *
 * A materialized path is a technique used to represent hierarchical data.  It
 * involves storing the path to each record in the hierarchy as a string of
 * identifiers, representing the sequence of ancestors up to that record.
 *
 * This particular implementation constructs paths from pairs representing
 * a hierarchical relationship.
 */
class MaterializedPath {
    /**
     * @type {DependencyPairs}
     */
    #deps

    /**
     * @param {DependencyPairs} deps
     */
    constructor (deps) {
        this.#deps = deps
    }

    /**
     * @param {string} key
     * @param {number} depth
     * @returns {StackEntry[]}
     */
    #getDeps (key, depth) {
        const children = this.#deps.get(key) ?? []
        const stack = children.map((child) => ({
            child,
            depth,
        }))

        return stack
    }

    /**
     * @param {string} entrypoint
     * @returns {string[][]}
     */
    getPath (entrypoint) {
        const stack = this.#getDeps(entrypoint, 0)
        const paths = [[entrypoint]]

        /**
         * Tracks entries that trigger recursion
         * @type {StackEntry[]}
         */
        let ancestry = [{ child: entrypoint, depth: -1 }]

        /**
         * Tracks the index of the path we're building
         * @type {number}
         */
        let index = 0
        let depth = 1

        // recursively process using a stack
        while (stack.length) {
            /**
             * Tracks the current path we're building
             * @type {string[]}
             */
            const currentPath = paths[index]

            // take the first entry off the beginning of the stack
            // it is guaranteed to exist
            const entry = stack.shift()

            // record the entry in the path at the given index
            currentPath.push(entry.child)

            // check if the current entry has any dependencies, itself
            let children = this.#getDeps(entry.child, depth)

            // prevent circular references from building
            children = children.filter((entry) => {
                const isCircular = currentPath.includes(entry.child)

                if (isCircular) {
                    const circularPath = currentPath.concat(entry.child)
                    Logger.info(`[materialized-path] Circular dependency detected at ${circularPath.join(' -> ')}; ignoring`)
                }

                return !isCircular
            })

            // if there are dependencies, recurse
            if (children.length) {
                // record the node that we're descending for
                ancestry.push(entry)

                // increment depth
                depth++

                // place the children at the beginning of the stack
                stack.unshift(...children)
            } else {
                // we're at a leaf node with no other dependencies
                const nextDepth = stack[0]?.depth

                // remove an ancestor if we've returned to the same depth
                ancestry = ancestry.filter((head) => head.depth !== nextDepth)

                // add a new path that starts with whatever ancestors we have
                const ancestors = ancestry.map((head) => head.child)

                // if there is more processing to be done, append ancestors
                if (stack.length) {
                    paths.push(ancestors)
                }

                // increment the index which will target building the next path
                index++
            }
        }

        return paths
    }
}

export { MaterializedPath }
