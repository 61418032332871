/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
const Config = {
    Recollect: {
        script: { src: "https://assets.ca.recollect.net/api/widget.js?scripts=head" },
        style: {
            href: "https://recollect.a.ssl.fastly.net/0.11.1654194167/api/areas/DistNorthVancouver/services/waste/style/widget/logan/Default.css",
        },
    },
    Userway: {
        account: "unAfj7cv4G",
    },
    SEO: {
        url: "https://www.dnv.org",
        website_title: "District of North Vancouver",
        description:
            "This is the official website for the District of North Vancouver, in British Columbia, Canada. It contains information on District programs and services, property and development, government business, and more.",
        website_image: "https://images.dnv.org/images/dnv-air-deepcove.jpg",
        keywords: "District North Vancouver",
    },
    google_site_verification: "+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=",
    recaptcha_site_key: "6LenVeEpAAAAAHBfaB6YaiuxZewd_XN-5LkeT_2a",
    dateFormat: {
        date: "MMMM DD, YYYY",
        date_new: "dddd, MMM D",
        date_short: "ddd, MMM D",
        event_date: "dddd, MMM D",
        date_fullday: "dddd MMMM D, YYYY",
        date_fullday_with_time: "dddd MMMM D, YYYY h:mma",
        time: "h:mma",
        datetime: "MMMM DD, YYYY h:mma",
        date_short_no_year: "MMM D",
        event_date_month: "MMM",
        event_date_date: "D",
        date_shortday: "ddd MMMM D, YYYY",
        date_fullday_short: "ddd MMMM D, YYYY",
        date_no_dow: "MMMM D, YYYY",
    },
};

export { Config };
