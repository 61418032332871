/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Blockquote } from '@tiptap/extension-blockquote'
import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Code } from '@tiptap/extension-code'
import { CodeBlock } from '@tiptap/extension-code-block'
import { ColumnExtension } from '@gocapsule/column-extension'
import { Document } from '@tiptap/extension-document'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import { Gapcursor } from '@tiptap/extension-gapcursor'
import { HardBreak } from '@tiptap/extension-hard-break'
import { History } from '@tiptap/extension-history'
import { HorizontalRule } from '@tiptap/extension-horizontal-rule'
import { Italic } from '@tiptap/extension-italic'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Strike } from '@tiptap/extension-strike'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { Text } from '@tiptap/extension-text'
import { TextAlign } from '@tiptap/extension-text-align'
import { Underline } from '@tiptap/extension-underline'
import { CustomParagraph } from './paragraph/custom-paragraph'
import { CustomHeading } from './table-of-contents/heading-toc'
import { CustomTable } from './table/custom-table'
import { SimpliCityDocLink } from './document/simplicity-doc-link'
// import { Link } from '@tiptap/extension-link'
import { SimpliCityLink } from './link/simplicity-link'
import { SimpliCityImage } from './simplicity-image/simplicity-image'

export const getExtension = (name, options) => {
    // configuration must be done during initialization
    // and can not be updated
    switch (name) {
        case 'Blockquote':
            return Blockquote.configure(options)
        case 'Bold':
            return Bold.configure(options)
        case 'BulletList':
            return BulletList.configure(options)
        case 'Code':
            return Code.configure(options)
        case 'CodeBlock':
            return CodeBlock.configure(options)
        case 'ColumnExtension':
            return ColumnExtension.configure(options)
        case 'Document':
            return Document.configure(options)
        case 'Dropcursor':
            return Dropcursor.configure(options)
        case 'Gapcursor':
            return Gapcursor.configure(options)
        case 'HardBreak':
            return HardBreak.configure(options)
        case 'History':
            return History.configure(options)
        case 'HorizontalRule':
            return HorizontalRule.configure(options)
        case 'Italic':
            return Italic.configure(options)
        case 'ListItem':
            return ListItem.configure(options)
        case 'OrderedList':
            return OrderedList.configure(options)
        case 'Strike':
            return Strike.configure(options)
        case 'Text':
            return Text.configure(options)
        case 'Underline':
            return Underline.configure(options)
        case 'TextAlign':
            return TextAlign.configure(options)
        case 'CustomTable':
            return CustomTable.configure(options)
        case 'TableRow':
            return TableRow.configure(options)
        case 'TableHeader':
            return TableHeader.configure(options)
        case 'TableCell':
            return TableCell.configure(options)
        case 'CustomParagraph':
            return CustomParagraph.configure(options)
        case 'CustomHeading':
            return CustomHeading.configure(options)
        case 'SimpliCityDocLink':
            return SimpliCityDocLink.configure(options)
        case 'SimpliCityLink':
            return SimpliCityLink.configure(options)
        case 'SimpliCityImage':
            return SimpliCityImage.configure(options)
        // case 'Link':
        //     return Link.configure(options)
    }
}


