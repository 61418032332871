/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useForm } from 'cms/forms/context'
import ErrorMsg from '../error-msg'
import { useNumericalConstraints } from '../hooks/use-numerical-constraints'
import { useNumericalValidator } from '../hooks/use-numerical-validator'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestion<number>>}
 */
const SimplicityFormNumeric = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        hasPlaceholder,
        hasPlaceholderText, // is the text, is not a boolean
        hasHelperText,
        helperText,
        isRequired,

        // min
        hasMinValue,
        minValue,

        // max
        hasMaxValue,
        maxValue,

        // decimals
        hasDecimalValue,
        decimalValue
    } = field || {}
    const { onInputChange } = useForm()

    const { min, max, decimals } = useNumericalConstraints(
        hasDecimalValue,
        decimalValue,
        hasMinValue,
        minValue,
        hasMaxValue,
        maxValue,
    )
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue,
    });
    const precision = decimalValue || 1
    const stepPower = 1 / Math.pow(10, precision)
    const stepper = precision > 0 ? formatter.format(stepPower) : 1
    const defaultPlaceholder = precision > 0 ? formatter.format(1) : 1
    // const placeholder = hasPlaceholder ? hasPlaceholderText : `Enter a number like: ${defaultPlaceholder}`
    const placeholder = hasPlaceholder ? hasPlaceholderText : `${defaultPlaceholder}`
    const currentError = useNumericalValidator(
        min,
        max,
        decimals,
        isRequired,
        value
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    return (
        <div className="simplicity-forms-field-group">
            <label className="simplicity-forms-field-label">
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description &&
                <span className="simplicity-forms-description">{description}</span>
            }
            <input
                type='number'
                id={guid}
                name={guid}
                placeholder={placeholder}
                value={value}
                min={min}
                max={max}
                step={stepper}
                onChange={(e) =>
                    onInputChange(e.target.value, field)
                }
                className={hasError ? 'form-field-error-input' : ''}
            />
            {hasError &&
                <ErrorMsg type="error" error={error} />
            }
            {hasHelperText && helperText &&
                <span className="simplicity-forms-helper-text">{helperText}</span>
            }
        </div>
    )
}

export { SimplicityFormNumeric }
