/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { Menu } from "./menu";
import { MenuItem } from "./menu_item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WebsiteLogo } from "components/header/logo";

/**
 *
 * @param {object} props
 * @param {() => void} props.toggleMobile
 */
const PrimaryMenu = (props) => {
    const { toggleMobile, show } = props;

    const { menu, side } = useSimpliCity();

    return (
        <div className="primary-menu">
            <div className="primary-menu-content">
                {/*desktop menu*/}
                <div className="primary-menu-content-logo">
                    <WebsiteLogo />
                </div>
                <div className="primary-menu-content-menu simplicity-menu-wrapper menu-level-1">
                    <Menu level={1} side={side} position={1} id="simplicity-menu-level1">
                        {menu.map((item, index) => (
                            <MenuItem
                                parent_postion={1}
                                position={index}
                                level={0}
                                side={index < 2 ? "right" : "left"}
                                size={menu.length}
                                title={item.title}
                                url={item.url}
                                nav_children={item.children}
                                key={item.url}
                            />
                        ))}
                    </Menu>
                </div>
                <div className={show ? "primary-menu-content-menu-mobile open" : "primary-menu-content-menu-mobile"}>
                    <button onClick={toggleMobile}>
                        <div>Menu</div>
                        <div>
                            <FontAwesomeIcon icon={`fa-regular fa-bars`} />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export { PrimaryMenu };
