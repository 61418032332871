/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Outlet } from 'react-router-dom'
import { BreadcrumbList } from 'cms/shared/breadcrumb'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { EnvWrapper } from 'cms/auth/auth/components/env-wrapper'

const ApplicationLayout = (props) => {

    const { crumbs } = props

    return <>
        <EnvWrapper>
            <Header />

            {/* Child routes are rendered here */}
            <div className="application layout layout-fullwidth">

                <BreadcrumbList crumbs={crumbs}/>

                <Outlet />

            </div>

            <Footer />
        </EnvWrapper>
    </>
}
export { ApplicationLayout }
