/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

/**
 * areChildrenHidden
 * Check if the children are hidden
 * @param itemChildren
 * @returns {boolean}
 */
export const areChildrenHidden = (itemChildren) => {
    const hidden = itemChildren.filter((ic) => ic.hide_in_nav === 1)
    return hidden.length === itemChildren.length
}

export const checkSiblingGuids = (openMenus, guid, tree) => {
    let omRemove = ""
    const isSameLevel = tree.filter((t) => t.guid === guid)
    if (isSameLevel.length > 0) {
        const treeGuids = tree.map((t) => t.guid)

        for (let g of treeGuids) {
            for (let o of openMenus) {
                if (o === g) {
                    omRemove = g
                }
            }
        }
    }
    // value that should be removed from openMenus
    return omRemove
}
