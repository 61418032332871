/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
// import { Logger } from 'cms/utils/logger'

const StageGuard = (props) => {

    const { children } = props
    const isStage = CmsConfig.isStage
    const isPreview = CmsConfig.isPreview

    // Logger.info(`[isStage : ${isStage}]`)
    // console.log(`[isStage : ${isStage}]`)


    if (!isStage) {
        return null
    }
    if (isPreview) {
        return null
    }
    return (
        <>{children}</>
    )
}

export { StageGuard }
