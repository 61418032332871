/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Checkbox } from '@mui/material'
import { useGridErrors } from 'cms/forms/hooks/use-grid-errors'
import { useGridValues } from 'cms/forms/hooks/use-grid-values'
import { useGridVisibleRows } from 'cms/forms/hooks/use-grid-visible-rows'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { getShortType } from 'cms/utils/get-short-type'
import { get } from 'lodash'
import ErrorMsg from '../../error-msg'
import { GridDropdown } from './grid-dropdown'
import { GridNumerical } from './grid-numerical'
import { GridShortAnswer } from './grid-short-answer'

const GridInputComponents = {
    'question.numericalValue': GridNumerical,
    'question.shortAnswer': GridShortAnswer,
    'question.dropdown': GridDropdown,
}

const ColumnStyleMap = {
    numericalValue: 'numerical-value',
    shortAnswer: 'short-answer',
    dropdown: 'dropdown',
}

/**
 * @type {QuestionComponent<FormQuestionGrid>}
 */
export const SimplicityFormGrid = (props) => {
    const { field } = props
    const {
        prompt,
        hasDescription,
        description,
        isRequired,
        isTouched,
        columnLabel,
        options: rows,
        columns,
    } = field || {}


    const { onChange, removeRow, gridValues } = useGridValues(field)
    const { onError, removeRowErrors, formattedErrors } = useGridErrors(
        field,
        rows,
        gridValues
    )

    const { visibleRows, toggleRowVisibility } = useGridVisibleRows(
        field,
        (rowId) => {
            removeRow(rowId)
            removeRowErrors(rowId)
        },
    )

    const { error, hasError } = useShowError(formattedErrors, field)

    const dynamicColumnWidth =  columns.length <= 2 ? '220px' : `${50 * columns.length + 80}px`;

    return (
        <div className="simplicity-forms-field-group">
            {hasError && <ErrorMsg type="error" error={error} />}

            <label className="simplicity-forms-field-label">
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description && <span className="simplicity-forms-description">{description}</span>}

            <div className='forms-table-wrapper'>
                <table>

                    {/* colgroup */}
                    <colgroup>
                        <col />
                        <col className='row-title' />
                        {columns.map((column) => {
                            const shortType = getShortType(column.type)

                            return (
                                <col key={`col-group-${column.guid}`} className={ColumnStyleMap[shortType]} />
                            )
                        })}
                    </colgroup>

                    {/* table header */}
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ minWidth: dynamicColumnWidth}}>{columnLabel}</th>
                            {columns.map((column) =>
                                <th key={column.guid}>{column.label}</th>
                            )}
                        </tr>
                    </thead>

                    {/* table body */}
                    <tbody>
                        {rows.map((option) => {
                            const isVisibleRow = visibleRows[option.id]
                            let defaultOtherLabel

                            if (option.isOther) {
                                defaultOtherLabel = get(
                                    field.value,
                                    [option.id, 'other'],
                                    ''
                                )
                            }

                            return (
                                <tr key={option.id}>
                                    {/* row checkbox */}
                                    <td>
                                        <Checkbox
                                            checked={isVisibleRow}
                                            onChange={() => toggleRowVisibility(option.id)}
                                        />
                                    </td>

                                    {/* row label */}
                                    <td style={{ fontWeight: isVisibleRow ? "bold" : "normal", minWidth: dynamicColumnWidth }}>
                                        {option.isOther ?
                                            <GridShortAnswer
                                                subQuestion={{
                                                    // The `other` input is only required if it's selected
                                                    required: isVisibleRow,
                                                }}
                                                defaultValue={defaultOtherLabel}
                                                onChange={(value) => onChange(option.id, 'other', value)}
                                                onError={(error) => onError(option.id, 'other', error)}
                                                isTouched={isTouched}
                                                shouldUpdate={isVisibleRow}
                                                placeholder="Other"
                                            /> :
                                            option.label
                                        }
                                    </td>

                                    {/* dynamic columns */}
                                    {columns.map((column) => {
                                        const GridInputComponent = GridInputComponents[column.type]
                                        const defaultValue = get(
                                            field.value,
                                            [option.id, column.guid],
                                        )

                                        return (
                                            <td key={column.guid} style={{ minWidth: dynamicColumnWidth}}>
                                                {/* show the input if the row has been selected*/}
                                                {isVisibleRow &&
                                                    <GridInputComponent
                                                        defaultValue={defaultValue}
                                                        subQuestion={column.subQuestion}
                                                        isTouched={isTouched}
                                                        onChange={(value) => onChange(option.id, column.guid, value)}
                                                        onError={(error) => onError(option.id, column.guid, error)}
                                                    />}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div >
    )
}
