/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { NavLink } from "react-router-dom"
import { isDocumentHit } from "./is-document-hit"

/**
 * @param {object} props
 * @param {import("./is-document-hit").SearchHit} props.hit
 */
const HitTitleLink = (props) => {

    // const { hit, text = '' } = props
    const { hit } = props
    if (isDocumentHit(hit)) {
        return (
            <NavLink to={hit.document_url} target="_blank">
                <div className="search-hit-title">{hit.title}</div>
            </NavLink>
        )
    }

    return (
        <NavLink to={hit.path}>
            <div className="search-hit-title">{hit.title}</div>
        </NavLink>
    )
}

export { HitTitleLink }
