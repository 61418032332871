/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { Logger } from 'cms/utils/logger'

/**
 * @param {string} dateTimeString
 * @param {DateTimeTypes} dateTimeType
 */
export function dateTimeTransformer (dateTimeString, dateTimeType) {
    const date = dayjs(dateTimeString)

    if (!date.isValid()) {
        Logger.info(`[date-time-transformer] The given date time string is invalid ${dateTimeString}`)
        return
    }

    if (dateTimeType === 'dateTime') {
        // renders: November 25, 2004, 3:00 PM
        return date.format(CmsConfig.dates.display.datetime)
    }

    if (dateTimeType === 'dateOnly') {
        // renders: November 25, 2004
        return date.format(CmsConfig.dates.display.date)
    }

    if (dateTimeType === 'timeOnly') {
        // renders: 3:00 PM
        return date.format(CmsConfig.dates.display.time)
    }

    // by default, return an ISO string
    return date.toISOString()
}


/**
 * @type {SnapshotComponent<DateTimeSnapshotValue>}
 */
const DateTimeSnapshot = (props) => {
    const { value } = props
    const { dateTimeString, dateTimeType } = value

    const formattedValue = useMemo(
        () => dateTimeTransformer(
            dateTimeString,
            dateTimeType
        ),
        [dateTimeString, dateTimeType]
    )

    return (
        <>{formattedValue}</>
    )
}

export { DateTimeSnapshot }

/**
 * @typedef {object} DateTimeSnapshotValue
 * @property {DateTimeTypes} dateTimeType
 * @property {string} dateTimeString
 */


/**
 * @typedef {'dateTime' | 'dateOnly' | 'timeOnly'} DateTimeTypes
 */
