/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

export const getFields = (model) => {
    if (!model) {
        return
    }
    const fields = {}
    if (!model?.fields) {
        return fields
    }
    for (const field of model.fields) {
        const name = field.name
        fields[name] = field
    }
    return fields
}
export const getFieldConfig = (model) => {
    if (!model) {
        return
    }
    const fields = []
    if (!model?.fields) {
        return fields
    }
    for (const field of model.fields) {
        const data = { name: field.name, type: field.type  }
        fields.push(data)
    }
    return fields
}
export const isList = (field) => {
    const settings = getFieldSettings(field)
    const {list} = settings || {}
    return list === true
}
export const hasValues = (field) => {
    const values = getFieldValues(field)
    return Array.isArray(values) && values.length > 0
}
export const getFieldValues = (field) => {
    const { values } = field || {}
    return values
}
export const getFieldSettings = (field) => {
    const { settings } = field || {}
    return settings
}
export const hasValue = (field) => {
    const values = getFieldValues(field)
    return Array.isArray(values) && values.length > 0
}
export const getFirstItemFromField = (field) => {
    if (!field) {
        return
    }
    const values = getFieldValues(field)
    return values[0]
}
export const getFirstValueFromField = (field) => {
    const firstValue = getFirstItemFromField(field)
    const { value } = firstValue || {}
    return value
}
export const getFirstSettingsFromField = (field) => {
    const firstValue = getFirstItemFromField(field)
    const { settings } = firstValue || {}
    return settings
}

export const eventTypes = ['model.event', 'model.meeting']

export const isEventType = (model) => {
    const { type } = model
    return eventTypes.includes(type)
}
export const isMatchType = (model,type) => {
    const { type: model_type } = model
    return model_type === type
}

export const slugify = (text) => {
    if (!text) {
        return ''
    }
    return text
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
}
