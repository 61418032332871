/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { isStringNotEmpty, isValidGuid } from 'cms/utils/empty-exists'
import { useAsync } from 'react-use'
import { defaultFormSettings } from './default-form-settings'
import { Logger } from 'cms/utils/logger'

/**
 * @param {string} formGuid
 */
export const useFormSettings = (formGuid) => {
    const { token } = useAuth() // commonly anonymous, in practice

    /**
     * @param {string[]} excerptGuids
     * @returns
     */
    async function loadExcerpts (excerptGuids) {
        const promises = excerptGuids
            .map(
                async (excerptGuid) => {
                    if (isStringNotEmpty(excerptGuid)) {
                        /** @type {ExcerptResponse} */
                        const response = await SimpliCitySDK.excerpt.findOne(token, excerptGuid)
                        Logger.warn('[loadExcerpts] is being loaded to often')
                        return response.content
                    }
                    return null
                }
            )

        const responses = await Promise.all(promises)

        Logger.debug('[loaded-excerpts] Loaded excerpts')
        Logger.debug('[loaded-excerpts] Loaded excerpts responses', { responses })

        return responses
    }

    const state = useAsync(async () => {
        let settings, responses
        if (isValidGuid(formGuid)) {
            /** @type {FormSettings} */
            settings = await SimpliCitySDK.formSettings.find(token, formGuid)
            // Logger.warn('useFormSettings')
            Logger.warn('[useFormSettings] is being loaded to often')
            const excerptGuids = [
                settings.success_description_reference,
                settings.failed_description_reference,
                settings.closed_banner_reference,
                settings.review_description_reference,
            ]

            responses = await loadExcerpts(excerptGuids)
        }

        const [submit, failed, closed, review] = responses || []

        return {
            settings: {
                ...defaultFormSettings,
                ...settings
            },
            excerpts: {
                submit,
                failed,
                closed,
                review
            },
        }
    })

    return state
}
