/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { GetGridRowFactory } from 'cms/forms/questions/grid'
import { objectHasKeys } from 'cms/utils/empty-exists'

/**
 * @param {PositionedItem} a
 * @param {PositionedItem} b
 */
function sortByPositionAsc (a, b) {
    return a.position - b.position
}

/**
 * @param {FormQuestionGrid} question
 */
export function gridValueTransformer (question) {

    const {
        options: rows,
        columns,
        value
    } = question

    if (!objectHasKeys(value)) {
        return []
    }

    // construct 2d array representing rows and columns
    const rowIds = Object.keys(value)
    const getGridRow = GetGridRowFactory(value, rows)

    const formattedRows = rowIds
        .map(
            (rowId) => {
                // get the current row definition
                const rowDefinition = getGridRow(rowId)

                // get the key/value representation of the row (keyed by columnId)
                const rowValues = value[rowId]

                // get the columnIds for the current row
                const columnIds = Object.keys(rowValues)

                const formattedColumns = columnIds
                    // ignore the `other` column; it's used to store the label for
                    // user-defined fields
                    .filter((id) => id !== 'other')
                    .map(
                        (columnId) => {
                            const columnDefinition = columns.find(
                                (column) => column.guid === columnId
                            )

                            const columnValue = rowValues[columnId]
                            const formattedColumn = {
                                colId: columnDefinition.guid,
                                label: columnDefinition.label,
                                position: columnDefinition.position,
                                value: columnValue
                            }

                            // resolve dropdown value
                            if (columnDefinition.type === 'question.dropdown') {
                                /**
                                 * @type {DropdownSubQuestion['options']}
                                 */
                                const dropdownOptions = columnDefinition.subQuestion.options

                                const selectedOption = dropdownOptions.find(
                                    (option) => option.guid === columnValue
                                )

                                formattedColumn.value = selectedOption.label
                            }

                            return formattedColumn
                        }
                    )
                    .sort(sortByPositionAsc)

                return {
                    rowId: rowDefinition.id,
                    label: rowDefinition.label,
                    position: rowDefinition.position,
                    columns: formattedColumns
                }
            }
        )
        .sort(sortByPositionAsc)

    return formattedRows
}

/**
 * @typedef {object} PositionedItem
 * @property {number} position
 */
