/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const TableOfContentsTarget = (props) => {

    const { target } = props

    return (
        <div className='back-to-top-group'>
            <p id={target} className='anchor right'>
                [ <a href={`#top`}>back to top</a> ]
            </p>
        </div>
    )
}

TableOfContentsTarget.displayName = 'TableOfContentsTarget'
export { TableOfContentsTarget }
