/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const CarouselImage = (props) => {

    const { image, settings, isHomepage } = props
    const { src, alt } = image || {}
    let position = 'center center'
    const { options, format } = settings || {}
    if (format === 'image.format.cropped-focus') {
        position = options?.position
    }
    const csstype = isHomepage ? 'home-banner-image' : 'carousel-image'

    if (image) {
        return (
            <div
                className={csstype}
                title={alt}
                style={{
                    backgroundImage: `url(${src})`,
                    backgroundSize: 'cover',
                    backgroundPosition: `${position}`,
                    objectFit: 'contain',
                }}
            />
        )
    } else {
        return (
            <div className='carousel-loading'>
                <div className='loader'>
                    {/*Loading image...*/}
                </div>
            </div>
        )
    }
}

CarouselImage.displayName = 'CarouselImage'
export { CarouselImage }
