/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isArray } from 'cms/utils/empty-exists'

/**
 * @param {OptionalQuestion<FormQuestionCheckbox>} question
 * @param {Record<string, string>=} labela
 */
const checkboxValueTransformer = (question, labels = {}) => {
    const { value: selectedOptionIds, options } = question

    if (!isArray(selectedOptionIds)) {
        return []
    }

    const selectedOptions = options
        .filter(
            (option) => selectedOptionIds.includes(option.id)
        )
        .map(
            (option) => {
                const { id, value } = option
                const otherLabel = labels[id]

                const label = option.isOther && otherLabel ?
                    otherLabel :
                    option.label

                return {
                    id,
                    label,
                    value
                }
            }
        )

    return selectedOptions
}

export { checkboxValueTransformer }
