/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isArray, isArrayNotEmpty } from 'cms/utils/empty-exists'

/**
 * @template {NavItemLike} T
 * @param {T[]=} list
 */
const filterHidden = function (list) {
    const filtered = list?.filter((item) => !item.hide_in_nav)
    return filtered
}

/**
 * @template {NavItemLike} T
 * @param {object} obj
 * @param {T[]} obj.children
 */
const filterHiddenRecursively = (obj) => {
    // filter object directly
    obj.children = filterHidden(obj.children)

    // filter children
    for (const child of obj.children) {
        if (isArray(child.children)) {
            filterHiddenRecursively(child)
        }
    }
}

const readNode = async function (webpage) {
    if (!webpage) {
        return
    }
    const {
        nav_title: title,
        children,
        path: url,
        hide_in_url,
        // slug,
    } = webpage

    return {
        title, url, children, hide_in_url
    }
}

const readChildren = async function (webpage, level, side) {
    if (!webpage) {
        return
    }
    const { children: all_children } = webpage
    const filteredChildren = filterHidden(all_children)
    const children = []
    if (isArrayNotEmpty(filteredChildren)) {
        for (const child of filteredChildren) {
            const child_data = await readNode(child)
            child_data.side = side
            child_data.level = level + 1
            if (child.children) {
                child_data.children = await readChildren(
                    child,
                    child_data.level,
                    child_data.side
                )
            }
            children.push(child_data)
        }
    }
    // returns only filtered children
    return children
}


export {
    readNode,
    readChildren,
    filterHidden,
    filterHiddenRecursively
}


/**
 * @typedef {object} NavItemLike
 * @property {boolean | number} hide_in_nav
 */
