/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {ResponseGroup[]} groups
 */
const createGroupCache = (groups) => {
    /**
     * @type {Record<string, QuestionResponseGroup>}
     */
    const groupCache = groups.reduce(
        (collector, group) => {

            /** @type {QuestionResponseGroup} */
            const responseGroup = {
                ...group,
                responses: []
            }

            return {
                ...collector,
                [group.guid]: responseGroup
            }
        },
        {}
    )

    return groupCache
}

/**
 * Groups a list of responses
 * @param {QuestionResponse[]=} responses
 * @param {ResponseGroup[]=} groups
 * @returns {GroupedQuestionResponse[]}
 */
const groupResponses = (responses = [], groups = []) => {
    const groupCache = createGroupCache(groups)

    /** @type {Map<string, GroupedQuestionResponse>} */
    const groupedResponseMap = new Map()

    for (const response of responses) {
        const { group } = response

        if (group && groupCache[group]) {
            // append the response to the cache; since the object assigned by reference,
            // we can set responses here without having to perform an extra lookup
            groupCache[group].responses.push(response)

            if (!groupedResponseMap.has(group)) {
                groupedResponseMap.set(
                    group,
                    groupCache[group]
                )
            }
        } else {
            // append a non-grouped response
            groupedResponseMap.set(
                response.guid,
                response
            )
        }

    }

    const groupedResponses = [...groupedResponseMap.values()]
    return groupedResponses
}

export { groupResponses }
