/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

const NotImplemented = () => {
    return (
        <>Not implemented</>
    )
}

export { NotImplemented }
