/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { getValue, getValues } from 'cms/utils/values-settings'
import { isArrayNotEmpty, isStringNotEmpty } from 'cms/utils/empty-exists'
import { getFields } from 'cms/fields/_helpers/helpers'
import { story_categories } from "cms/models/category-options"


const CategoriesList = (props) => {

    const { model } = props
    const fields = getFields(model)
    const { category, secondary_category } = fields || {}
    let categoryList = []
    const category_value = getValue(category)
    const secondary_category_values = getValues(secondary_category)
    categoryList.push(category_value)
    categoryList = categoryList.concat(secondary_category_values)
    // const link_base = '/dnv-stories/'
    categoryList = categoryList.filter((item) => {
        return isStringNotEmpty(item)
    })
    const getCategoryPath = (label) => {
        const category = story_categories.find((element) => element.label === label)
        return category?.path
    }

    return (
        <div>
            {isArrayNotEmpty(categoryList) &&
                categoryList.map((cat, index) => {
                    if ((cat)) {
                        const isLast = index === categoryList.length - 1
                        const sep = isLast ? '' : ', '
                        const cat_path = getCategoryPath(cat)
                        // const cat_slug = slugify(cat)
                        const link = <a
                            className={`story-category-list-link`}
                            href={`${cat_path}`}>{cat}</a>
                        return (
                            <span key={index}>{link}{sep}</span>
                        )
                    }
                })
            }
        </div>
    )
}

CategoriesList.displayName = 'CategoriesList'
export { CategoriesList }
