/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { DisplayTextOnly } from './_helpers/display-text-only'
import { FieldDisplayWrapper } from './_helpers/field-display-wrapper'

const Text = (props) => {

    const { field } = props
    const { values } = field || {}
    return (
        <FieldDisplayWrapper field={field}>
            {values && Array.isArray(values) &&
                values.map((item, index) => {
                    const { value, settings } = item || {}
                    const { style, format, options } = settings || {}
                    return (
                        <Fragment key={index}>
                            <DisplayTextOnly value={value} style={style} format={format} options={options} />
                        </Fragment>
                    )
                })}
        </FieldDisplayWrapper>
    )
}
export { Text }
