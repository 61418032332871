/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { createContext } from 'react'

/**
 * @type {SimpliCityAppContext}
 */
const defaultContext = {
    loading: true,
    error: null,
}

export const SimpliCityAppContext = createContext(defaultContext)

/**
 * @template {any} A
 * @typedef {import('react').Dispatch<A>} Dispatch
 */

/**
 * @template {any} S
 * @typedef {import('react').SetStateAction<S>} SetStateAction
 */

/**
 * @typedef {object} MenuItem
 * @property {string} guid
 * @property {string} path
 * @property {string} description
 * @property {number} hide_in_nav
 * @property {string} nav_icon
 * @property {string} nav_title
 * @property {string} parent
 * @property {MenuItem[]} children
 */

/**
 * @typedef {any} WebpageError
 */

/**
 * @typedef {object} SimpliCityAppContext
 * @property {boolean} showMobileMenu
 * @property {() => void} toggleMobile
 * @property {import('./fetch-navigation').NavItem[]} menu
 * @property {MenuItem[]} levelOneData
 * @property {string} home
 * @property {string} side
 * @property {boolean} loading
 * @property {Dispatch<SetStateAction<boolean>>} setLoading
 * @property {boolean} hasModel
 * @property {Dispatch<SetStateAction<boolean>>} setHasModel
 * @property {Website} website
 * @property {any} webpage
 * @property {Dispatch<SetStateAction<any>>} setWebpage
 * @property {any} model
 * @property {Dispatch<SetStateAction<any>>} setModel
 * @property {any} nav
 * @property {WebpageError} error
 * @property {Dispatch<SetStateAction<WebpageError>>} setError
 * @property {any} fields
 * @property {Dispatch<SetStateAction<any>>} setFields
 */

/**
 * @typedef {object} Website
 * @property {number} id
 * @property {string} guid
 * @property {string} application
 * @property {string} title
 * @property {string} description
 * @property {string} home
 * @property {string} domain
 * @property {string} updated_at
 * @property {string} updated_by
 * @property {string} created_at
 * @property {string} created_by
 * @property {import('cms/webpage').NullableString} published_at
 * @property {import('cms/webpage').NullableString} published_by
 */
