/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { FormProvider } from 'cms/forms/context'
import { Form } from 'cms/forms/form'

/**
 * @type {FormState}
 */
const initialFormState = {
    schemaForm: {
        name: "",
        version: "1.0.0",
        form_id: "",
        type: "request",
        questions: [],
        steps: [],
        updated_at: undefined
    },
    resetCount: 0
}

const FormsComponent = (props) => {
    const { component } = props

    return (
        <FormProvider initState={initialFormState}>
            <Form component={component} />
        </FormProvider>
    )
}

FormsComponent.displayName = 'FormsComponent'

export { FormsComponent }
