/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'

const useAlerts = () => {

    const { token = '' } = useAuth()

    const [alert, setAlert] = useState()
    const [alertLoaded, setAlertLoaded] = useState(false)

    const loadAlerts = async function () {
        const _alert = await SimpliCitySDK.alert.findCurrent(token)
        if (!!_alert) {
            setAlert(_alert)
            setAlertLoaded(true)
        }
    }

    return {
        loadAlerts,
        alert,
        alertLoaded
    }
}

export { useAlerts }
