/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { LargeFeedFilter } from 'cms/shared/feeds/large'
import { FeedbackHelpful } from 'components/feedback-helpful'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

const News = () => {

    const { webpage, model } = useSimpliCity()

    const type = 'model.news'

    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>News</h1>

            <LargeFeedFilter
                type={type}
                enableBar={true}
            />

            <FeedbackHelpful model={model} webpage={webpage} />

        </main>
    )
}

export { News }
