/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { getExtension } from 'cms/rich-text/tiptap-extensions/get-extension'
import { extensionConfig } from './extensions-config'

export const assembleTipTapExtensions = (options) => {
    const defaultOptions = { CustomHeading: { enableTOC: false } }
    const extensions = []
    options = options ? options : defaultOptions
    for (const key of Object.keys(extensionConfig)) {
        const config = extensionConfig[key]
        const target = config.options
        const source = options[key]
        const mergedOptions = { ...target, ...source }
        if (config.enabled) {
            const extension = getExtension(key, mergedOptions)
            // console.log(extension.name, key, mergedOptions)
            extensions.push(extension)
        }
    }
    return extensions
}

