/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @type {QuestionComponent<FormQuestionGroup>}
 */
const SimplicityFormGroup = (props) => {
    const { field } = props
    const { prompt, showTitle } = field

    return (
        <div className='form-question-group'>
            {showTitle && <h3>{prompt}</h3>}
        </div>
    )
}

export { SimplicityFormGroup }
