/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isStringNotEmpty } from 'cms/utils/empty-exists'

/**
 * Temporary hack to show/hide FontAwesome icons
 * @param {object} props
 * @param {string} props.icon
 * @param {string=} props.color
 * @param {string=} props.fontSize
 * @param {boolean} props.show
 */
export function FaWrapper (props) {

    const { icon, show } = props
    if (!isStringNotEmpty(icon)) {
        return null
    }

    return (
        <span hidden={!show}>
            <FontAwesomeIcon icon={icon}/>
        </span>
    )
}
