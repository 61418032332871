/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import cn from 'clsx'

export const Menu = (props) => {

    const { children, level, side } = props

    const classes = cn(
        'simplicity-menu',
        // FIXME: the level doesn't seem to be used anymore
        { [`level-${level}`]: level },
        side
    )

    return (
        <ul className={classes}>
            {children}
        </ul>
    )
}
