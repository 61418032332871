/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { objectHasPropertyString } from 'cms/utils/empty-exists'
// import { faArrowUpRightFromSquare } from 'icons'
import { icons } from 'icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ListLink = (props) => {

    const { link } = props
    const { url, link_type, text } = link || {}
    const isExternalLink = link_type === 'external'
    const target = isExternalLink ? '_blank' : '_self'

    return (
        <Fragment>
            {objectHasPropertyString(link, "url") && (
                <div className={'image-list-item-link'}>
                    <a href={url} target={target} rel="noreferrer">
                        {text}
                    </a>
                    {isExternalLink &&
                        <>&nbsp;<FontAwesomeIcon icon={icons.regular.faArrowUpRightFromSquare} size='1x' /></>
                    }
                </div>
            )}
        </Fragment>
    )
}

ListLink.displayName = 'LinkList'
export { ListLink }
