/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FooterContactUs () {
    return (
        <div className="footer-contact">
            <h2>Contact us</h2>
            <div className={"footer-logo"}>
                <a href="/" className="footer-logo logo stacked">
                    District of North Vancouver
                </a>
            </div>
            <p>604-990-2311</p>
            <p>
                <a href="mailto:infoweb@dnv.org">infoweb@dnv.org</a>
            </p>
            <address>
                355 West Queens Road
                <br />
                North Vancouver, BC V7N 4N5
            </address>
            <a href="/government-administration/contact-us" className="footer-button-link" aria-label="Full Firectory">
                <div>Full directory</div>
                <div>
                    <FontAwesomeIcon icon={`fa-regular fa-arrow-right`} />
                </div>
            </a>
        </div>
    );
}

export { FooterContactUs };
