/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useState } from "react";
import { MobileMenu } from "./mobile_menu";
import { MobileBackDrop } from "./mobile_menu/mobile-backdrop";
import { PrimaryMenu } from "./primary_menu";
import { TopBar } from "./top_bar";
import { AlertBanner } from "components/header/alert-banner";
import { useMedia } from "react-use";

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const isDesktop = useMedia("screen and (min-width: 1125px)");

    const toggleMobile = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <header className="header">
            <AlertBanner />

            {isDesktop && <TopBar />}

            <PrimaryMenu toggleMobile={toggleMobile} show={showMobileMenu} />

            <MobileMenu show={showMobileMenu} />

            {!isDesktop && <TopBar />}
            {showMobileMenu && <MobileBackDrop toggleMobile={toggleMobile} />}
        </header>
    );
};

export { Header };
