/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { Button } from '@mui/material'
import { ReactComponent as PublishedIcon } from 'cms/assets/icons/circle-check-regular-published.svg'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'
import { useCookies } from 'react-cookie'
import { ReactComponent as SimpliCityIcon } from 'cms/assets/icons/simplicity-icon-gray.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSimpliCityComposer } from 'cms/hooks/use-simplicity-composer'

const StageBanner = (props) => {

    const { children } = props
    const admin_auth_cookie_name = 'simplicity_admin'
    const environment_name = `${CmsConfig.Environment.Name}`
    const { editor } = useSimpliCityComposer()

    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(admin_auth_cookie_name)
    const logout = () => {
        Logger.info('logout taking place')
        removeCookie('simplicity_admin', {path: '/', domain: CmsConfig.COOKIE_DOMAIN})
        removeCookie('simplicity_id', { path: '/', domain: CmsConfig.COOKIE_DOMAIN })
        removeCookie('simplicity_refresh', {path: '/', domain: CmsConfig.COOKIE_DOMAIN})
        const url = `${CmsConfig.LOGOUT_URL}`
        Logger.info(`Logout: ${url}`)
        window.location.replace(url)

    }
    const onLogout = (event) => {
        event.preventDefault()
        logout()
    }

    return (
        <>
            <div className='bannerContainer'>
                <div className='leftContent'>
                    <SimpliCityIcon className='bannerIcon' />
                    <span className="environment-label">[{environment_name}]</span>
                    <PublishedIcon className='bannerIcon' />
                    <span>Stage</span>
                </div>
                <div className='rightContent'>
                    <Button
                        variant="text"
                        size="small"
                        href={editor}
                        target={'_blank'}
                        className='editButton'
                        startIcon={<FontAwesomeIcon icon="fa-regular fa-pen-to-square" />}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="text"
                        size="small"
                        onClick={onLogout}
                    >
                        Logout
                    </Button>
                </div>
            </div>
            {children}
        </>
    )
}

export { StageBanner }
