/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
// TODO: Deprecate in favour of IconText
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { Grid } from '@mui/material'
import { ErrorList } from './error-list'
import { isArrayGreaterThan } from 'cms/utils/empty-exists'

/**
 * @param {object} props
 * @param {string | string[]} props.error
 */
const ErrorMsg = (props) => {
    const { error } = props
    const hasMultipleErrors = isArrayGreaterThan(error, 1)

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="flex-start"
            className='form-field-error-container'
        >
            <Grid
                justifyContent="center"
                className='form-field-error-label'>
                <div className='form-field-error'>
                    <div className='error-icon'><ReportProblemIcon /></div>
                    <div className='error-text'>
                        {hasMultipleErrors && <p className='form-field-error-heading'>Please fix the following errors:</p>}
                        <ErrorList error={error} />
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default ErrorMsg

