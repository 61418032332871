/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { isStringNotEmpty } from "cms/utils/empty-exists";

const getStyleName = (style) => {
    return isStringNotEmpty(style) && style.startsWith('field.style.') &&
        style.replace('field.style.', '')
}

const DisplayTextOnly = (props) => {

    const { value, style } = props
    const formattedStyle = getStyleName(style)
    return (
        <Fragment>
            {isStringNotEmpty(value) &&
                <span className={`simplicity-field ${formattedStyle}`}>
                    {value}
                </span>
            }
        </Fragment>
    )
}
export { DisplayTextOnly }
