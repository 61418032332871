/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SnapshotTypeMap } from './snapshot-type-map'
import { NotImplemented } from './snapshots/not-implemented'
import { Logger } from 'cms/utils/logger'

/**
 * @param {object} props
 * @param {QuestionResponse} props.response
 */
const DynamicResponse = (props) => {
    const { response } = props
    const { prompt, type, value } = response

    let SnapshotComponent = SnapshotTypeMap[type]

    if (!SnapshotComponent) {
        Logger.debug(`[form-result-field] No component found for question type ${type}`)
        SnapshotComponent = NotImplemented
    }

    return (
        <div className='result-form'>
            <h3 className="title">
                {prompt}
            </h3>
            <SnapshotComponent value={value} />
        </div>
    )
}

export { DynamicResponse }
