/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
import { Button } from '@mui/material'

const StageLogin = () => {

    const login_url = CmsConfig.LOGIN_URL
    const environmentName = CmsConfig.Environment.Name
    const isStage = CmsConfig.isStage

    if (!isStage) {
        return null
    }

    return (
        <div className='bannerContainer'>
            <div className='leftContent'>
                <span className="environment-label">[{environmentName}]</span>
                <span>Sign-in required to use this app.</span>
            </div>
            <div className='rightContent'>
            <Button
                variant="text"
                size="small"
                href={login_url}
                className='editButton'
            >
                Login
            </Button>
            </div>
        </div>
    )
}

export { StageLogin }
