/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useEffect, useState } from 'react'
import { filterHiddenRecursively } from '../read_node'
import { Logger } from 'cms/utils/logger'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

/**
 * @param {string} webpageGuid
 */
const usePageNav = (webpageGuid) => {

    let {
        parents,
        navTree,
    } = useSimpliCity()

    // tracks the section tree structure that makes up the sidebar navigation
    const [sectionTree, setSectionTree] = useState({})
    const [error, setError] = useState()

    // tracks the current section guid
    const [sectionGuid, setSectionGuid] = useState()

    // controls highlighting for the active menu item
    const [selectedSections, setSelectedSections] = useState([])

    // tracks the menus that the user has open
    const [openMenus, setOpenMenus] = useState([])

    async function prepareParents () {
        try {
            // FIXME: this logic may not always be correct for unparented pages
            if (parents) {
                // const section = parents?.length > 1 ? parents[0] : undefined
                const section = parents[parents.length - 2]
                const parentGuids = parents.map((page) => page?.guid)
                // parentGuids is a list of ancestor guids
                // - ordered from the parent of this webpage up to the root
                Logger.debug(`[use-page-nav] section guid, parents for webpage ${webpageGuid}`)
                if (section && section?.guid) {
                    setSectionGuid(section?.guid)
                }
                setSelectedSections(parentGuids)
                setOpenMenus(parentGuids)
            }

        } catch (error) {
            Logger.error(error)
            setError({ error })
        }
    }

    async function prepareSectionTree () {
        try {
            if (navTree) {
                Logger.info(`[use-page-nav] section tree for section ${sectionGuid}`, { sectionGuid })
                Logger.debug('[use-page-nav] section tree', navTree)
                filterHiddenRecursively(navTree)
                setSectionTree(navTree)
            }
        } catch (error) {
            Logger.error(error)
            setError({ error })
        }
    }

    // when the webpageGuid changes, fetch its parents
    useEffect(() => {
        if (webpageGuid) {
            prepareParents()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webpageGuid])


    // when the sectionGuid changes, fetch the tree
    useEffect(() => {
        if (sectionGuid) {
            prepareSectionTree()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionGuid])

    return {
        sectionGuid,
        sectionTree,
        error,
        selectedSections,
        openMenus,
        setOpenMenus
    }
}

export { usePageNav }
