/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SortDropdown } from "./sort-dropdown";
// FIXME: move to css
import styles from "./sort-results.module.css";

export function SortResults (props) {
    const { className } = props;
    return (
        <div className={`${styles.container} ${className}`}>
            <p>Sort by</p>
            <SortDropdown />
        </div>
    );
}
