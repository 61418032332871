/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { GridRowSnapshot } from './grid-row.snapshot'

/**
 * @type {SnapshotComponent<GridRowSnapshot[]>}
 */
const GridSnapshot = (props) => {
    const { value: rows } = props

    return (
        <>
            {rows.map(
                (row) => {
                    const { columns, label, rowId } = row

                    return (
                        <GridRowSnapshot
                            key={rowId}
                            label={label}
                            columns={columns}
                        />
                    )
                }
            )}
        </>
    )
}

export { GridSnapshot }
