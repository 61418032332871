/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { NavLink } from 'react-router-dom'

/**
 * Renders just the title when there are children.  Otherwise renders a link.
 * @type {import('react').FC<NavigationSummaryLinkProps>}
 */
const NavigationSummaryLink = (props) => {
    const { path, title, hasChildren } = props

    if (hasChildren) {
        return title
    }

    return (
        <NavLink to={path} style={{ textDecoration: "none" }}>
            {title}
        </NavLink>
    )
}

export { NavigationSummaryLink }


/**
 * @typedef {object} NavigationSummaryLinkProps
 * @property {string} path
 * @property {string} title
 * @property {boolean} hasChildren
 */
