/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import axios from 'axios'
import {createContext, useEffect, useReducer, useState} from 'react'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'
import { jwtDecode } from 'jwt-decode'
import { useCookies } from 'react-cookie'
import { AuthReducer, initialState } from 'cms/auth/auth/store/reducers/auth'
import { LOGIN, LOGOUT } from 'cms/auth/auth/store/reducers/actions'
import { Meta } from 'cms/shared/meta'
import { TopEdgeLoader } from 'cms/auth/auth/components/top-edge-loader'
import { AuthSessionCheck } from 'cms/auth/auth/auth-session-check'

/**
 * @type {{ token?: string }}
 */
const defaultContext = null

const SimpliCityAuthContext = createContext(defaultContext)

const SimpliCityAuthProvider = (props) => {

    const { children } = props
    const API_BASEURL = CmsConfig.API_BASEURL
    const login_url = CmsConfig.LOGIN_URL
    const profile_endpoint = `${API_BASEURL}/admin/account/me`
    const cookie_domain = CmsConfig.COOKIE_DOMAIN
    const admin_auth_cookie_name = 'simplicity_admin'
    const [state, dispatch] = useReducer(AuthReducer, initialState)
    const [cookies, setCookie, removeCookie] = useCookies(admin_auth_cookie_name)
    const isStage = CmsConfig.isStage
    const isPreview = CmsConfig.isPreview
    const isLive = !isPreview && !isStage

    /**
     * verifyToken
     * decodes the JWT Token and determines if it is valid
     * @param serviceToken
     * @returns {boolean}
     */
    const verifyToken = (serviceToken) => {
        if (!serviceToken) {
            return false
        }
        const decoded = jwtDecode(serviceToken)
        return decoded.exp > (Date.now() / 1000)
    }

    /**
     * getUserProfile
     * use the access token to call the "me" API to get profile information.
     * Dispatches a LOGIN action with user profile data
     * @param serviceToken
     * @returns {Promise<void>}
     */
    const getUserProfile = async (serviceToken) => {
        const response = await axios({
            method: 'GET',
            url: profile_endpoint,
            headers: { Authorization: `Bearer ${serviceToken}`},
            timeout: 8000,
            responseType: 'json'
        })
        return response.data
    }

    /**
     * setSession
     * calls set cookie with the service token. primes API client to include Bearer auth with
     * the token in API calls
     * @param serviceToken
     */
    const setSession = (serviceToken) => {
        if (serviceToken) {
            Logger.debug('SET SERVICE TOKEN')
            // Logger.debug('SET SERVICE TOKEN: ' + serviceToken)
            setCookie(admin_auth_cookie_name, serviceToken,  { path: '/', domain: cookie_domain })
            // localStorage.setItem('serviceToken', serviceToken)
            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
        } else {
            Logger.debug('SET SERVICE TOKEN: MISSING' )
            removeCookie(admin_auth_cookie_name)
            // localStorage.removeItem('serviceToken')
            delete axios.defaults.headers.common.Authorization
        }
    }

    /**
     * logout
     * destroys the auth cookie and dispatches a LOGOUT action
     */
    const logout = () => {
        Logger.debug("logout taking place")
        removeCookie(admin_auth_cookie_name, { path: '/', domain: cookie_domain })
        setSession(null)
        dispatch({type: LOGOUT})
    }

    /**
     * getToken
     * @returns {string} service token (access_token) found in auth cookie
     */
    const getToken = () => {
        return cookies[admin_auth_cookie_name]
    }

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = cookies[admin_auth_cookie_name]
                const verified = verifyToken(serviceToken)

                if (isLive) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            undefined,
                            token: serviceToken
                        }
                    })

                } else if (serviceToken && verified) {
                    Logger.info(`[SimpliCityAuthContext] verified`)
                    // console.log(`[SimpliCityAuthContext] verified`)

                    // Get user Profile
                    const user = await getUserProfile(serviceToken)
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user,
                            token: serviceToken
                        }
                    })

                } else {
                    Logger.info(`Login: ${login_url}`)
                    Logger.info(`[SimpliCityAuthContext] redirect to login`)
                    console.log(`[SimpliCityAuthContext] redirect to login`)

                    // Redirect to Login
                    window.location.replace(login_url)
                }
            } catch (err) {
                Logger.error(`[SimpliCityAuthContext] ERROR: ${err.message}`, { err })
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    // if not initialized only show the top loader
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <TopEdgeLoader/>
    }

    const simpliCityAuthContext = {
        ...state,
        logout,
        getToken,
    }

    // once initialized - return children wrapped in an SimpliCityAuthContext
    return (
        <SimpliCityAuthContext.Provider value={simpliCityAuthContext}>

            <Meta />

            <AuthSessionCheck>
                {children}
            </AuthSessionCheck>

        </SimpliCityAuthContext.Provider>
    )
}

export { SimpliCityAuthProvider, SimpliCityAuthContext }
