/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from 'icons'
const { faQuoteLeft } = regular

function QuoteComponent (props) {

    const { component } = props
    // const { richtext, author } = component
    const { author } = component

    return (
        <div>
            <blockquote className="simplicity-quote">
                <FontAwesomeIcon icon={faQuoteLeft} />
                {author && (
                    <div className={"author"}>
                        <div>- {author}</div>
                    </div>
                )}
            </blockquote>
        </div>
    )
}


QuoteComponent.displayName = 'QuoteComponent'
export { QuoteComponent }
