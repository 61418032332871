/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {FormQuestionRating} question
 */
const ratingValueTransformer = (question) => {

    const value = {
        selected: question.value,
        max: question.rating,
        iconType: question.iconType
    }

    return value
}

export { ratingValueTransformer }
