/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Heading } from '@tiptap/extension-heading'
import { mergeAttributes } from '@tiptap/core'
import shortHash from 'short-hash'

const CustomHeading = Heading.extend({

    addOptions () {
        return {
            levels: [1, 2, 3, 4, 5, 6],
            HTMLAttributes: {},
            enableTOC: false
        }
    },

    renderHTML ({ node, HTMLAttributes }) {

        const enabled = this.options?.enableTOC
        const hasLevel = this.options?.levels?.includes(node.attrs.level);
        const level = hasLevel
            ? node?.attrs?.level
            : this.options?.levels[0]

        // Uses <H2> tags (level 2)
        if (enabled && level === 2) {
            const fragment = node?.content
            const textNode = fragment?.content[0]  // TODO: this should maybe loop
            const text = textNode?.text
            const hasContent = !!text
            if (!hasContent ) {
                return ''
            }
            const textHash = shortHash(text)

            return [
                `div`,
                ['div', {'class': 'simplicity-heading'},
                    ['div', {'class': 'back-to-top-group'},
                        ['p', {'id': textHash, 'class': 'anchor right'},
                            '[',
                            ['a', {'href': '#top'}, 'back to top'],
                            ']',
                        ],
                    ]
                ],
                [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
            ]
        } else {
            return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
        }
    },

})

export {CustomHeading}
