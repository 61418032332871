/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { optionTransformer } from './option.transformer'

/**
 * @param {FormQuestionGrid} question
 * @returns {FormQuestionGrid}
 */
const gridSchemaTransformer = (question) => {
    const {
        guid,
        hasOtherOptions,
        options: initialOptions,
        otherOptions
    } = question

    const options = optionTransformer(
        guid,
        hasOtherOptions,
        initialOptions,
        otherOptions,
        () => {
            return {
                type: 'question.shortAnswer'
            }
        }
    )

    const newQuestion = {
        ...question,
        options,
        value: {}
    }

    return newQuestion
}

export { gridSchemaTransformer }
