/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { FeatureWebpage } from 'cms/components/feature-webpage/feature-webpage'

const FeatureWebpageComponent = (props) => {

    const { component } = props
    const {
        webpageCard = '',
        webpages = [],
        // settings
    } = component || {}

    return (
        <div className={`feature-webpage-container ${webpageCard}_${webpages.length}`}>
            {webpages?.length > 0 && (
                <Fragment>
                    {webpages.map((web, idx) => (
                        <FeatureWebpage
                            key={idx}
                            idx={idx}
                            web={web}
                            webpageCard={webpageCard}
                            total={webpages.length}
                        />
                    ))}
                </Fragment>
            )}
        </div>
    )
}

FeatureWebpageComponent.displayName = 'FeatureWebpageComponent'
export { FeatureWebpageComponent }
