/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { FormControlLabel, Switch } from '@mui/material'
import { useMemo } from 'react'
import { useToggleRefinement } from 'react-instantsearch'

export function DocumentTypeToggle () {
    const attribute = 'is_document'

    const { refine, value } = useToggleRefinement({
        attribute,
        on: true,
        off: false,
    })

    /** @type {import('@mui/material').FormControlLabelProps['onChange']} */
    const toggleFilter = (event, checked) => {
        refine({ isRefined: !checked })
    }

    // We have to memoize this value for MUI; it will only be set on the initial render
    const defaultChecked = useMemo(
        () => value.isRefined,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return (
        <div>
            <FormControlLabel
                name='document-type-toggle'
                control={
                    <Switch
                        defaultChecked={defaultChecked}
                        color='focus'
                    />
                }
                onChange={toggleFilter}
                label='Show documents'
            />
        </div>
    )
}
