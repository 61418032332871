/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useForm } from 'cms/forms/context'
import { useMemo } from 'react'
import Select from 'react-select'
import { ErrorMessages } from '../error-messages'
import ErrorMsg from '../error-msg'
import { useSetQuestionErrors } from '../hooks/use-set-question-errors'
import { useShowError } from '../hooks/use-show-error'

/**
 * @type {QuestionComponent<FormQuestionDropdown>}
 */
const SimplicityFormDropdown = ({ field }) => {
    const {
        guid,
        hasDescription,
        description,
        prompt,
        value,
        hasHelperText,
        helperText,
        options,
        isRequired,
        required,
    } = field || {}
    const { onInputChange } = useForm()

    const currentError = useMemo(
        () => {
            // react-select will give us either null or an object
            if (isRequired && !value) {
                return ErrorMessages.required()
            }

            return null
        },
        [isRequired, value]
    )

    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    const onChange = (selectedOption) => {
        onInputChange(selectedOption, field)
    }

    return (
        <div className='simplicity-forms-field-group'>
            <label
                htmlFor={`simplicity-forms-dropdown-${guid}`}
                className='simplicity-forms-field-label'
            >
                {prompt}
                {!required && <span> (optional)</span>}
            </label>
            {hasDescription && description && (
                <span className='simplicity-forms-description'>{description}</span>
            )}
            {options && (
                <Select
                    className={`simplicity-forms-field-dropdown ${hasError ? 'form-field-error-input' : ''}`}
                    name={guid}
                    id={guid}
                    value={value}
                    openOnFocus
                    isClearable
                    placeholder='Please Select'
                    options={options}
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    fullWidth
                    onChange={onChange}
                />
            )}
            {hasError && (
                <ErrorMsg type='error' error={error} />
            )}
            {hasHelperText && helperText && (
                <span className='simplicity-forms-helper-text'>{helperText}</span>
            )}
        </div>
    )
}

export { SimplicityFormDropdown }
