/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { useRouteError } from 'react-router-dom'
import { Header } from 'components/header'
import { exists, isNull, isObject, objectHasPropertyNumber } from 'cms/utils/empty-exists'
import { CmsConfig } from 'cms/config'
import { Logger } from 'cms/utils/logger'

const RouterError = () => {

    const error = useRouteError()

    const wasNull = isNull(error)
    const didExist = exists(error)
    const wasErrorObject = isObject(error) && objectHasPropertyNumber(error, 'status')
    const hasError = !wasNull && didExist && wasErrorObject
    let displayedError = ''
    if (hasError) {
        Logger.error('Router Error', error)
        // Logger.error(error.data)
        displayedError = hasError ? JSON.stringify(error, null, 2) : ''
    }

    return (
        <Fragment>
            <Header />
            <div>
                <div className='homepage-wrapper'>
                    <div style={{
                        width: '100%',
                        height: '50vh',
                        gap: '24px',
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '40px'
                    }}>
                        {/*<h1>{webpage?.nav_title}</h1>*/}
                        {/*<CircularProgress color='primary' />*/}

                        <h1>Sorry, we had a problem!</h1>
                        <p>An unexpected error has occurred.</p>
                        <p>
                            <i>{error.statusText || error.message}</i>
                        </p>
                        {/*<p>{error?.error?.message}</p>*/}
                        {CmsConfig.showErrors &&
                            <div style={{
                                border: '1px solid black',
                                borderRadius: '8px',
                                overflow: 'auto',
                                // maxWidth: '1200px',
                                width: '100%',
                                height: '600px',
                                padding: '40px'
                            }}>

                            <pre style={{width: '1000px'}}>
                                {displayedError}
                            </pre>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

RouterError.displayName = 'RouterError'
export { RouterError }
