/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { Logger } from 'cms/utils/logger'

export const getComponentFields = function (model, schema) {
    let results = []
    if (!schema) {
        return results
    }
    for (const field of schema) {
        // console.log(JSON.stringify(field))
        if (field.type === FIELD.COMPONENT) {
            const field_name = field.name
            const isList = field.list === true
            Logger.debug(`Field ${field_name} ${isList ? ' is a list of components.' : ' is a single component.'}`)
            results.push({ name: field_name, list: isList })
        }
    }
    return results
}

export const cloneObject = function (obj) {
    return { ...obj }
}

export const cloneArray = function (array) {
    const replacement = []
    if (array) {
        for (const item of array) {
            let component = JSON.parse(JSON.stringify(item))
            replacement.push({ ...component })
        }
    }
    return replacement
}

export const FIELD = {
    TEXT: 'field.text',
    MULTILINE_TEXT: 'field.multi-line-text',
    RICHTEXT: 'field.richtext',
    NUMBER: 'field.number',
    DECIMAL: 'field.decimal',
    CURRENCY: 'field.currency',
    LOCATION: 'field.location',
    DATE: 'field.date',
    TIME: 'field.time',
    DATETIME: 'field.datetime',
    TIMEZONE: 'field.timezone',
    BOOLEAN: 'field.boolean',
    URL: 'field.url',
    IMAGE: 'field.image',
    DOCUMENT: 'field.document',
    VIDEO: 'field.video',
    FILE: 'field.file',
    JSON: 'field.json',
    EMAIL: 'field.email',
    PHONE_NUMBER: 'field.phone-number',
    ADDRESS: 'field.address',
    ENUM: 'field.enum',
    COMPONENT: 'field.component',
    WEBPAGE: 'field.webpage-ref',
    MODEL: 'field.model-ref',
    MAP: 'field.map',
}
