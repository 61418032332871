/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import cn from 'clsx'
import { useNumericalConstraints } from 'cms/forms/hooks/use-numerical-constraints'
import { useNumericalValidator } from 'cms/forms/hooks/use-numerical-validator'
import { useEffect, useState } from 'react'

/**
 * @param {object} props
 * @param {NumericalSubQuestion} props.subQuestion
 * @param {boolean} props.isTouched
 * @param {number=} props.defaultValue
 * @param {(value: string) => void} props.onChange
 * @param {(error: string) => void} props.onError
 */
export function GridNumerical (props) {

    const { subQuestion, onChange, isTouched, onError, defaultValue } = props
    const {
        required,

        // min
        hasMinValue,
        minValue,

        // max
        hasMaxValue,
        maxValue,

        // decimals
        hasDemicalValue,
        demicalValue
    } = subQuestion

    const { min, max, decimals } = useNumericalConstraints(
        hasDemicalValue,
        demicalValue,
        hasMinValue,
        minValue,
        hasMaxValue,
        maxValue,
    )

    const [value, setValue] = useState(defaultValue)

    useEffect(
        () => {
            // ignore until we actually edit this input
            if (value !== undefined) {
                const numericalValue = Number(value)
                onChange(numericalValue)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value]
    )

    const error = useNumericalValidator(
        min,
        max,
        decimals,
        required,
        value
    )

    useEffect(
        () => {
            onError(error)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error]
    )

    const className = cn({
        'form-field-error-input': error && isTouched
    })

    return (
        <input
            className={className}
            type="number"
            defaultValue={defaultValue}
            min={min}
            max={max}
            onChange={(e) => setValue(e.target.value)}
        />
    )
}
