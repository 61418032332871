/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useState } from 'react'
import { SimpliCityAppContext } from './simplicity-app.context'
import { useFetchNavigation } from 'cms/hooks/use-fetch-navigation'
import { objectHasPropertyBoolean } from 'cms/utils/empty-exists'
// import { useSeo } from 'cms/hooks/use-seo'
import { prepareSeo } from 'cms/hooks/helpers/prepare-seo'

/**
 * @param {object} props
 * @param {any} props.website
 * @param {import("react").ReactNode} props.children
 * @returns
 */
const SimpliCityContextProvider = (props) => {

    const domain = window.location.host
    const path = window.location.pathname
    const {
        website,
        settings,
        children
    } = props

    // const { token } = useAuth()
    // const [website, setWebsite] = useState()
    // const [settings, setSettings] = useState()
    const [error, setError] = useState(null)
    const [webpage, setWebpage] = useState()
    const [model, setModel] = useState()
    const [seo, setSeo] = useState()
    const [navTree, setNavTree] = useState()
    // const [menu, setMenu] = useState()
    // const [levelOneData, setLevelOneData] = useState()
    // const [nav, setNav] = useState()
    // const [side, setSide] = useState()
    const [parents, setParents] = useState()
    const [bannerImage, setBannerImage] = useState()
    const { home } = website
    const { menu, levelOneData, nav, side } = useFetchNavigation(home, setError)
    const [hasModel, setHasModel] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState()
    const [errorWebpage, setErrorWebpage] = useState(null)
    const [errorModel, setErrorModel] = useState(null)
    const [errorFields, setErrorFields] = useState(null)
    const [hasErrorPage, setHasErrorPage] = useState(false)

    const hasModelFeature = (key) => {
        const { features } = model || {}
        return objectHasPropertyBoolean(features, key)
    }

    /** @type {SimpliCityAppContext} */
    const appContext = {
        website,
        home,
        domain,
        path,
        webpage,
        setWebpage,
        seo,
        model,
        setModel,
        hasModel,
        setHasModel,
        loading,
        setLoading,
        fields,
        setFields,
        error,
        setError,
        settings,
        hasModelFeature,
        menu,
        levelOneData,
        nav,
        side,
        errorWebpage,
        errorModel,
        errorFields,
        hasErrorPage,
        setErrorWebpage,
        setErrorModel,
        setHasErrorPage,
        setErrorFields,

        setNavTree,
        setSeo,
        setParents,
        bannerImage,
        setBannerImage,
        // setMenu,
        // setLevelOneData,
        // setNav,
        // setSide,
        navTree,
        parents,
    }

    return (
        <SimpliCityAppContext.Provider value={appContext}>
            <div className='simplicity-root'>
                {children}
            </div>
        </SimpliCityAppContext.Provider>
    )
}

export { SimpliCityContextProvider }
