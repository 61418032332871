/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { Menu } from 'cms/shared/page-nav/menu'
import { usePageNav } from './use-page-nav'

const PageNav = (props) => {
    const { webpage } = props;
    const { guid } = webpage;

    const {
        // error,
        // sectionGuid,
        sectionTree,
        selectedSections,
        openMenus,
        setOpenMenus,
    } = usePageNav(guid);

    const { children: treeChildren, nav_title } = sectionTree || {};

    return (
        <div className="side-nav">
            <h2>{nav_title}</h2>
            <div className="title-underline"></div>
            <Menu
                tree={treeChildren}
                currentPageGuid={guid}
                openMenus={openMenus}
                setOpenMenus={setOpenMenus}
                selectedSections={selectedSections}
                // parent={parent}
            />
        </div>
    );
};

export { PageNav };
