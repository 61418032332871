/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import SVGIcons from 'cms/shared/SVGIcons'
import React from 'react'

// const STYLES = {
// const styleOptions = [
// const FORMATS = {
// const formatOptions = [
const openInNamedWindow = (event, url) => {
    // const { url } = params || {}
    const id = `${Math.random().toString(16).slice(2)}`
    window.open(url, id, 'popup')
}

const DOCUMENT_STYLES = {
    BODY: 'field.style.body',
    HEADING_ONE: 'field.style.h1',
    HEADING_TWO: 'field.style.h2',
    HEADING_THREE: 'field.style.h3',
    // HEADING_FOUR: 'field.style.h4',
    // HEADING_FIVE: 'field.style.h5',
    // HEADING_SIX: 'field.style.h6',
}
const DOCUMENT_STYLE_OPTIONS = [
    { name: 'Body', value: DOCUMENT_STYLES.BODY },
    { name: 'Heading 1', value: DOCUMENT_STYLES.HEADING_ONE },
    { name: 'Heading 2', value: DOCUMENT_STYLES.HEADING_TWO },
    { name: 'Heading 3', value: DOCUMENT_STYLES.HEADING_THREE },
    // { name: 'Heading 4', value: STYLES.HEADING_FOUR },
    // { name: 'Heading 5', value: STYLES.HEADING_FIVE },
    // { name: 'Heading 6', value: STYLES.HEADING_SIX },
]
const DOCUMENT_FORMATS = {
    TITLE: 'field.format.document-title',
    TITLE_SIZE: 'field.format.document-title-size'
}
const DOCUMENT_FORMAT_OPTIONS = [
    { name: 'Title', value: DOCUMENT_FORMATS.TITLE },
    { name: 'Title & Size', value: DOCUMENT_FORMATS.TITLE_SIZE },
]
const DOCUMENT_LIST_TYPES = {
    ICON: 'field.list.document-icon',
    BULLET: 'field.list.document-bullet',
    SIMPLE: 'field.list.document-simple',
    ACCORDION: 'field.list.document-accordion',
}
const DOCUMENT_LIST_STYLE_OPTIONS = [
    { name: 'Icon List', value: DOCUMENT_LIST_TYPES.ICON },
    { name: 'Bullet List', value: DOCUMENT_LIST_TYPES.BULLET },
    { name: 'Simple List', value: DOCUMENT_LIST_TYPES.SIMPLE },
    { name: 'Accordion List', value: DOCUMENT_LIST_TYPES.ACCORDION },
]
const TARGETS = {
    TAB: { name: 'tab', target: '_blank', handler: undefined },
    WINDOW: { name: 'window', target: '_blank', handler: openInNamedWindow },
    SELF: { name: 'self', target: '_self', handler: undefined },
}
const DOCUMENT_TARGETS = {
    NEW: '_blank',
    SELF: '_self',
    PARENT: '_parent',
    TOP: '_top',
    DOWNLOAD: '_self', // FIXME: this isn't really a target type
}
const DOCUMENT_TARGET_OPTIONS = [
    { name: 'Open in a new tab', value: DOCUMENT_TARGETS.NEW },
    { name: 'Open in this tab', value: DOCUMENT_TARGETS.SELF },
    { name: 'Start download', value: DOCUMENT_TARGETS.DOWNLOAD },
]
const getSVGIconForType = (type) => {
    switch (type) {
        case 'application/pdf':
            return <span style={{fill: '#e02214'}}><SVGIcons.files.PdfFile/></span>
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <span style={{fill: '#0255db'}}><SVGIcons.files.WordFile/></span>
        case 'application/msword':
            return <span style={{fill: '#0255db'}}><SVGIcons.files.WordFile/></span>
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <span style={{fill: '#1f5e23'}}><SVGIcons.files.ExcelFile/></span>
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <span style={{fill: '#D04423'}}><SVGIcons.files.PowerpointFile/></span>
        case 'text/plain':
            return <span style={{fill: '#454545'}}><SVGIcons.files.TextLinesFile/></span>
        case 'text/csv':
            return <span style={{fill: '#222222'}}><SVGIcons.files.CsvFile/></span>
        case 'text/xml':
            return <span style={{fill: '#c9e817'}}><SVGIcons.files.XmlFile/></span>
        case 'application/x-zip-compressed':
            return <span style={{fill: '#ebd96a'}}><SVGIcons.files.ZipFile/></span>
        case 'application/json':
            return <span style={{fill: '#c9e817'}}><SVGIcons.files.CodeFile/></span>
        case 'text/calendar':
            return <span style={{fill: '#bf2a1d'}}><SVGIcons.files.TextLinesFile/></span>
        case 'image/png':
            return <span style={{fill: '#d10dd1'}}><SVGIcons.files.ImageFile/></span>
        case 'image/jpeg':
            return <span style={{fill: '#0404b8'}}><SVGIcons.files.ImageFile/></span>
        case 'video/mp4':
            return <span style={{fill: '#8c0456'}}><SVGIcons.files.VideoFile/></span>
        case 'video/quicktime':
            return <span style={{fill: '#09aee6'}}><SVGIcons.files.VideoFile/></span>
        case 'image/svg+xml':
            return <span style={{fill: '#5906bf'}}><SVGIcons.files.ImageFile/></span>
        default:
            return <span style={{fill: '#565656'}}><SVGIcons.files.QuestionFile/></span>
    }
}

const getSVGIconForFile = (file) => {
    return getSVGIconForType(file.type)
}
const getSVGIconForDocument = (document) => {
    return getSVGIconForType(document.file_type)
}

const readTarget = (options) => {
    const { openIn } = options || {}
    switch (openIn) {
        case TARGETS.TAB.name:
            return TARGETS.TAB
        case TARGETS.WINDOW.name:
            return TARGETS.WINDOW
        case TARGETS.SELF.name:
            return TARGETS.SELF
        default:
            return TARGETS.TAB
    }
}

function formatBytes (bytes, decimals = 1) {
    if (!+bytes) {
        return '0 Bytes'
    }
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export {
    getSVGIconForFile,
    getSVGIconForDocument,
    formatBytes,
    readTarget,
    DOCUMENT_LIST_TYPES,
    DOCUMENT_LIST_STYLE_OPTIONS,
    DOCUMENT_STYLES,
    DOCUMENT_STYLE_OPTIONS,
    DOCUMENT_FORMATS,
    DOCUMENT_FORMAT_OPTIONS,
    DOCUMENT_TARGETS,
    TARGETS,
    DOCUMENT_TARGET_OPTIONS
}


// const image_sample = {
//     id: 228,
//     guid: '1afb8ea8-b798-43fe-8e51-6a7cc8d06b78',
//     organization: '229dc6b8-b28d-459f-881e-f699cf7f3a1a',
//     title: 'joel-vodell-TApAkERW5pQ-unsplash.jpg',
//     description: null,
//     storage_account: 'simplicityappimages',
//     storage_container: 'images',
//     storage_filepath: 'joel-vodell-TApAkERW5pQ-unsplash.jpg',
//     storage_url: 'https://simplicityappimages.blob.core.windows.net/images/joel-vodell-TApAkERW5pQ-unsplash.jpg',
//     url: 'https://images-dnv.simplicitycms.ca/images/joel-vodell-TApAkERW5pQ-unsplash.jpg',
//     file_name: 'joel-vodell-TApAkERW5pQ-unsplash.jpg',
//     file_ext: 'jpg',
//     file_type: 'image/jpeg',
//     file_size: 3121975,
//     width: 680,
//     height: 400,
//     updated_at: '2022-10-13 20:12:59',
//     updated_by: '852725ec-ac64-452b-be05-e3b8f123a5bf',
//     created_at: '2022-10-13 20:12:59',
//     created_by: '852725ec-ac64-452b-be05-e3b8f123a5bf',
// }
