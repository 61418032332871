/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { useFetchWebsite } from 'cms/hooks/use-fetch-website'

const LoadWebsite = (props) => {

    // run hook
    useFetchWebsite(props)

    return (<></>)
}

LoadWebsite.displayName = 'LoadWebsite'
export { LoadWebsite }
