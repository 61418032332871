/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

/**
 * @param {boolean} isEnabled
 * @param {string} action
 */
const useGetRecaptchaToken = (isEnabled, action) => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const getRecaptchaToken = useCallback(async () => {
        if (!isEnabled) {
            return
        }

        if (!executeRecaptcha) {
            throw new Error('executeRecaptcha is not available')
        }

        const recaptchaToken = await executeRecaptcha(action)
        return recaptchaToken
    }, [executeRecaptcha, isEnabled, action])

    return { getRecaptchaToken }
}

export { useGetRecaptchaToken }
