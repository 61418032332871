/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @type {SnapshotComponent<{ label: string }>}
 */
const DropdownSnapshot = (props) => {
    const { value } = props

    return (
        <>{value.label}</>
    )
}

export { DropdownSnapshot }
