/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useFormContext } from './use-form-context'

const useFormState = () => {
    const context = useFormContext()
    return context.state
}

export { useFormState }
