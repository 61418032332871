/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Logger } from 'cms/utils/logger'

/**
 * Append other options to options
 *
 * @template {Record<string, unknown>} [T=QuestionOption]
 * @param {string} guid
 * @param {boolean} hasOtherOptions
 * @param {T[]} initialOptions
 * @param {string} otherOptions
 * @param {(id: string) => T} additionalFields
 */
const optionTransformer = (
    guid,
    hasOtherOptions,
    initialOptions,
    otherOptions,
    additionalFields = () => {}
) => {

    // prevent duplicate loading from upstream
    const wasAlreadyTransformed = initialOptions.some(
        (option) => option.isOther
    )

    if (!hasOtherOptions || wasAlreadyTransformed) {
        return initialOptions
    }

    const numDefinedOptions = initialOptions.length
    const numOtherOptions = Number(otherOptions)

    // prevent a bad value from breaking this form
    if (isNaN(numOtherOptions)) {
        Logger.warn('[optionTransformer] otherOptions is not a valid number')
        return initialOptions
    }

    const userDefinedOptions = [...Array(numOtherOptions).keys()]
        .map((x) => {
            const otherPosition = x + 1
            const rowPosition = numDefinedOptions + otherPosition

            const id = `other-${guid}-${rowPosition}`

            /**
             * @type {T}
             */
            const otherOption = {
                ...additionalFields(id),
                id,
                label: `Other ${otherPosition}`,
                isOther: true,
                position: rowPosition,
                rules: [],
            }

            return otherOption
        })

    const combinedOptions = initialOptions
        .concat(userDefinedOptions)

    return combinedOptions
}

export { optionTransformer }
