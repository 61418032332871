/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useForm } from 'cms/forms/context'
import { useInitialValue } from 'cms/hooks/use-initial-value'
import { set, unset } from 'lodash'
import { useRef } from 'react'

/**
 * @param {FormQuestionGrid} question
 */
export function useGridValues (question) {

    const { value } = question
    const { onInputChange } = useForm()

    const initialValue = useInitialValue(value)

    // We're using refs here as an intermediary data store.
    // This allows us to perform mutations without worrying about poisoning
    // future/current React renders.  After mutation, we immediately call
    // an upstream function for the change to take effect (render).
    const gridValues = useRef(initialValue)

    function updateFormValue () {
        const clonedValues = structuredClone(gridValues.current)
        onInputChange(clonedValues, question)
    }

    /**
     * @param {string} rowId - The option ID
     * @param {string} columnId - The column ID
     * @param {any} gridItemValue
     */
    function onChange (rowId, columnId, gridItemValue) {
        set(gridValues.current, [rowId, columnId], gridItemValue)
        updateFormValue()
    }

    /**
     * @param {string} rowId
     */
    function removeRow (rowId) {
        unset(gridValues.current, [rowId])
        updateFormValue()
    }

    return {
        onChange,
        removeRow,
        gridValues,
        updateFormValue,
    }
}
