/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Logger } from 'cms/utils/logger'
import { questionConfig as config } from './question-config'
import { SimplicityFormAddress } from './questions/address'
import { SimplicityFormCheckbox } from './questions/checkbox'
import { SimplicityFormDateTime } from './questions/date-time'
import { SimplicityFormDropdown } from './questions/dropdown'
import { SimplicityFormEmail } from './questions/email'
import { FileUpload } from './questions/file-upload'
import { SimplicityFormGrid } from './questions/grid'
import { SimplicityFormGroup } from './questions/group'
import { SimplicityFormHeading } from './questions/heading'
import { SimplicityFormInfoBox } from './questions/info-box'
import { SimplicityFormLocation } from './questions/location'
import { SimplicityFormLongAnswer } from './questions/long-answer'
import { SimplicityFormMultipleChoice } from './questions/multiple-choice'
import { SimplicityFormName } from './questions/name'
import { SimplicityFormNumeric } from './questions/numeric'
import { SimplicityFormOpinionScale } from './questions/opinion-scale'
import { SimplicityFormParagraph } from './questions/paragraph'
import { SimplicityFormPhoneNumber } from './questions/phone-number'
import { SimplicityFormRating } from './questions/rating'
import { SimplicityFormShortAnswer } from './questions/short-answer'
import { SimplicityFormWebsiteUrl } from './questions/website-url'
import { SimplicityFormYesNo } from './questions/yes-no'

// transformers
import {
    addressSchemaTransformer,
    checkboxSchemaTransformer,
    fileUploadSchemaTransformer,
    gridSchemaTransformer,
    multipleChoiceSchemaTransformer,
    opinionScaleSchemaTransformer,
} from './transformers/schema'
import {
    checkboxValueTransformer,
    dateTimeValueTransformer,
    gridValueTransformer,
    locationValueTransformer,
    multipleChoiceValueTransformer,
    opinionScaleValueTransformer,
    ratingValueTransformer,
} from './transformers/value'


/**
 * @type {Record<QuestionTypes, QuestionConfig>}
 */
export const QuestionTypeMap = {
    // basic types
    'question.shortAnswer': config(SimplicityFormShortAnswer),
    'question.longAnswer': config(SimplicityFormLongAnswer),
    'question.multipleChoice': config(
        SimplicityFormMultipleChoice,
        multipleChoiceValueTransformer,
        multipleChoiceSchemaTransformer
    ),
    'question.dropdown': config(SimplicityFormDropdown),
    'question.checkbox': config(
        SimplicityFormCheckbox,
        checkboxValueTransformer,
        checkboxSchemaTransformer,
    ),
    'question.yesNo': config(SimplicityFormYesNo),

    // other selection
    'question.dateTime': config(
        SimplicityFormDateTime,
        dateTimeValueTransformer,
    ),
    'question.location': config(
        SimplicityFormLocation,
        locationValueTransformer,
    ),
    'question.address': config(SimplicityFormAddress, null, addressSchemaTransformer),
    'question.fileUpload': config(FileUpload, null, fileUploadSchemaTransformer),
    'question.rating': config(SimplicityFormRating, ratingValueTransformer),
    'question.opinionScale': config(
        SimplicityFormOpinionScale,
        opinionScaleValueTransformer,
        opinionScaleSchemaTransformer
    ),
    'question.grid': config(
        SimplicityFormGrid,
        gridValueTransformer,
        gridSchemaTransformer,
    ),

    // advanced text inputs
    'question.numericalValue': config(SimplicityFormNumeric),
    'question.name': config(SimplicityFormName),
    'question.email': config(SimplicityFormEmail),
    'question.phoneNumber': config(SimplicityFormPhoneNumber),
    'question.websiteUrl': config(SimplicityFormWebsiteUrl),

    // static content
    'question.heading': config(SimplicityFormHeading),
    'question.paragraph': config(SimplicityFormParagraph),
    'question.infoBox': config(SimplicityFormInfoBox),
    'question.group': config(SimplicityFormGroup),
}

/**
 * @param {QuestionTypes} type
 * @returns {QuestionComponent}
 */
export function getQuestionFormComponentByType (type) {
    const config = QuestionTypeMap[type]

    if (!config) {
        Logger.warn(`No component was found for type ${type}`)
    }

    return config?.component
}
