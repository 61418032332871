/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { InvalidContext } from 'cms/context/invalid-context.error'
import { useContext } from 'react'
import { FormContext } from '../form.context'

const useFormContext = () => {

    const context = useContext(FormContext)

    if (!context) {
        throw new InvalidContext('FormContext', 'FormProvider')
    }

    return context
}

export { useFormContext }
