/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isArraySingleValue } from 'cms/utils/empty-exists'
import { useMemo } from 'react'
import { useFormState } from '../context'

/**
 * @param {boolean} showReviewStep
 */
const useSteps = (showReviewStep) => {

    const { activeStep, schemaForm } = useFormState()
    const { questions = [], steps: initialSteps = [] } = schemaForm

    /**
     * @param {FormQuestion} question
     */
    const isActiveStep = (question) => {
        const { type, step } = question
        return type && step === activeStep
    }

    const hasSingleQuestionStep = isArraySingleValue(initialSteps)

    const steps = useMemo(
        () => {

            /**
             * @type {Step[]}
             */
            let combinedSteps = structuredClone(initialSteps)

            // hide the label if there is only a single question
            if (hasSingleQuestionStep) {
                combinedSteps[0].label = ''
            }

            // inject a review step if configured
            if (showReviewStep) {
                const step = initialSteps.length + 1
                combinedSteps.push({ step })
            }

            return combinedSteps

        },
        [hasSingleQuestionStep, initialSteps, showReviewStep]
    )

    const showStepper = isArraySingleValue(steps)

    const isFirstStep = activeStep === 1
    const isLastStep = activeStep === steps.length
    const showReview = isLastStep && showReviewStep

    const stepQuestions = useMemo(
        () => {
            const filteredQuestions = hasSingleQuestionStep ?
                questions :
                questions.filter(isActiveStep)

            return filteredQuestions
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeStep, questions]
    )


    return {
        steps,
        showStepper,
        hasSingleQuestionStep,
        stepQuestions,
        isFirstStep,
        isLastStep,
        showReview
    }
}

export { useSteps }
