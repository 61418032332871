/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import algoliasearch from 'algoliasearch/lite'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { history } from 'instantsearch.js/es/lib/routers'
import { useMemo } from 'react'

const routing = {
    router: history({
        cleanUrlOnDispose: false
    }),
}

/**
 * We want these values to persist after the hook is unmounted.
 */
export const algoliaCache = {
    /** @type {import('algoliasearch/lite').SearchClient} */
    client: null,
}

/**
 * @param {boolean} searchOnEmpty
 */
const useAlgolia = (searchOnEmpty = false) => {
    const { settings } = useSimpliCity()
    const { algolia_app_id, algolia_public_key, algolia_index } = settings

    const algoliaClient = useMemo(() => {

        if (!algoliaCache.client) {
            const algoliaClient = algoliasearch(
                algolia_app_id,
                algolia_public_key
            )

            // persist the base client
            algoliaCache.client = algoliaClient
        }

        const { client } = algoliaCache

        /**
         * This is the custom client for code that called this hook.
         * @type {import('algoliasearch').SearchClient}
         */
        const customClient = {
            ...client,
            search (requests, options) {

                const hasQueries = requests.some(
                    (request) => {
                        const query = request.query || request.params?.query
                        return !!query
                    }
                )

                // search if there's a query or we are allowed to search on empty
                if (hasQueries || searchOnEmpty) {
                    return client.search(requests, options)
                }

                // spoof the results
                return Promise.resolve({
                    results: requests.map(() => ({
                        hits: [],
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0,
                        hitsPerPage: 0,
                        exhaustiveNbHits: false,
                        query: '',
                        params: '',
                    })),
                });

            }
        }

        return customClient
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        algoliaClient,
        routing,
        searchIndex: algolia_index
    }
}

export { useAlgolia }
