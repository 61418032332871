/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "icons";
import { areChildrenHidden, checkSiblingGuids } from "components/header/mobile_menu/tree";
import { isArrayNotEmpty } from "cms/utils/empty-exists";

/**
 * @type {import('react').FC<MenuItemProps>}
 */
const MenuItem = (props) => {
    const { children, openMenus, setOpenMenus, item, guid, siblings } = props;
    const { children: itemChildren, path, nav_title } = item || {};

    const [parentsFlag, setParentsFlag] = useState(true);
    const [parents, setParents] = useState([]);
    // tracks parents of selected page to apply css
    // copied openMenus on load so it doesn't change as user interacts with menu
    if (parentsFlag) {
        setParents(openMenus);
        setParentsFlag(false);
    }

    const loc = useLocation();
    const isActive = loc.pathname === path;

    const isOpen = openMenus.includes(guid);
    const icon = isOpen ? icons.solid.faMinus : icons.solid.faPlus;

    const toggleOpen = () => {
        const siblingGuid = checkSiblingGuids(openMenus, guid, siblings);

        setOpenMenus((prev) => {
            let prevOM = prev;
            if (siblingGuid.length > 0 && siblingGuid !== guid) {
                prevOM = prev.filter((i) => i !== siblingGuid);
            }
            const next = isOpen ? prevOM.filter((item) => item !== guid && item !== siblingGuid) : [...prevOM, guid];

            return next;
        });
    };

    const getClassNames = (cssClass) => {
        let cn = cssClass;
        if (isActive) {
            cn = cn + " active";
        }
        if (isOpen) {
            cn = cn + " open";
        }

        if (parents.includes(guid) && !isActive) {
            cn = cn + " active-parent";
        }

        return cn;
    };

    return (
        <li className={`${isArrayNotEmpty(itemChildren) ? "has-children" : ""}`}>
            <div className={getClassNames("menu-item")}>
                {isActive && (
                    <span className="active-icon">
                        <FontAwesomeIcon icon={icons.solid.faArrowRight} />
                    </span>
                )}
                <div className="menu-item-text">
                    <NavLink to={path} end>
                        {nav_title}
                    </NavLink>
                </div>
                <div>
                    {isArrayNotEmpty(itemChildren) && !areChildrenHidden(itemChildren) && (
                        <button onClick={toggleOpen}>
                            <FontAwesomeIcon icon={icon} />
                        </button>
                    )}
                </div>
            </div>
            <div>{children}</div>
        </li>
    );
};

MenuItem.displayName = "MenuItem";
export { MenuItem };

/**
 * @typedef {object} MenuItemProps
 * @property {string[]} openMenus
 * @property {import('react').Dispatch<import('react').SetStateAction<string[]>>} setOpenMenus
 */
