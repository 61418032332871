/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'
import { parentsToCrumbs } from 'cms/shared/breadcrumb/parents-to-crumbs'
import { Logger } from 'cms/utils/logger'
import { useSimpliCity } from 'cms/hooks/use-simplicity'

export const useFetchBreadcrumbs = (webpage,model) => {

    const { guid: webpage_guid } = webpage || {}
    const { type } = model || {}
    let { parents } = useSimpliCity()

    const defaultCrumbs = []

    const [crumbs, setCrumbs] = useState(defaultCrumbs)
    const [error, setError] = useState()

    async function fetchData (webpage_guid) {
        try {
            if (parents) {
                parents = parents.reverse()
                parents.pop() // remove self

                const crumbs = parentsToCrumbs(parents)
                Logger.debug(`breadcrumbs for ${webpage_guid} => ${JSON.stringify(crumbs)}`)
                setCrumbs(crumbs)
            }
        } catch (error) {
            Logger.error(error)
            setError(error)
        }
    }

    useEffect(() => {
        let data
        switch (type) {
            case 'model.news':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'News', path: '/news' }
                ]
                setCrumbs(data)
                break
            case 'model.notice':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'Advisories', path: '/advisories' }
                ]
                setCrumbs(data)
                break
            case 'model.alert':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'Alerts', path: '/alerts' }
                ]
                setCrumbs(data)
                break
            case 'model.story':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'Stories', path: '/dnv-stories' }
                ]
                setCrumbs(data)
                break
            case 'model.meeting':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'Events', path: '/events' }
                ]
                setCrumbs(data)
                break
            case 'model.event':
                data = [
                    { path: '/', title: 'Home' },
                    { title: 'Events', path: '/events' }
                ]
                setCrumbs(data)
                break
            default:
                fetchData(webpage_guid)
                break
        }

    }, [webpage_guid, type])

    return {
        crumbs,
        error
    }
}
