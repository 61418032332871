/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const FeedbackComponent = (props) => {

    const { component } = props
    const {primary_question } = component || {}

    return (
        <div className="page-feedback__block">
            <h2 className="page-feedback__title">{primary_question}</h2>
            <div className="page-feedback__options">
                <button className="btn" data-option="yes">
                    Yes
                </button>
                <button className="btn" data-option="no">
                    No
                </button>
            </div>
        </div>
    );
}

FeedbackComponent.displayName = 'FeedbackComponent'
export { FeedbackComponent }
