/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { NavigationItem } from './navigation_item'
// import { RichText } from 'cms/rich-text'
import { isArrayNotEmpty } from "cms/utils/empty-exists";

const NavigationListStyle = (props) => {

    const { children } = props
    // NOTE: NavigationListStyle does not display the description field

    return (
        <>
            <div className='navigation-list-body'>
                {isArrayNotEmpty(children) &&
                    children.map((item) => {
                        const { guid } = item
                        return (
                            <NavigationItem
                                key={`navigation-item-${guid}`}
                                item={item}
                            />
                        )
                    })}
            </div>
        </>
    )
}

NavigationListStyle.displayName = 'NavigationListStyle'
export { NavigationListStyle }
