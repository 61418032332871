/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import LinearProgress from '@mui/material/LinearProgress'
import { LoaderWrapper } from 'cms/auth/auth/components/loader-wrapper'
import { StageLogin } from './stage-login'
import { CmsConfig } from 'cms/config'

const TopEdgeLoader = () => {

    const isStage = CmsConfig.isStage

    return (
        <LoaderWrapper>
            <LinearProgress color="primary" />
            {isStage &&
                <StageLogin />
            }
        </LoaderWrapper>
    )
}

export { TopEdgeLoader }
