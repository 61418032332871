/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/** @type {import("@tiptap/core").Attribute} */
const className = {
    default: null,
    parseHTML: (element) => {
        // Unsure of how classes are parsed; the types suggest className
        return element.class || element.className
    },
    renderHTML: (attributes ) => {
        return {
            class: `${attributes.class}`
        }
    }
}

export { className }
