/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { TabItems } from 'cms/components/tab-group/tab_items'
import { TabNav } from 'cms/components/tab-group/tab_nav'
import { cloneArray } from 'cms/hooks/helpers/hooks-helpers'
import { useEffect, useState } from 'react'

const TabGroupComponent = (props) => {

    const { component } = props
    const { tabs: initialTabs } = component
    const [tabs, setTabs] = useState(initialTabs || [])
    const [initialized, setInitialized] = useState(false)

    const selectTab = function (event, guid) {
        event.preventDefault()
        const _tabs = cloneArray(tabs)
        _tabs.forEach((tab) => {
            tab.isVisible = tab.guid === guid
        })

        if (_tabs.every((tab) => tab.isVisible === false)) {
            _tabs[0].isVisible = true
        }
        setTabs(_tabs)
    }

    useEffect(() => {
        if (!initialized) {
            const _tabs = cloneArray(tabs)
            _tabs.forEach((tab, index) => {
                // true for the first tab, false otherwise
                tab.isVisible = index === 0
            })
            setTabs(_tabs)
            setInitialized(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='panel-group tabs' data-group-type='tabs'>
            <TabNav
                tabs={tabs}
                selectTab={selectTab}
            />
            <TabItems
                tabs={tabs}
            />
        </div>
    )
}

TabGroupComponent.displayName = 'TabGroupComponent'
export { TabGroupComponent }
