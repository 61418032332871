/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { RichText } from "cms/rich-text";
import { isArrayNotEmpty, isRichTextNotEmpty, isStringNotEmpty } from "cms/utils/empty-exists";
import { HighlightImage } from "./highlight-image";
import { HighlightLink } from "./highlight-link";

/*
 *   This is one highlight card used in a component field. The component field may have multiple components.
 */
const HighlightVertical = (props) => {
    const { image, title, description, links, style, format, settings } = props;

    return (
        <>
            {/* <div className="feed-component-header">
                <div className="feed-component-title-wrapper">
                    <h2 className="feed-component-title-link">{title}</h2>bbbb
                    <div className="feed-component-title-underline"></div>
                </div>
                <div className="feed-component-all-links"></div>
            </div> */}
            <div className={`simplicity-cta-container vertical`}>
                <HighlightImage image={image} imageCard={"vertical"} settings={settings} />

                <div className={`simplicity-cta-content vertical`}>
                    {isStringNotEmpty(title) && <div className="cta-title">{title}</div>}

                    {isRichTextNotEmpty(description) && (
                        <RichText className={isArrayNotEmpty(links) ? "truncate" : "overflow"} richtext={description} />
                    )}

                    {isArrayNotEmpty(links) &&
                        links.map((link, index) => {
                            return <HighlightLink key={index} link={link} format={format} style={style} />;
                        })}
                </div>
            </div>
        </>
    );
};

HighlightVertical.displayname = "HighlightVertical";
export { HighlightVertical };
