/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import SimpliCityFormattedImage from "cms/shared/images/formatted-image-fetch"

function ImageComponent (props) {

    const { component } = props
    const {image, settings} = component || {}

    return (
        <>
            <SimpliCityFormattedImage
                image={image}
                settings={settings}
            />
        </>
    )
}

ImageComponent.displayName = 'ImageComponent'
export { ImageComponent }
