/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CarouselImage } from 'cms/components/hero-carousel/carousel-image'
import { RichTextSimple } from 'cms/rich-text/rich-text-simple'
import { regular } from 'icons'
const { faChevronLeft, faChevronRight } = regular

const HeroCarouselSlideHomepage = (props) => {

    const { slides, images, index, total, moveIndex } = props || {}
    const slide = slides[index]
    const slideImage = slide?.image
    const { title, description, link } = slide || {}
    const { text, url } = link || {}
    const image = Array.isArray(images) && images[index]
    let settings
    if (slideImage) {
        settings = slideImage?.settings
    }
    const previous = () => {
        moveIndex(-1)
    }
    const next = () => {
        moveIndex(1)
    }

    return (
        <Fragment>
            <div className="home-banner">

                <div className="home-banner-content">

                    <h1>{title}</h1>

                    {description &&
                        <div className='hero-description-wrapper'>
                            <RichTextSimple
                                richtext={description}
                                className="carousel-slide-description"
                            />
                        </div>
                    }

                    <div className="home-banner-links">

                        <Link to={url}>{text}</Link>

                        <div className="controls">
                            <div className="forward-back">
                                <button aria-label="Previous" onClick={previous}>
                                    <FontAwesomeIcon icon={ faChevronLeft } />
                                </button>

                                <span>{`${index + 1}/${total}`}</span>

                                <button aria-label="Next" onClick={next}>
                                    <FontAwesomeIcon icon={ faChevronRight } />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <CarouselImage
                    image={image}
                    slide={slide}
                    settings={settings}
                    isHomepage={true}
                />

            </div>
        </Fragment>
    )
}

HeroCarouselSlideHomepage.displayName = 'HeroCarouselSlideHomepage'
export { HeroCarouselSlideHomepage }
