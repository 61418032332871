/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { RichText } from 'cms/rich-text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from 'icons'
const { faMessage, faInfo } = solid

const InfoBoxComponent = (props) => {
    const { component } = props || {}
    const { richtext } = component || {}

    return (
        <>
            <div className="simplicity-info-box" role="region" aria-labelledby="information-block-title">
                <div className="simplicity-info-box-icon">
                    <span className='fa-layers fa-fw fa-lg'>
                        <FontAwesomeIcon
                            icon={faMessage}
                            transform={'grow-6'}
                        />
                        <FontAwesomeIcon
                            icon={faInfo}
                            transform={'shrink-4 up-3'}
                            className={'font-awesome-icon-layer'}
                        />
                    </span>
                </div>
                <div className="simplicity-info-box-content">
                    <RichText richtext={richtext} />
                </div>
            </div>
        </>
    );
};

InfoBoxComponent.displayName = "InfoBoxComponent"
export { InfoBoxComponent }
