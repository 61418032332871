/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { icons } from 'icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
// import { faArrowRight, faMinus, faPlus } from 'icons'

export function Menu (props) {
    const { tree, level = 1, currentPageGuid, setOpenMenus, openMenus, selectedSections } = props
    const [parentsFlag, setParentsFlag] = useState(true)
    const [parents, setParents] = useState([])

    const nextLevel = level + 1
    // const isWithinAncestors = openMenus?.includes(currentPageGuid)
    // const parent = tree ? tree[0]?.parent : undefined
    const isParentGuid = tree ? openMenus?.includes(tree[0]?.parent) : false

    // tracks parents of selected page to apply css
    // copied openMenus on load so it doesn't change as user interacts with menu
    if (parentsFlag && openMenus?.length > 0) {
        setParents(openMenus)
        setParentsFlag(false)
    }

    const toggleMenu = (guid) => {
        if (!openMenus) {
            return
        }
        if (openMenus.includes(guid)) {
            // close menu
            setOpenMenus(openMenus.filter((e) => e !== guid))
        } else {
            // check if there's a sibling open, if so close it
            const sib = checkSiblingGuids(guid, tree)

            if (sib?.length > 0) {
                const temp = Object.assign(
                    [],
                    openMenus.filter((e) => e !== sib)
                )
                setOpenMenus([...temp, guid])
            } else {
                setOpenMenus([...openMenus, guid])
            }
        }
    };

    const checkSiblingGuids = (guid, tree) => {
        let omRemove = ""
        const isSameLevel = tree.filter((t) => t.guid === guid)
        if (isSameLevel.length > 0) {
            const treeGuids = tree.map((t) => t.guid)

            for (let g of treeGuids) {
                for (let o of openMenus) {
                    if (o === g) {
                        omRemove = g
                    }
                }
            }
        }
        // value that should be removed from openMenus
        return omRemove;
    }

    return (
        <ul className={`menu-level-${level} ${isParentGuid ? "open" : ""}`}>
            {tree?.map((treeNode, index) => {
                const { guid: nodeGuid, children: childTree, path, nav_title } = treeNode
                const hasChildren = isArrayNotEmpty(childTree)
                // isSelected means that this node in the tree matches the current webpage
                const isSelectedSection = selectedSections?.includes(nodeGuid)
                const isSelectedSectionStyle = isSelectedSection ? "selected-section" : ""
                // isOpen is true for each of the parent guids - but also is toggled in the UI
                const isOpen = openMenus?.includes(nodeGuid)
                const isOpenStyle = isOpen ? "active" : ""
                // isSelected means that this node in the tree matches the current webpage
                const isSelected = nodeGuid === currentPageGuid
                const isSelectedStyle = isSelected ? "selected" : ""
                const isParentStyle = parents.includes(nodeGuid) && nodeGuid !== currentPageGuid ? "active-parent" : ""

                return (
                    <li key={index}>
                        <div
                            className={`menu-item ${isSelectedSectionStyle} ${isOpenStyle} ${isParentStyle} ${isSelectedStyle}`}
                        >
                            {/* {level > 1 && ( */}
                            <div className={`selected-icon ${!isSelected && level === 1 ? "hidden" : ""}`}>
                                {isSelected && <FontAwesomeIcon icon={icons.solid.faArrowRight} />}
                            </div>
                            {/* )} */}

                            <div className={`title ${isSelectedStyle}`}>
                                <NavLink to={path}>{nav_title}</NavLink>
                                {hasChildren && (
                                    <div className={"expanded"}>
                                        {hasChildren && (
                                            <button onClick={() => toggleMenu(nodeGuid)}>
                                                <FontAwesomeIcon
                                                    icon={isOpen ? icons.solid.faMinus : icons.solid.faPlus}
                                                />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* {hasChildren && isOpen && ( */}
                        {hasChildren && (
                            <Menu
                                tree={childTree}
                                level={nextLevel}
                                currentPageGuid={currentPageGuid}
                                openMenus={openMenus}
                                setOpenMenus={setOpenMenus}
                            />
                        )}
                    </li>
                )
            })}
        </ul>
    )
}
