/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment, useEffect, useState } from 'react'

const SkipDuringPrerender = (props) => {

    const { children } = props

    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setHasMounted(true)
    }, [])

    if (!hasMounted) {
        return null
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export { SkipDuringPrerender }
