/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { CmsConfig } from 'cms/config'
// import { Logger } from 'cms/utils/logger'

const PreviewGuard = (props) => {

    const { children } = props
    const isPreview = CmsConfig.isPreview

    // Logger.info(`[isPreview : ${isPreview}]`)
    // console.log(`[isPreview : ${isPreview}]`)

    if (!isPreview) {
        return null
    }
    return (
        <>{children}</>
    )
}

export { PreviewGuard }
