/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync, useCounter } from 'react-use'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { isStringNotEmpty } from 'cms/utils/empty-exists'
import { CmsConfig } from 'cms/config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
dayjs.extend(utc)
dayjs.extend(customParseFormat)

const defaultPage = 1
const defaultPageSize = 10
const defaultPageSizeCalendar = 500
const defaultDays = 365*2
const defaultDaysCalendar = 365*2
const modeCalendar = 'calendar'
const modeList = 'list'

/**
 * @param selectedType
 * @param {string} categoryName
 * @param selectedCategory
 * @param {boolean} isCalender
 */
const useFetchEventFeed = (selectedType, categoryName, selectedCategory, isCalender) => {

    const { token } = useAuth()
    let selected_type
    if (selectedType !== 'all') {
        selected_type = selectedType
    }

    const [term, setTerm] = useState('')
    const [category, setCategory] = useState(selectedCategory ?? '')
    useEffect(() => {
        setCategory(selectedCategory)
    }, [selectedCategory])

    const [page, { inc, dec, reset, set }] = useCounter(defaultPage, null, 1)
    const pageSize = isCalender ? defaultPageSizeCalendar : defaultPageSize
    const days = isCalender ? defaultDaysCalendar : defaultDays
    const mode = isCalender ? modeCalendar : modeList

    const { loading, error, value } = useAsync(async () => {

        const options = { page, pageSize, term, days, mode, type: selected_type }

        if (category) {
            if (category !== '- Any -' && category !== 'All' ) {
                options.category = category
                options.categoryName = categoryName
            }
        }
        const { pagination, results } = await SimpliCitySDK.model.findEventFeed(token, options)
        const models = []
        for (const model of results) {
            const { title, path, guid, description } = model
            const { event } = model

            const modelEvent = {
                Id: guid,
                Subject: title || 'Untitled',
                Description: description || '',
                StartTime: convertStartDate(event),
                EndTime: convertEndDate(event),
                Path: path,
            }
            models.push(modelEvent)
        }
        return {
            models,
            pagination
        }
    }, [token, page, categoryName, term, category, isCalender])

    const search = useCallback(
        /**
         * @param {string} term
         * @param {string} category
         */
        (term, category) => {
            setTerm(term)
            setCategory(category)
            reset()

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    const paginationState = useMemo(() => {
        const total = value?.pagination?.total || 0
        const numPages = Math.ceil(total / pageSize)
        const hasPrev = page > 1
        const hasNext = page < numPages

        const first = ((page - 1) * pageSize) + 1
        const last = Math.min((page) * pageSize, total)
        const hasResults = total > 0

        return {
            first,
            hasNext,
            hasPrev,
            hasResults,
            last,
            numPages,
            page,
            pageSize,
            total,
        }
    }, [page, pageSize, value?.pagination?.total])

    const controls = {
        next: () => inc(),
        prev: () => dec(),
        to: set,
    }

    return {
        // User input
        search,

        // state
        loading,
        error,
        value,

        // pagination
        pagination: {
            controls,
            state: paginationState
        },
    }
}
const convertStartDate = (event) => {
    let start, parsed, converted
    try {
        const hasDate = isStringNotEmpty(event.StartDate)
        const hasTime = isStringNotEmpty(event.StartTime)
        if (hasDate && hasTime) {
            start = `${event.StartDate} ${event.StartTime}`
            parsed = dayjs.utc(start, CmsConfig.dates.internalFormats.event).local()
        } else if (hasDate) {
            start = `${event.StartDate}`
            parsed = dayjs.utc(start, CmsConfig.dates.internalFormats.date).local()
        } else if (hasTime) {
            start = `${event.StartTime}`
            parsed = dayjs.utc(start, CmsConfig.dates.internalFormats.time).local()
        }
        converted = parsed.toISOString()
        return converted
    } catch (err) {
        console.error(err)
        return start
    }
}

const convertEndDate = (event) => {
    let end, parsed, converted
    try {
        const hasDate = isStringNotEmpty(event.EndDate)
        const hasTime = isStringNotEmpty(event.EndTime)
        if (hasDate && hasTime) {
            end = `${event.EndDate} ${event.EndTime}`
            parsed = dayjs.utc(end, CmsConfig.dates.internalFormats.event).local()
        } else if (hasDate) {
            end = `${event.EndDate}`
            parsed = dayjs.utc(end, CmsConfig.dates.internalFormats.date).local()

        } else if (hasTime) {
            end = `${event.EndTime}`
            parsed = dayjs.utc(end, CmsConfig.dates.internalFormats.time).local()
        }
        converted = parsed.toISOString()
        return converted
    } catch (err) {
        console.error(err)
        return end
    }
}


export { useFetchEventFeed }
