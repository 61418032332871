/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import { useCallback, useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { CaptchaNotice } from './captcha-notice'
import { useForm, useFormState, useSubmitForm } from './context'
import { FormWrapper } from './form-wrapper'
import StatusBanner from './status-banner'
import Steps from './steps/steps'
import { useFormSettings } from './use-form-settings'
import { useGetRecaptchaToken } from './use-get-recaptcha-token'
import { Logger } from 'cms/utils/logger'

/**
 * @type {import('react').FC<FormProps>}
 */
const Form = (props) => {
    const { component } = props
    const {
        form_id,
        description,
    } = component || {}

    /**
     * @type {import('react').MutableRefObject<HTMLDivElement | null>}
     */
    const formRef = useRef(null)
    const submitForm = useSubmitForm()

    const {
        getCurrentForm,
        validate,
    } = useForm()

    const FormState = useFormState()
    const { schemaForm, resetCount } = FormState
    const { questions } = schemaForm

    // status represents the status of the form response success or failed
    // JSDoc needs this odd construction to work with a nullable type
    const [status, setStatus] = useState(/** @type {FormSubmitStatus | null} */(null))

    const { loading, error, value: formConfig } = useFormSettings(form_id)
    const { activeStep } = useFormState()
    const formProvided = !!form_id && !!activeStep

    const captchaEnabled = formConfig?.settings?.enable_captcha
    Logger.debug(`captchaEnabled: ${captchaEnabled}`)
    const { getRecaptchaToken } = useGetRecaptchaToken(
        captchaEnabled,
        'submit_form'
    )

    useUpdateEffect(() => {
        if (resetCount > 0) {
            scrollToFormTop()
            setStatus(null)
        }
    }, [resetCount])

    useEffect(() => {
        if (form_id) {
            getCurrentForm(form_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form_id])

    const scrollToFormTop = useCallback(() => {
        const element = formRef.current

        if (element) {
            const offset = -10
            const elementTop = element.getBoundingClientRect().top

            const top = elementTop + window.scrollY + offset
            window.scrollTo({
                top,
                behavior: 'smooth'
            })
        }
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()
        const isFormValid = validate(questions)

        if (isFormValid) {
            try {
                const recaptchaToken = await getRecaptchaToken()
                const hasToken = !!recaptchaToken
                const start = hasToken ? recaptchaToken.slice(0,16) : ''
                Logger.debug(`recaptchaToken present: ${hasToken} ${start}`)
                await submitForm(recaptchaToken)
                setStatus('success')
            } catch (error) {
                Logger.error(error)
                setStatus('error')
            }
        }

        scrollToFormTop()
    }

    // show loading state
    if (loading || FormState.loading) {
        return null
    }

    // TODO: handle error state
    if (error) {
        Logger.error(error)
        return null
    }

    const {
        active_status,
        closed_message,
        enable_closed_banner,
        enable_failed_description,
        enable_success_description,
        failed_title,
        success_title,
        enable_review_step,
        review_title,
        enable_captcha
    } = formConfig.settings

    const { closed, submit, failed, review } = formConfig.excerpts

    const form_closed = active_status === "closed"

    const form_response_success = status === 'success'
    const form_response_error = status === 'error'

    if (!formProvided) {
        return null
    }
    // show form closed
    if (form_closed) {
        return (
            <StatusBanner
                status="caution"
                title={closed_message}
                message={enable_closed_banner && closed}
            />
        )
    }

    // show success
    if (form_response_success) {
        return (
            <FormWrapper ref={formRef} description={description}>
                <StatusBanner
                    message={enable_success_description && submit}
                    status={status}
                    title={success_title}
                />
            </FormWrapper>
        )
    }

    // show error
    if (form_response_error) {
        return (
            <FormWrapper ref={formRef} description={description}>
                <StatusBanner
                    message={enable_failed_description && failed}
                    status={status}
                    title={failed_title}
                />
            </FormWrapper>
        )
    }

    // show steps
    return (
        <FormWrapper ref={formRef} description={description}>
            <form style={{ marginTop: 48 }} onSubmit={handleSubmit}>
                {/* All questions are resolved via steps */}
                <Steps
                    description={review}
                    showReviewStep={enable_review_step}
                    title={review_title}
                    onNavigate={scrollToFormTop}
                />
            </form>
            {enable_captcha && <CaptchaNotice />}
        </FormWrapper>
    )
}

export { Form }


/**
 * @typedef {object} FormProps
 * @property {object} component
 * @property {string} component.form_id
 * @property {import('@tiptap/core').JSONContent=} component.description
 */
