/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from "react";
import { LiveAlertBanner } from './LiveAlertBanner'
import { PreviewAlertBanner } from './PreviewAlertBanner'
import { useAlerts } from 'cms/hooks/use-alerts'
import { useNavigate } from 'react-router-dom'
import { CmsConfig } from 'cms/config'

const AlertBanner = () => {

    const isPreview = CmsConfig.isPreview
    const [showBanner, setShowBanner] = useState(false)
    const [started, setStarted] = useState(false)

    const navigate = useNavigate()
    const { alert, loadAlerts } = useAlerts()
    // TODO: change API to provide one answer suitable for the env
    // const { enabled } = alert || {}
    let { enabled, enabled_live, enabled_preview } = alert || {}
    if (alert && !enabled) {
        enabled = isPreview ? enabled_preview : enabled_live
    }

    const handleCloseBanner = () => {
        sessionStorage.setItem('hideAlertBanner', 'true')
        setShowBanner(false)
    }
    const onClickBanner = (event, path) => {
        navigate(path)
    }

    useEffect(() => {
        if (!started) {
            setStarted(true)
            loadAlerts()
        }
    }, [started, loadAlerts])

    useEffect(() => {
        const hideBanner = sessionStorage.getItem('hideAlertBanner') === 'true'
        if (hideBanner) {
            setShowBanner(false)
            return
        }
        if (enabled) {
            setShowBanner(true)
        }
    }, [alert, enabled])

    useEffect(() => {
        const hideBanner = sessionStorage.getItem('hideAlertBanner')
        if (hideBanner) {
            setShowBanner(false)
        }
    }, [])

    return (
        <div>

            {!isPreview && alert &&
                <LiveAlertBanner
                    alert={alert}
                    enabled={enabled_live}
                    handleCloseBanner={handleCloseBanner}
                    showBanner={showBanner}
                    onClickBanner={onClickBanner}
                />
            }

            {isPreview && alert &&
                <PreviewAlertBanner
                    alert={alert}
                    enabled={enabled_preview}
                    handleCloseBanner={handleCloseBanner}
                    showBanner={showBanner}
                    onClickBanner={onClickBanner}
                />
            }

        </div>
    )
}

export { AlertBanner }
