/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @typedef {import("@tiptap/pm/model").Node} ProseMirrorNode
 * @typedef {import("prosemirror-model").DOMOutputSpec} DOMOutputSpec
 */

/**
 * Creates a colgroup element for a table node in ProseMirror.
 *
 * @param {ProseMirrorNode} node - The ProseMirror node representing the table.
 * @param {number} cellMinWidth - The minimum width of a cell in the table.
 * @param {number=} overrideCol - (Optional) The index of the column to override the width of.
 * @param {any=} overrideValue - (Optional) The width value to use for the overridden column.
 * @returns An object containing the colgroup element, the total width of the table, and the minimum width of the table.
 */
export function createColGroup (node, cellMinWidth, overrideCol, overrideValue) {
    let totalWidth = 0;
    let fixedWidth = true;

    /** @type {DOMOutputSpec[]} */
    const cols = [];
    const headerRow = node.firstChild;

    if (!headerRow) {
        return {};
    }

    for (let i = 0, col = 0; i < headerRow.childCount; i += 1) {
        const { colSpan, colwidth } = headerRow.child(i).attrs;

        for (let j = 0; j < colSpan; j += 1, col += 1) {
            const hasWidth =
                overrideCol === col ? overrideValue : colwidth && colwidth[j];
            const cssWidth = hasWidth ? `${hasWidth}px` : "";
            const isLastCol = col === headerRow.childCount - 1

            totalWidth += hasWidth || cellMinWidth;

            if (!hasWidth) {
                fixedWidth = false;
            }

            // ignore sizing on last column
            if (isLastCol) {
                cols.push(["col", {}])
            } else {
                cols.push(["col", cssWidth ? { style: `width: ${cssWidth}` } : {}]);
            }

        }
    }

    const tableWidth = fixedWidth ? `${totalWidth}px` : "";
    const tableMinWidth = fixedWidth ? "" : `${totalWidth}px`;

    /** @type {DOMOutputSpec[]} */
    const colgroup = ["colgroup", {}, ...cols];

    return { colgroup, tableWidth, tableMinWidth };
}
