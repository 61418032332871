/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from 'icons'
const { faChevronLeft, faChevronRight } = regular

const EmptyCarouselSlide = (props) => {

    const { isHomepage } = props
    const csstype = isHomepage ? 'home-banner-image' : 'carousel-image'

    return (
        <div className="home-banner">

            <div className="home-banner-content">

                <h1> </h1>

                <div className='hero-description-wrapper'/>

                <div className="controls">

                    <div className="forward-back">
                        <button aria-label='Previous' disabled>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </button>

                        <span>0/0</span>

                        <button aria-label='Next' disabled>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </button>
                    </div>
                </div>
            </div>
            <div
                className={csstype}
                style={{backgroundColor: '#989898'}}
            />
        </div>
    )
}

export { EmptyCarouselSlide }
