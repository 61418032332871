/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import DateIcon from 'cms/assets/icons/calendar-solid.svg'
import TimeIcon from 'cms/assets/icons/time-solid.svg'
import { useForm } from 'cms/forms/context'
import { ErrorMessages } from 'cms/forms/error-messages'
import ErrorMsg from 'cms/forms/error-msg'
import { useSetQuestionErrors } from 'cms/forms/hooks/use-set-question-errors'
import { useShowError } from 'cms/forms/hooks/use-show-error'
import { useMemo } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

/**
 * @type {QuestionComponent<FormQuestionDateTime>}
 */
const SimplicityFormDateTime = (props) => {

    const { field } = props || {}
    const {
        guid,
        prompt,
        value,
        hasDescription,
        description,
        hasHelperText,
        helperText,
        dateTimeType,
        isRequired,
    } = field || {}

    const { onInputChange } = useForm()

    const currentError = useMemo(
        () => {

            const isValidDate = new Date(value) > 0

            if (isRequired && !isValidDate) {
                return ErrorMessages.required()
            }

            return null
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRequired, value]
    )
    useSetQuestionErrors(guid, currentError)
    const { error, hasError } = useShowError(currentError, field)

    // FIXME: These are not standard formats - also maybe difficult to change this due to existing data
    const dateTimeFormat = {
        dateOnly: 'yyyy-MM-dd',
        timeOnly: 'h:mm a',
        dateTime: 'yyyy-MM-dd hh:mm',
    }

    const isDateTime = dateTimeType === 'dateTime'
    const isDateOnly = dateTimeType === 'dateOnly'
    const isTimeOnly = dateTimeType === 'timeOnly'

    const hasTime = isDateTime || isTimeOnly
    const hasDate = isDateTime || isDateOnly
    const icon = hasDate ?
        <img src={DateIcon} alt="dateTime" /> :
        <img src={TimeIcon} alt="Time" />

    return (
        <div className={`simplicity-forms-field-group date-time ${hasError ? 'error' : ''}`}>
            <label htmlFor='simplicity-forms-field-label' className='simplicity-forms-field-label'>
                {prompt}
                {!isRequired && <span> (optional)</span>}
            </label>
            {hasDescription && description &&
                <span className='simplicity-forms-description'>{description}</span>
            }
            <DatePicker
                showTimeSelect={hasTime}
                showTimeSelectOnly={isTimeOnly}
                id='simplicity-forms-dateTime'
                name='simplicity-forms-dateTime'
                selected={value}
                dateFormat={dateTimeFormat[dateTimeType]}
                onChange={(date) => onInputChange(date, field)}
                isClearable
                showIcon
                icon={icon}
            />
            {hasError && <ErrorMsg type='error' error={error} />}
            {hasHelperText && helperText &&
                <span className='simplicity-forms-helper-text'>{helperText}</span>
            }
        </div>
    )
}

export { SimplicityFormDateTime }
