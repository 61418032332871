/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { getAttributes, Mark, mergeAttributes, markPasteRule } from '@tiptap/core'
import { find, registerCustomProtocol, reset } from 'linkifyjs'
import { Plugin, PluginKey } from '@tiptap/pm/state'


function clickHandler (options) {
    return new Plugin({
        key: new PluginKey('handleClickLink'),
        props: {
            handleClick: (view, pos, event) => {
                var _a, _b;
                if (event.button !== 0) {
                    return false;
                }
                const eventTarget = event.target;
                if (eventTarget.nodeName !== 'A') {
                    return false;
                }
                const attrs = getAttributes(view.state, options.type.name);
                const link = event.target;
                const href = (_a = link === null || link === void 0 ? void 0 : link.href) !== null && _a !== void 0 ? _a : attrs.href;
                const target = (_b = link === null || link === void 0 ? void 0 : link.target) !== null && _b !== void 0 ? _b : attrs.target;
                if (link && href) {
                    if (view.editable) {
                        window.open(href, target);
                    }
                    return true;
                }
                return false;
            },
        },
    });
}


const SimpliCityDocLink = Mark.create({
    name: 'doclink',
    priority: 1000,
    keepOnSplit: false,
    onCreate () {
        this.options.protocols.forEach((protocol) => {
            if (typeof protocol === 'string') {
                registerCustomProtocol(protocol);
                return;
            }
            registerCustomProtocol(protocol.scheme, protocol.optionalSlashes);
        });
    },
    onDestroy () {
        reset();
    },
    inclusive () {
        return this.options.autolink;
    },
    addOptions () {
        return {
            openOnClick: true,
            linkOnPaste: true,
            autolink: true,
            protocols: [],
            HTMLAttributes: {
                guid: null,
                type: 'doc-link',
                external: true,
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
                class: null,
            },
            validate: undefined,
        };
    },
    addAttributes () {
        return {
            guid: {
                default: null,
            },
            href: {
                default: null,
            },
            url: {
                default: null,
            },
            type: {
                default: 'doclink',
            },
            text: {
                default: this.options.HTMLAttributes.text,
            },
            target: {
                default: this.options.HTMLAttributes.target,
            },
            rel: {
                default: this.options.HTMLAttributes.rel,
            },
            class: {
                default: this.options.HTMLAttributes.class,
            },
        };
    },
    parseHTML () {
        return [{ tag: 'a[href]:not([href *= "javascript:" i])' }];
    },
    renderHTML ({ HTMLAttributes }) {
        return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands () {
        return {
            setDocLink: (attributes) => ({ chain }) => {
                return chain().setMark(SimpliCityDocLink.name, attributes).setMeta('preventAutolink', true).run();
            },
            toggleDocLink: (attributes) => ({ chain }) => {
                return chain()
                .toggleMark(SimpliCityDocLink.name, attributes, { extendEmptyMarkRange: true })
                .setMeta('preventAutolink', true)
                .run();
            },
            unsetDocLink: () => ({ chain }) => {
                return chain()
                .unsetMark(SimpliCityDocLink.name, { extendEmptyMarkRange: true })
                .setMeta('preventAutolink', true)
                .run();
            },
        };
    },
    addPasteRules () {
        return [
            markPasteRule({
                find: (text) => find(text)
                .filter((link) => {
                    if (this.options.validate) {
                        return this.options.validate(link.value);
                    }
                    return true;
                })
                .filter((link) => link.isLink)
                .map((link) => ({
                    text: link.value,
                    index: link.start,
                    data: link,
                })),
                type: this.type,
                getAttributes: (match, pasteEvent) => {
                    var _a, _b;
                    const html = (_a = pasteEvent === null || pasteEvent === void 0 ? void 0 : pasteEvent.clipboardData) === null || _a === void 0 ? void 0 : _a.getData('text/html');
                    const hrefRegex = /href="([^"]*)"/;
                    const existingLink = html === null || html === void 0 ? void 0 : html.match(hrefRegex);
                    if (existingLink) {
                        return {
                            href: existingLink[1],
                            type: 'undetermined'
                        };
                    }
                    return {
                        href: (_b = match.data) === null || _b === void 0 ? void 0 : _b.href,
                        type: 'undetermined'
                    };
                },
            }),
        ];
    },
    addProseMirrorPlugins () {
        const plugins = [];
        if (this.options.openOnClick) {
            plugins.push(clickHandler({
                type: this.type,
            }));
        }
        return plugins;
    },
});

export { SimpliCityDocLink, SimpliCityDocLink as default }
