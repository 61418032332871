/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Fragment } from "react"
import { AccordionComponent } from './accordion'
import { CallToActionComponent } from './call-to-action'
import { DocumentListComponent } from './document-list'
import { EmbedComponent } from './embed'
import { WebComponentEmbedComponent } from './embed-webcomponent'
import { FeatureWebpageComponent } from './feature-webpage'
import { FeedbackComponent } from './feedback'
import { FormsComponent } from './forms'
import { HeadingComponent } from './heading'
import { InfoBoxComponent } from './info-box'
import { IconLinkListComponent } from './icon-link-list'
import { ImageComponent } from './image'
import { ImageGalleryComponent } from './image-gallery'
import { ImageListComponent } from './image-list'
import { LinkButtonComponent } from './link-button'
import { LinkListComponent } from './link-list'
import { NavigationListComponent } from './navigation-list'
import { QuoteComponent } from './quote'
import { TabGroupComponent } from './tab-group'
import { TocComponent } from './toc'
import { RichTextComponent } from './rich-text/rich-text'
import { RichTextWithImageComponent } from './rich-text-with-image'
import { YoutubeComponent } from './youtube'
import { FeedComponent } from './feed'
import { FeedComponentCalendar } from './calendar'
import { HeroCarousel } from './hero-carousel'
import { DividerComponent } from './divider'

export const getComponent = (component, field, webpage) => {
    const type = component?.type
    // console.log('ContentZoneDisplay type: ', type)

    switch (type) {
        case 'component.accordion':
            return <AccordionComponent component={component} field={field} />

        case 'component.call-to-action':
            return <CallToActionComponent component={component} field={field} />

        case 'component.divider':
            return <DividerComponent component={component} field={field} />

        case 'component.document-list':
            return <DocumentListComponent component={component} field={field} />

        case 'component.embed':
            return <EmbedComponent component={component} field={field} />

        case 'component.embed-web-component':
            return <WebComponentEmbedComponent component={component} field={field} />

        case 'component.feature-webpage':
            return <FeatureWebpageComponent component={component} field={field} />

        case 'component.feedback':
            return <FeedbackComponent component={component} field={field} />

        case 'component.feed-calendar':
            return <FeedComponentCalendar component={component} field={field} />

        case 'component.feed-model':
            return <FeedComponent component={component} field={field} />

        case 'component.form':
            return <FormsComponent component={component} field={field} />

        case 'component.hero-carousel':
            return <HeroCarousel component={component} field={field} />

        case 'component.heading':
            return <HeadingComponent component={component} field={field} />

        case 'component.icon-links-list':
            return <IconLinkListComponent component={component} field={field} />

        case 'component.info-box':
            return <InfoBoxComponent component={component} field={field} />

        case 'component.image':
            return <ImageComponent component={component} field={field} />

        case 'component.image-gallery':
            return <ImageGalleryComponent component={component} field={field} />

        case 'component.image-list':
            return <ImageListComponent component={component} field={field} />

        case 'component.link-button':
            return <LinkButtonComponent component={component} field={field} />

        case 'component.links-list':
            return <LinkListComponent component={component} field={field} />

        case 'component.quote':
            return <QuoteComponent component={component} field={field} />

        // rich-text-editor is the legacy (Quill) implementation
        case 'component.rich-text-editor':
        case 'component.rich-text':
            return <RichTextComponent component={component} field={field} />

        case 'component.rich-text-with-image':
            return <RichTextWithImageComponent component={component} field={field} />

        case 'component.tab-panel':
            return <TabGroupComponent component={component} field={field} />

        case 'component.table-of-contents':
            return <TocComponent component={component} webpage={webpage} field={field} />

        case 'component.navigation-list':
            return <NavigationListComponent component={component} webpage={webpage} field={field} />

        case 'component.youtube':
            return <YoutubeComponent component={component} field={field} />

        default:
            return <Fragment></Fragment>
    }
}
