/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Document from '../shared/document'
import { DOCUMENT_STYLES, DOCUMENT_TARGETS } from "cms/components/document-list/helpers";

const AccordionList = (props) => {

    const { component } = props || {}
    const { documents = [], title, listStyle, settings } = component || {}
    const {
        style = DOCUMENT_STYLES.BODY,
        format = DOCUMENT_TARGETS.NEW
    } = settings || {}
    const [expanded, setExpanded] = useState(false)
    let expandedIcon
    if (expanded) {
        expandedIcon = <RemoveIcon className='document-list-accordion-summary-icon'/>
    } else {
        expandedIcon = <AddIcon className='document-list-accordion-summary-icon'/>
    }

    return (
        <Fragment>

            <Accordion
                expanded={expanded}
                style={{ boxShadow: 'none' }}
                onChange={() => setExpanded(!expanded)}
                className={`document-list-accordion-root ${expanded ? `expanded` : ``}`}
            >
                <AccordionSummary
                    expandIcon={expandedIcon}
                    aria-controls='panel-accordion'
                    id='panel-accordion'
                    className={`document-list-accordion-summary list-heading ${listStyle} ${expanded ? `expanded` : ``}`}
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails className={`document-list-accordion-details ${expanded ? `expanded` : ``}`}>
                    <div className={`document-list-accordion-list ${listStyle}`}>
                        {documents.map((document, index) => (
                            <div className='document-list-accordion-row' key={index}>
                                <Document
                                    listStyle={listStyle}
                                    doc={document}
                                    style={style}
                                    format={format}
                                />
                            </div>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </Fragment>
    )
}

export default AccordionList
