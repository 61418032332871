/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { RichText } from 'cms/rich-text'
import { ReactComponent as Message } from 'cms/assets/icons/message-solid.svg'
import { ReactComponent as Info } from 'cms/assets/icons/circle-info-regular.svg'
import { ReactComponent as Help } from 'cms/assets/icons/circle-question-regular.svg'
import { ReactComponent as Warning } from 'cms/assets/icons/circle-warning-regular.svg'

/**
 * @type {QuestionComponent<FormQuestion<null>>}
 */
const SimplicityFormInfoBox = (props) => {

    const { field } = props || {}
    const { content, iconType } = field || {}

    return (
        <Fragment>
            {content && iconType &&
                <div className='simplicity-forms-info-box'>
                    {iconType === 'message' &&
                        <div className='iconBox'>
                            <Message alt='message' />
                        </div>
                    }
                    {iconType === 'information' &&
                        <div className='iconBox'>
                            <Info alt='information' />
                        </div>
                    }
                    {iconType === 'question' &&
                        <div className='iconBox'>
                            <Help alt='question' />
                        </div>
                    }
                    {iconType === 'warning' &&
                        <div className='iconBox'>
                            <Warning alt='warning' />
                        </div>
                    }
                    <RichText richtext={content} />
                </div>
            }
        </Fragment>
    )
}

export { SimplicityFormInfoBox }
