/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import StarIcon from '@mui/icons-material/Star'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from 'layout/homepage/useWindowSize'
import { useRef } from 'react'
import dayjs from 'dayjs'

const transform = (rawEvents) => {
    const events = []
    rawEvents.forEach((event) => {
        try {
            const _event = {
                id: event.Id,
                title: event.Subject,
                start: dayjs.utc(event.StartTime).local().toDate(),
                end: dayjs.utc(event.EndTime).local().toDate(),
                allDay: false,
                url: event.Path
            }
            events.push(_event)
        } catch (err) {
            console.error(err)
        }
    })
    return events
}

const EventCalendar = (props) => {

    const { events: rawEvents } = props
    const navigate = useNavigate()
    const events = isArrayNotEmpty(rawEvents) ? transform(rawEvents) : []
    const hasEvents = isArrayNotEmpty(events)
    const { isDesktop } = useWindowSize();
    const calendarRef = useRef(null);
    const containerRef = useRef(null);
    const yearViewType = 'multiMonthYear';
    const monthViewType = 'dayGridMonth';
    const weekViewType = 'listWeek';

    const onEventClick = (info) => {
        info.jsEvent.preventDefault();
        const url = info.event.url
        navigate(url)
    }

    const moreLinkContent = (args) => {
        const { num, text, view } = args;
        const { type } = view;
        const content = (
            <div className='more-link-content'>
                <span>{num}</span> <StarIcon />
            </div>
        )
        return (type === yearViewType) ? content : text
    }

    const changeCalendarView = (view, date) => {
        const currentCalendar = calendarRef.current;
        if (!!currentCalendar) {
            const calendarApi = currentCalendar.getApi();
            calendarApi.changeView(view, date);
        }
    }

    const handleDateClick = (info) => {
        const { date } = info;
        changeCalendarView(weekViewType, date);
        scrollToCalendarTop();
    }

    const scrollToCalendarTop = () => {
        const calendarContainer = containerRef.current;
        if (!!calendarContainer) {
            const offset = -10;
            const calendarTop = calendarContainer.getBoundingClientRect().top;
            const top = calendarTop + window.scrollY + offset;
            window.scrollTo({
                top,
                behavior: 'smooth'
            });
        }
    }

    return (
        <>
            {hasEvents &&
                <div
                    className='calendar-wrapper'
                    ref={containerRef}
                >
                    <FullCalendar
                        timeZone={'UTC'}
                        ref={calendarRef}
                        initialView={isDesktop ? monthViewType : weekViewType}
                        plugins={[dayGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin]}
                        headerToolbar={{
                            start: 'title',
                            center: 'prev,today,next',
                            end: 'multiMonthYear,dayGridMonth,listWeek',
                        }}
                        buttonText={{
                            today: 'Today',
                            multiMonthYear: 'Year',
                            dayGridMonth: 'Month',
                            week: 'Week',
                            listWeek: 'Week',
                        }}
                        footerToolbar={{
                            start: '',
                            center: '',
                            end: '',
                        }}
                        weekends={true}
                        firstDay={0}
                        eventDisplay={'block'}
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                        }}
                        aspectRatio={1}
                        contentHeight={'auto'}
                        events={events}
                        eventClick={onEventClick}
                        eventBorderColor={'#06577A'}
                        dayMaxEvents={2}
                        moreLinkContent={moreLinkContent}
                        dateClick={handleDateClick}
                    />
                </div>
            }
        </>
    )
}

export { EventCalendar }
