/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";
import { NavLink } from "react-router-dom";
import { FooterNeighbourhoods } from "./footer-column/neighbourhoods";
import { FooterContactUs } from "./footer-column/footer_contactus";
import { FooterSocial } from "./footer-column/footer_social";
// import { FooterLogo } from "./footer-column/footer_logo";

const Footer = () => {
    return (
        <>
            <div className="footer-land">
                <p>
                    We respectfully acknowledge the original peoples of these lands and waters, specifically the
                    səlilwətaɬ (Tsleil-Waututh), Sḵwx̱wú7mesh Úxwumixw (Squamish), and xʷməθkʷəy̓əm (Musqueam), on whose
                    unceded ancestral lands the District of North Vancouver is located. We value the opportunity to
                    learn, share, and serve our community on these unceded lands.&nbsp;
                    <NavLink
                        aria-label="Our journey towards reconciliation"
                        to="/community-environment/our-journey-towards-reconciliation">
                        Our journey towards reconciliation
                    </NavLink>
                    {/*<a href="/community-environment/our-journey-towards-reconciliation">*/}
                    {/*    Our journey towards reconciliation*/}
                    {/*</a>*/}
                </p>
            </div>
            <div id="footer" className="page-footer">
                <div className={"page-footer-content"}>
                    <FooterContactUs />

                    <FooterNeighbourhoods />

                    <FooterSocial />
                </div>
            </div>
            <div className={"page-copyright"}>
                <div className={"page-copyright-content"}>
                    <p>© {new Date().getFullYear()} Corporation of the District of North Vancouver</p>
                    <ul>
                        <li>
                            <a aria-label="SimpliCity CMS" href="https://www.simplicitycms.ca/">
                                Built with SimpliCity &trade;
                            </a>
                        </li>
                        <li>
                            <div>
                                <NavLink
                                    aria-label="Privacy Policy"
                                    to="/government-administration/our-privacy-statement"
                                >
                                    Privacy Policy
                                </NavLink>
                            </div>
                            <div>
                                <NavLink aria-label="Terms of use" to="/terms-use">
                                    Terms of use
                                </NavLink>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export { Footer };
