/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from "react"
import { ErrorMessages } from "../error-messages"

/**
 * Simple validator that checks array/string length
 * @param {boolean} isRequired
 * @param {string | any[]} value
 * @param {string=} errorMessage
 */
export function useRequiredValidator (
    isRequired,
    value,
    errorMessage
) {
    const error = useMemo(
        () => {
            const notEmpty = value?.length > 0
            const empty = !notEmpty
            if (isRequired && empty) {
                return errorMessage || ErrorMessages.required()
            }

            return null
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRequired, value]
    )

    return error
}
