/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

export const LINK_FORMATS = {
    TEXT: 'text-link',
    UNDERLINE: 'underline-link',
    BULLET_POINT: 'bullet-point-link',
    CTA: 'cta-link',
}
