/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'

const ScaledWidthImage = (props) => {

    const { image, settings } = props || {}
    const { width, height } = image || {}
    const { options } = settings || {}
    const {
        dimension = '100%',
        forceSixteenNine = false,
        contain = false
    } = options || {}
    const hasWidth = !!width
    const hasHeight = !!height
    let aspect_ratio, fit, maxWidth
    if (forceSixteenNine) {
        fit = 'contain'
        maxWidth = '100%'
        aspect_ratio = '16/9'
    } else if (contain) {
        fit = 'contain'
        maxWidth = '100%'
        const standardRatio = 16/9
        const currentRatio = width/height
        const isWider = currentRatio > standardRatio
        if (isWider) {
            aspect_ratio = currentRatio
        } else {
            aspect_ratio = standardRatio
        }
    } else if (hasWidth && hasHeight) {
        fit = 'contain'
        const longerSide = height >= width ? 'height' : 'width'
        maxWidth = '100%'
        aspect_ratio = (longerSide === 'height') ? `${height}/${width}` : `${width}/${height}`
    }

    return (
        <>
            {image &&
                <>
                    <img
                        src={image.url}
                        alt={image.alt}
                        width={dimension}
                        style={{
                            aspectRatio: aspect_ratio,
                            objectFit: fit,
                            maxWidth: maxWidth,
                        }}
                    />
                </>
            }
        </>
    )
}

export { ScaledWidthImage }
