/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { useContext } from 'react'
import { SimpliCityAuthContext } from 'cms/auth/auth/simplicity-auth-context'

const useAuth = () => {

    const context = useContext(SimpliCityAuthContext)

    if (!context) {
        throw new Error('context must be used inside provider')
    }

    return context
}

export { useAuth }
