/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Config } from 'config'
import { CmsConfig } from 'cms/config'

/**
 * Meta adds default metadata. Once a webpage is loaded this should be replaced with more specific results
 */
const Meta = () => {

    const url = Config.SEO.url || CmsConfig.SEO.url
    const website_title = Config.SEO.website_title || CmsConfig.SEO.website_title
    const description = Config.SEO.description || CmsConfig.SEO.description
    const google_site_verification = Config.google_site_verification || CmsConfig.google_site_verification
    const website_image = Config.SEO.website_image || CmsConfig.SEO.website_image
    const content_type = CmsConfig.SEO.content_type
    const robots_content = CmsConfig.SEO.robots_content
    const x_ua_compatible = CmsConfig.SEO.x_ua_compatible
    const viewport = CmsConfig.SEO.viewport

    return (
        <Fragment>
            <Helmet>
                {/* This defines defaults - most will be overridden deeper in */}
                <meta charSet="utf-8" />
                <title>{website_title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={url} />

                <meta name='robots' content={robots_content} />
                <meta name="google-site-verification" content={google_site_verification} />
                <meta httpEquiv="X-UA-Compatible" content={x_ua_compatible} />
                <meta httpEquiv="Content-Type" content={content_type} />
                <meta name="viewport" content={viewport} />

                <meta property="og:title" content={website_title} />
                <meta property="og:url" content={url} />
                <meta property="og:site_name" content={website_title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={website_image} />

                <meta name="twitter:card" content='summary_large_image' />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:title" content={website_title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={website_image} />

            </Helmet>
        </Fragment>
    )
}

export { Meta }
