/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */

import { useEffect, useState } from 'react'
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { isValidGuid } from '../utils/empty-exists'
// import { Logger } from 'cms/utils/logger'

const  useSimpliCityWebpageModelImage = (webpage_guid) => {

    // const { webpage_guid } = props || {}
    const { token } = useAuth()

    const [webpage,setWebpage] = useState()
    const [model,setModel] = useState()
    const [image,setImage] = useState()
    const [settings,setSettings] = useState()
    const [inProgress,setInProgress] = useState(false)
    const [loaded,setLoaded] = useState(false)

    useEffect(() => {

        const getWebpageModelAndImage = async (wp_guid) => {
            setInProgress(true)
            if (wp_guid && !image && !inProgress && !loaded) {
                const _webpage = await SimpliCitySDK.webpage.findOne(token, wp_guid)
                setWebpage(_webpage)
                const model_guid = _webpage?.model
                if (model_guid && !image && !loaded) {
                    const _model = await SimpliCitySDK.model.findOne(token, model_guid)
                    if (_model) {
                        setModel(_model)
                        const banner = _model?.banner_image?.image
                        const _settings = model?.banner_image?.settings
                        setSettings(_settings)
                        if (banner) {
                            const isGuid = isValidGuid(banner)
                            let _image
                            if (isGuid) {
                                _image = await SimpliCitySDK.images.findOne(token, banner)
                            } else {
                                _image = banner
                            }
                            setImage(_image)
                            _model.banner_image = {
                                image: _image,
                                settings: _settings,
                            }
                            setLoaded(true)
                            setInProgress(false)
                            return
                        } else {
                            setLoaded(true)
                        }
                        setInProgress(false)
                        return
                    }
                    setInProgress(false)
                    return
                }
                setInProgress(false)
                return
            }
            setInProgress(false)
            setLoaded(false)
        }
        getWebpageModelAndImage(webpage_guid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webpage_guid])

    return { model, webpage, image, settings, loaded }
}

export { useSimpliCityWebpageModelImage }
