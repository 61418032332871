import dayjs from "dayjs";
import { getPrimaryDate, hasPrimaryDate } from "cms/models/helpers";
import { Config } from "config";
import { Image } from 'cms/images/image'
import React from 'react'

const NewsResultItem = (props) => {
    const { model } = props;
    const { title, path } = model;
    const hasDate = hasPrimaryDate(model);
    let primary_date;
    if (hasDate) {
        const dateData = getPrimaryDate(model);
        const { date } = dateData || {};
        primary_date = date ? dayjs(date).format(Config.dateFormat.date_shortday) : undefined;
    }

    return (
        <div className="feed-result-item">
            <div className="field-content banner-image-style">
                <Image model={model} showCaption={false}/>
            </div>
            <div className="field-content title-date">
                <h3 className="title">
                    <a href={path}>{title}</a>
                </h3>
                {primary_date && <div className="field field-name-post-date">{primary_date}</div>}
            </div>
        </div>
    );
};

export { NewsResultItem };
