/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

import React from 'react'
import { RichText } from 'cms/rich-text'
import { useScrollToLocation } from 'cms/hooks/use-scroll-to-location'
import { defaultTOCRichTextObjectPM } from "cms/shared/toc/default_toc_rich_text"
import { useFetchTableOfContents } from './use-fetch-table-of-contents'

const TableOfContents = (props) => {

    const { richtext = defaultTOCRichTextObjectPM } = props

    // a utility to scroll on load, to anchors based on hash params in the URL
    useScrollToLocation()

    // Get chapters
    const { chapters, loading } = useFetchTableOfContents()

    return (
        <>
            {chapters && !loading &&
                <div id='top' className='table-of-contents'>
                    <RichText richtext={richtext} />
                    {chapters && chapters.map((item, index) => {
                        const {chapter, target} = item || {}
                        return (
                            <li key={`${target}${index}`}>
                                <a href={`#${target}`}>{chapter}</a>
                            </li>
                        )
                    })}
                </div>
            }
        </>
    )
}

TableOfContents.displayName = 'TableOfContents'
export { TableOfContents }
