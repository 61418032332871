/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import dropdownStyles from "./dropdown.module.css";
import cn from "clsx";

/**
 * Generate dropdown styles
 * @param {{ rightAligned?: boolean; size?: 'lg'}} options
 * @returns
 */
export function useDropdownStyles (options) {
    const { rightAligned = false, size } = options;

    /** @type {import("react-select").StylesConfig} */
    const styles = {
        menu: (base) => {
            return {
                ...base,
                // unset width to ensure CSS definition takes precedence
                width: undefined,
            };
        },
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? "0 0 0 1px #3D7737" : "none",
            borderColor: state.isFocused ? "#3D7737" : "#919191",
            cursor: "pointer",
        }),
    };

    /** @type {import('react-select').ClassNamesConfig} */
    const classNames = {
        control: () => dropdownStyles.control,
        menu: () =>
            cn(dropdownStyles.menu, {
                [dropdownStyles.lg]: size === "lg",
                [dropdownStyles.right]: rightAligned,
            }),
        placeholder: () => dropdownStyles.placeholder,
    };

    return {
        styles,
        classNames,
    };
}
