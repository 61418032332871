/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { atom } from "jotai"

/**
 * This should only ever be populated if we're on the /search page and we need to
 * force the full search to accept our given query
 */
export const searchQueryAtom = atom("")
