/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */

/**
 * @type {SnapshotComponent<string>}
 */
const TextSnapshot = (props) => {
    const { value } = props

    return (
        <div className="text-snapshot">
            {value}
        </div>
    )
}

export { TextSnapshot }
