/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useEffect, useState } from 'react'
import { useSearchBox } from 'react-instantsearch'
import { useNavigate } from 'react-router-dom'
import { useSearchUrl } from 'cms/search/hooks/use-search-url'
import { useDebouncedQuery } from './use-debounced-query'
import { useSaveSearchQuery } from 'cms/search/hooks/use-save-search-query'

/**
 * @param {boolean=} autoSearch
 * @param {boolean=} navigateOnSubmit
 */
export function useSearchInput (autoSearch = false, navigateOnSubmit = false) {
    const debouncedQuery = useDebouncedQuery()

    /** @type {import('react-instantsearch').UseSearchBoxProps} */
    const searchBoxProps = {}

    if (autoSearch) {
        searchBoxProps.queryHook = debouncedQuery
    }

    const { clear, query, refine } = useSearchBox(searchBoxProps)
    const [search, setSearch] = useState(query)

    useEffect(() => {
        if (autoSearch) {
            refine(search)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSearch, search])

    useEffect(() => {
        if (search !== query) {
            setSearch(query)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const navigate = useNavigate()
    const searchUrl = useSearchUrl()
    const saveSearchQuery = useSaveSearchQuery()

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    function onChange (event) {
        setSearch(event.target.value)
    }

    /**
     * @param {import('react').FormEvent<HTMLFormElement>} event
     */
    function onSubmit (event) {
        event.preventDefault()
        refine(search)

        if (navigateOnSubmit && search) {
            saveSearchQuery()
            navigate(searchUrl)
        }
    }

    return {
        clear,
        onChange,
        onSubmit,
        query,
        search,
    }
}
