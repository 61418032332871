/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { LargeFeedFilter } from "cms/shared/feeds/large";

const Alerts = () => {

    const type = 'model.alert'

    return (
        <main>
            <h1 style={{ marginTop: '16px' }}>Alerts</h1>

            <LargeFeedFilter
                type={type}
                enableBar={true}
            />

        </main>
    )
}

export { Alerts }
