/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { SimpliCitySDK } from 'cms/sdk'
import { useAuth } from 'cms/auth/auth/hooks/useAuth'
import { useSimpliCity } from 'cms/hooks/use-simplicity'
import { Logger } from 'cms/utils/logger'
import { isStringNotEmpty } from 'cms/utils/empty-exists'
// import {prepareSeo} from './helpers/prepare-seo'
// import { prepareNavigation } from './helpers/prepare-navigation'

const useFetchWebpage = () => {

    const { token } = useAuth()

    const {
        home,
        setWebpage,
        setModel,
        setHasModel,
        setFields,
        setLoading,
        setError,
        setNavTree,
        setSeo,
        setParents,
        setBannerImage,
        // setLevelOneData,
        // setMenu,
        // setNav,
        // setSide,

        // setErrorWebpage,
        // setErrorModel,
        // setErrorFields,
        // setHasErrorPage,
    } = useSimpliCity()

    /**
     * Retrieves webpage data at the given path
     * @param {string=} path
     */
    async function fetchWebpageDataComplete (path) {
        setLoading(true)
        setHasModel(false)
        setModel(null)

        // try {
        //      loadErrorPage() // no await
        // } catch (e) {
        //     Logger.error(e)
        // }
        try {
            let _webpage
            let data
            let encodedPath = encodeURIComponent(path)
            if (!path) {
                 encodedPath = encodeURIComponent('/')
            }
            if (typeof  path !== 'string') {
                Logger.warn(`[fetchWebpageData] Path was not a string ${path}`)
            }
            Logger.debug(`[fetchWebpageData] Loading webpage data: ${encodedPath}`)
            console.debug(`[fetchWebpageData] Loading webpage data: ${encodedPath}`)
            // _webpage = await SimpliCitySDK.webpage.findOneByPath(token, encodedPath)
            data = await SimpliCitySDK.webpage.findOneExpanded(token, encodedPath)
            Logger.debug(`[fetchWebpageData]`, { data })
            console.debug(`[fetchWebpageData]`, { data })

            // webpage
            _webpage = data?.webpage
            setWebpage(_webpage)

            // model
            const model = data?.model
            const banner_image = data?.banner_image
            setBannerImage(banner_image)
            const _seo = data?.seo
            setSeo(_seo)
            // const seo = prepareSeo(_webpage, model, _seo, banner_image)
            // setSeo(seo)
            const parents = data?.parents
            setParents(parents)
            const navigation = data?.navigation
            setNavTree(navigation)
            // const {
            //     nav,
            //     menu,
            //     levelOneData,
            //     side
            // } = prepareNavigation(navigation, 'right')
            // setLevelOneData(levelOneData)
            // setMenu(menu)
            // setNav(nav)
            // setSide(side)

            if (model) {
                setModel(model)
                setHasModel(true)
                const _fields = {}
                for (const field of model.fields) {
                    const name = field.name
                    _fields[name] = field
                }
                setFields(_fields)
            }
        } catch (error) {
            Logger.error('[fetchWebpageData] Error: ' + error?.message)
            Logger.error(error)
            setError(error)
            setHasModel(false)
        } finally {
            setLoading(false)
        }
    }

    /**
     * Retrieves webpage data at the given path
     * @param {string=} path
     */
    async function fetchWebpageData (path) {
        setLoading(true)
        setHasModel(false)
        setModel(null)

        // try {
        //      loadErrorPage() // no await
        // } catch (e) {
        //     Logger.error(e)
        // }
        try {
            let model_guid
            let _webpage
            let _webpage_guid

            if (!path) {
                _webpage = await SimpliCitySDK.webpage.findOne(token, home)
                _webpage_guid = _webpage?.guid
                setWebpage(_webpage)
            } else {
                const encodedPath = encodeURIComponent(path)
                if (typeof  path !== 'string') {
                    Logger.warn(`[fetchWebpageData] Path was not a string ${path}`)
                }
                Logger.debug(`[fetchWebpageData] Loading webpage data: ${encodedPath}`)
                console.debug(`[fetchWebpageData] Loading webpage data: ${encodedPath}`)
                _webpage = await SimpliCitySDK.webpage.findOneByPath(token, encodedPath)
                _webpage_guid = _webpage?.guid
                setWebpage(_webpage)
            }
            // if webpage found, then load model
            if (isStringNotEmpty(_webpage_guid)) {
                if (_webpage.model) {
                    model_guid = _webpage.model
                    Logger.debug('[fetchWebpageData] model_guid: ', model_guid)
                }
                if (model_guid) {
                    const model = await SimpliCitySDK.model.findOne(token, model_guid)
                    if (model) {
                        setModel(model)
                        setHasModel(true)
                        const _fields = {}
                        for (const field of model.fields) {
                            const name = field.name
                            _fields[name] = field
                        }
                        setFields(_fields)
                    }
                }
            }
        } catch (error) {
            Logger.error('[fetchWebpageData] Error: ' + error?.message)
            Logger.error(error)
            setError(error)
            setHasModel(false)
        } finally {
            setLoading(false)
        }
    }

    // const loadErrorPage = async () => {
    //
    //     const path = '/sorry-page-no-longer-available-2'
    //     setLoading(false)
    //     setModel(null)
    //     setWebpage(null)
    //
    //     try {
    //         let model_guid
    //         const encodedPath = encodeURIComponent(path)
    //         const _webpage = await SimpliCitySDK.webpage.findOneByPath(token, encodedPath)
    //         setErrorWebpage(_webpage)
    //         setHasErrorPage(true)
    //         if (_webpage) {
    //             if (_webpage?.model) {
    //                 model_guid = _webpage.model
    //             }
    //             if (model_guid) {
    //                 const model = await SimpliCitySDK.model.findOne(token, model_guid)
    //                 if (model) {
    //                     setErrorModel(model)
    //                     setHasModel(true)
    //                     const _fields = {}
    //                     for (const field of model.fields) {
    //                         const name = field?.name
    //                         _fields[name] = field
    //                     }
    //                     setErrorFields(_fields)
    //                 }
    //             }
    //         }
    //
    //     } catch (error) {
    //         Logger.error('[loadErrorPage] Error: ' + error?.message)
    //         Logger.error(error)
    //     }
    // }

    const fetchWebpage = async (path) => {
        try {
            if (typeof path !== 'string') {
                Logger.warn('Path was not a string', path)
                Logger.flush()
            }
            const encodedPath = encodeURIComponent(path)
            return await SimpliCitySDK.webpage.findOneByPath(token, encodedPath)
        } catch (error) {
            Logger.error(error)
            Logger.flush()
        }
    }

    return {
        fetchWebpageData,
        fetchWebpage,
        fetchWebpageDataComplete,
    }
}

export { useFetchWebpage }
