/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { get } from "lodash"

/**
 * Returns a find function that searches for rows based on the row id.
 *
 * It also correctly finds row labels
 * @param {GridQuestionValue} values
 * @param {GridQuestionOption[]} rows
 */
export function GetGridRowFactory (values, rows) {

    /**
     * @param {string} rowId
     * @returns {GridQuestionOption}
     */
    return function (rowId) {
        const row = rows.find((row) => row.id === rowId)

        // find an existing `other` value to use as the row label
        const otherRowLabel = get(
            values,
            [row.id, 'other'],
        )

        // use the `other` value as the row label, defaulting to the row label
        const rowLabel = otherRowLabel || row.label

        return {
            ...row,
            label: rowLabel
        }
    }
}
