/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from 'react'
import { NavLink } from 'react-router-dom'

const WebsiteLogo = () => {

    return (
        <NavLink
            to='/'
            title='Home'
            rel='home'
            className='logo'
            id='header-logo'
        >
            Home
        </NavLink>
    )
}
export { WebsiteLogo }
