/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { Fragment } from "react";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { ErrorDisplay } from "cms/shared/error_display";
import { Header } from "components/header";
import { Home_Feeds } from "layout/homepage/feeds";
import { HomeContent } from "layout/homepage/content";
import { HomeHighlightArea } from "layout/homepage/highlight-area";
import { Footer } from "components/footer";
import { ServicesList } from "layout/homepage/services-list";
import { HomeHeroCarousel } from "layout/homepage/services-banner/hero-carousel";
// import { ResponsiveDiagnostic } from "layout/homepage/responsive-diagnostic";
// import { SkipDuringPrerender } from 'cms/shared/hyrdation/avoid-prerender'

const Layout_Homepage = () => {
    const { model, fields, error } = useSimpliCity();

    return (
        <Fragment>
            <Header />
            <div>
                {fields && (
                    <>
                        <div className="home-top-section">
                            <HomeHeroCarousel />
                        </div>
                    </>
                )}

                <div id="layout" className="layout homepage">
                    <ServicesList />

                    <Home_Feeds />

                    <div className="homepage-wrapper">
                        {model && (
                            <>
                                <HomeContent />

                                <HomeHighlightArea />
                            </>
                        )}
                    </div>
                </div>
            </div>

            <ErrorDisplay error={error} />

            <Footer />

            {/* <ResponsiveDiagnostic /> */}
        </Fragment>
    );
};
Layout_Homepage.displayName = "Layout_Homepage";
export { Layout_Homepage };
