/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { getProperty } from 'cms/utils'
import {SimpliCityFormattedImage} from 'cms/images/formatted-image'

/**
 * ModelImage
 * For use with Model.banner_image as populated image data
 * @param props
 * @returns {Element}
 * @constructor
 */
const ModelImage = (props) => {

    const { model } = props || {}
    const banner_image = getProperty(model, 'banner_image')
    const { image, settings } = banner_image || {}
    const showCaption = settings?.options?.useCaption ?? false

    return (
        <>
            {image && (
                <Fragment>
                    <SimpliCityFormattedImage
                        image={image}
                        settings={settings}
                        showCaption={showCaption}
                    />
                </Fragment>
            )}
        </>
    )
}

export { ModelImage }
