/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useCallback } from 'react'
import { useAlgolia } from 'cms/search/hooks/use-algolia'
import { getReplicaName } from './get-replica-name'

/**
 * @param {string} attribute
 */
export function useReplicaIndexName (attribute) {
    const { searchIndex } = useAlgolia()

    return useCallback(
        /**
         * @param {import("./get-replica-name").Directions} direction
         */
        function (direction) {
            const replicaName = getReplicaName(searchIndex, attribute, direction)
            return replicaName
        },
        [searchIndex, attribute]
    )
}
