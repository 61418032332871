/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { RichText } from 'cms/rich-text'
import { isStringNotEmpty } from 'cms/utils/empty-exists'
import { forwardRef } from 'react'


const FormWrapper = forwardRef(
    /**
     * @param {FormWrapperProps} props
     */
    (props, ref) => {
        const { description, children } = props

        return (
            <div ref={ref} className="simplicity-forms">
                {isStringNotEmpty(description) && <RichText richtext={description} />}
                {children}
            </div>
        )
    })

export { FormWrapper }

/**
 * @typedef {object} FormWrapperProps
 * @property {import('@tiptap/core').JSONContent=} description
 * @property {import('react').ReactNode} children
 */
