/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {FormQuestionOpinionScale} question
 */
const opinionScaleSchemaTransformer = (question) => {

    /**
     * @type {FormQuestionOpinionScale}
     */
    const newQuestion = {
        ...question,
        value: question.minValue,
        isValid: true,
    }

    return newQuestion
}

export { opinionScaleSchemaTransformer }
