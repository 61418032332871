/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useMemo } from 'react'
import { groupResponses } from './group-responses'

/**
 * Groups a list of responses
 * @param {QuestionResponse[]=} fields
 * @param {ResponseGroup[]=} groups
 */
const useGroupResponses = (fields, groups) => {
    const groupedResponses = useMemo(
        () => {
            return groupResponses(
                fields,
                groups
            )
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return groupedResponses
}

export { useGroupResponses }
