/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { useAtomValue } from 'jotai'
import { Configure, Hits, InstantSearch } from 'react-instantsearch'
import { FullSearchInput } from '../input/full-search-input'
import { searchQueryAtom } from 'cms/search/state/search-query-atom'
import { useAlgolia } from 'cms/search/hooks/use-algolia'
import { ContentTypeFilter } from './filters/content-type-filter'
import { CurrentFilterList } from './filters/current-filter-list'
import { DocumentTypeToggle } from './filters/document-type-toggle'
import { SearchHit } from './hit/search-hit'
import { SearchPaginationCustom } from './pagination/search-pagination'
import { SearchStats } from './search-stats'
import { SortResults } from './sort/sort-results'

export function FullSearch () {
    const { algoliaClient, routing, searchIndex } = useAlgolia(true)

    const query = useAtomValue(searchQueryAtom)

    return (
        <InstantSearch
            searchClient={algoliaClient}
            indexName={searchIndex}
            future={{
                preserveSharedStateOnUnmount: true,
            }}
            routing={routing}
            insights
        >
            <Configure
                hitsPerPage={10}
                query={query}
                // this prevents algolia from forcing these values and sending another request
                highlightPreTag='__ais-highlight__'
                highlightPostTag='__/ais-highlight__'
            />
            <h2 style={{ fontSize: "var(--font-size-medium)" }}>Keywords</h2>

            <FullSearchInput classNames="full-search-input">
                Search
            </FullSearchInput>

            <SearchStats />

            <div className="full-search-controls">
                <div className="full-search-content-filters">
                    <ContentTypeFilter attribute="type" placeholder="Filter results" className="filter-results" />
                    <DocumentTypeToggle />
                </div>
                <SortResults className="sort-results" />
            </div>

            <CurrentFilterList attribute="type" prefix="Filter results" />

            {/* results */}
            <Hits hitComponent={SearchHit} />

            {/* pagination */}
            <SearchPaginationCustom />
        </InstantSearch>
    )
}
