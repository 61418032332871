/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React, { Fragment } from 'react'
import { useSimpliCityWebpageModelImage } from "cms/hooks/use-webpage-image-image"
import { ModelImage } from 'cms/images/model-image'
import {useNavigate} from 'react-router-dom'
// import { Logger } from 'cms/utils/logger'

const FeatureWebpage = (props) => {

    const { web, webpageCard, total } = props || {}
    const {
        webpage: webpage_guid,
        description,
        title,
    } = web || {}

    const {
        model,
        webpage,
        loaded
    } = useSimpliCityWebpageModelImage(webpage_guid)
    const { path } = webpage || {}

    const navigate = useNavigate()

    const onClick = (event) => {
        event.stopPropagation()
        navigate(path)
        // location.href = path
    }

    return (
        <Fragment>
            {model && loaded &&
                <div
                    className={`feature-webpage-content ${webpageCard}_full_width_${total % 3}`}
                    onClick={(event) => onClick(event, path)}
                >
                    {model &&
                        <ModelImage model={model} />
                    }
                    <div className="webpage-content-text">
                        {" "}
                        <h3>
                            <a href={webpage?.path} className="cta-link-style">
                                {title}
                            </a>
                        </h3>
                        <p>{description}</p>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export { FeatureWebpage }
