/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
/**
 * @param {FormQuestion | FormQuestionGrid} question
 * @returns {question is FormQuestionGrid}
 */
const isGridQuestion = (question) => {
    return question.type === 'question.grid'
}

export { isGridQuestion }
