/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import { isLogicalQuestion } from 'cms/forms/utils/is-logical-question'
import { isArrayNotEmpty } from 'cms/utils/empty-exists'
import { Logger } from 'cms/utils/logger'

/**
 * Determines children -> parent dependencies
 * @param {FormQuestion[]} questions
 */
const extractDependencies = (questions) => {
    /**
     * A map of children -> parent dependencies using a Set to prevent duplicates from forming
     * @type {Map<string, Set<string>}
     */
    const childrenParentDeps = new Map()

    let hasRules = false

    for (const question of questions) {
        const isLogical = isLogicalQuestion(question)

        // ignore non-logical questions
        if (!isLogical) {
            continue
        }

        for (const option of question.options) {
            // ignore options without rules
            if (!isArrayNotEmpty(option.rules)) {
                continue
            }

            hasRules = true

            for (const rule of option.rules) {
                const { targetElement } = rule

                // if the set doesn't exist, then create it
                if (!childrenParentDeps.has(targetElement)) {
                    childrenParentDeps.set(targetElement, new Set())
                }

                const parentSet = childrenParentDeps.get(targetElement)
                parentSet.add(question.guid)
            }
            const rulesMsg = option.rules.map((rule) => rule.targetElement)
            Logger.debug(`[extract-deps] ${question.guid} has rules that target ${rulesMsg}`)
        }
    }

    /**
     * A map of children -> parent dependencies
     * @type {DependencyPairs}
     */
    const deps = new Map()

    // Convert Set values to an array
    for (const entry of childrenParentDeps.entries()) {
        const [targetElement, parentSet] = entry

        const parents = Array.from(parentSet.values())
        deps.set(targetElement, parents)
    }

    return {
        deps,
        hasRules
    }
}

export { extractDependencies }
