/*
 * Copyright (C) 2025 SimpliCity Digital Inc - All Rights Reserved
 */
import React from "react";
import cn from "clsx";
import { Outlet } from "react-router-dom";
import { BreadcrumbList } from "cms/shared/breadcrumb";
import { Footer } from "components/footer";
import { Header } from "components/header";
import { ErrorDisplay } from "cms/shared/error_display";
// import { EventNav } from 'cms/shared/event-nav'
import { EventNav } from "mydnv/components/shared/event-nav";
import { useSimpliCity } from "cms/hooks/use-simplicity";
import { EnvWrapper } from 'cms/auth/auth/components/env-wrapper'

const ApplicationNewsLayout = (props) => {
    const { crumbs, customClass, selectedSection } = props;
    const { webpage, error } = useSimpliCity();

    return (
        <>
            <EnvWrapper>
                <Header />

                <div className={cn("event", "two-column-side-nav", customClass)}>
                    <div className="two-column-side-nav-right">
                        <div id="layout" className="layout">
                            <div className="main-content">
                                <BreadcrumbList crumbs={crumbs} />
                                <div>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="two-column-side-nav-left">
                        <EventNav webpage={webpage} selectedSection={selectedSection} />
                    </div>
                </div>

                <ErrorDisplay error={error} />

                <Footer />
            </EnvWrapper>
        </>
    );
};
export { ApplicationNewsLayout };
