/*
 * Copyright (C) 2025.  SimpliCity Digital Inc - All Rights Reserved
 */
import { generateHTML, generateText } from '@tiptap/core'
import { assembleTipTapExtensions } from './tiptap-extensions/assemble-extensions'
import { isString } from "cms/utils/empty-exists"

// import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
// import { isRichTextQuill } from 'cms/rich-text/is-rich-text-quill'
// Set configuration options
//    https://github.com/nozer/quill-delta-to-html
// const quillConfig = {
//     multiLineParagraph: false,
// }
// export function quillToHtml (richtext) {
//     const converter = new QuillDeltaToHtmlConverter(richtext.ops, quillConfig)
//     return converter.convert()
// }

/**
 * @type {React.HTMLAttributes}
 */
export const DEFAULT_HTML = '<span></span>'

/**
 * tipTapToHtml
 * @param richtext
 * @param options
 * @returns {JSXInternal.HTMLAttributes|string}
 */
export function tipTapToHtml (richtext, options) {
    if (!richtext) {
        return DEFAULT_HTML
    }
    const extensions = assembleTipTapExtensions(options)
    return generateHTML(richtext, extensions)
}

/**
 * tipTapToText
 * @param richtext
 * @param options
 * @returns {string}
 */
export function tipTapToText (richtext, options) {
    if (!richtext) {
        return ''
    }
    const extensions = assembleTipTapExtensions(options)
    return generateText(richtext, extensions)
}

/**
 * convertToHtml
 * @param richtext
 * @param options
 * @returns {JSXInternal.HTMLAttributes|JSXInternal.HTMLAttributes|string|*}
 */
export function convertToHtml (richtext, options) {
    // handle null, undefined, and empty string
    if (!richtext) {
        return DEFAULT_HTML
    }

    // handle a non-empty string passed to it
    if (isString(richtext)) {
        return richtext
    }
    // const isQuill = isRichTextQuill(richtext)
    // const html = isQuill
    //     ? quillToHtml(richtext)
    //     : tipTapToHtml(richtext, options)

    const html = tipTapToHtml(richtext, options)

    return html
}

